<template>
  <div>
    <div class="set-toggle">
      <h4>Follow Route</h4>
      <label class="switch">
        <input
            type="checkbox"
            v-model="selectCameraType.followRoute"

        >
<!--        @change="checkFollowRoute"-->
        <span class="slider round"></span>
      </label>
    </div>

    <p v-if="selectCameraType.followRoute" class="message-warning">
      {{ $t("mission-action-modal-comment") }}
    </p>

    <ul class="set-sub-toggle">
      <li v-for="[key,value,i] in  Object.entries(selectCameraType.cameraTypeList)" :key="i">
        <h4>{{ key }}</h4>
        <label class="switch">
          <input
              type="checkbox"
              v-model="selectCameraType.cameraTypeList[key]"
              @change="tt(i,$event.target.value)"
              :disabled="selectCameraType.followRoute"

          >
          <span class="slider round"></span>
        </label>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TakePhoto",
  data() {
    return {
      selectedIndex : -1, //선택된 액션 인덱스
      selectMarkerIndex : -1, //마커의 번호.
      actionElementList : [],
      selectCameraType : {
        followRoute: false,
        cameraTypeList: {
          wide: false,
          zoom: false,
          ir: false
        }
      },
    }
  },
  mounted() {

  },
  watch: {
    'selectCameraType.followRoute': {
      handler (event) {
        if (event) {
          // this.$set(this.selectCameraType, "followRoute", event)
          console.log("watch - followRoute true")
          this.selectCameraType.cameraTypeList = {
            wide: true,
            zoom: true,
            ir: true
          }
        } else {
          console.log("watch - followRoute false")
          this.sendToModal()
        }

      },
      deep: true
    },
    'selectCameraType.cameraTypeList' : {
      handler (event) {
        console.log("watch - cameraTypeList", event)
        for(let key in event) {
          this.selectCameraType.cameraTypeList[key] = event[key]
          this.$set(this.selectCameraType.cameraTypeList, key, event[key]);
        }
        this.sendToModal()
      },
      deep: true,
    },
  },

  methods : {
    /**
     * 모달에서 액션을 선택, 추가시, 해당 이벤트 호출
     * @param selectMarkerIndex : 마커인덱스
     * @param selectedIndex : 액션번호
     * @param data : actionList <List>
     */
    onSettingData(selectMarkerIndex, selectedIndex, actionElementList) {
      this.selectedIndex = selectedIndex
      this.actionElementList = actionElementList
      this.selectMarkerIndex = selectMarkerIndex
      this.init()
    },

    /**
     * 데이터를 새로추가인지,
     * 기존 데이터를 가지고 추가하는것인지 체크
     * 새로추가하는거라면 followRoute 를 true 로 변경후 return -> watch를 타게됨
     */
    init() {
      for (let i = 0; i < this.actionElementList.length; i++) {
        if (this.actionElementList[i].actionName === "fileSuffix" && this.actionElementList[i].actionData == null){
          this.selectCameraType.followRoute = true
          return
        }
      }
      this.setSelectCameraType()
    },

    /**
     * 이전 데이터를 기반으로 토글버튼 세팅
     */
    setSelectCameraType() {
      console.log("setSelectCameraType")
      for (let i = 0; i < this.actionElementList.length; i++) {
        const actionOption = this.actionElementList[i]

        if (actionOption.actionName === "useGlobalPayloadLensIndex") {
          if (actionOption.actionData === null || actionOption.actionData === undefined || actionOption.actionData === '0' || actionOption.actionData === 0) {

            this.selectCameraType.followRoute = false
          } else {
            this.selectCameraType.followRoute = true
            break
          }
        }

        if (actionOption.actionName === "payloadLensIndex") {
          if (actionOption.actionData !== null && actionOption.actionData !== undefined && actionOption.actionData !== '') {
            const cameraData = actionOption.actionData.split(',');
            if (cameraData.length !== 0) {
              cameraData.forEach((key) => {
                this.selectCameraType.cameraTypeList[key] = true
              });
            }
          }
        }
      }
    },


    /**
     * 토글액션이 일어나는 경우.
     * 모달로 현재 설정된 데이터 전송.
     */
    sendToModal() {
      this.actionElementList.forEach(item => {
        // id 설정
        // TODO: 업데이트를 하게되면, fileSuffix가 계속 변경될텐데 괜찮??
        if (item.actionName == "fileSuffix") {
          item.actionData = crypto.randomUUID();
        }

        // zoom, wide, irp 설정
        if (item.actionName == "payloadLensIndex") {
          const cameraTypes = []

          const currentCamera = this.selectCameraType.cameraTypeList
          for (let key in currentCamera) {
            if (currentCamera[key]) {
              cameraTypes.push(key);
            }
          }

          const result = cameraTypes.join(',')
          if (item.actionData != result) item.actionData = result
        }

        // isTrue 설정
        if (item.actionName == "useGlobalPayloadLensIndex") {
          const data = this.selectCameraType.followRoute ? 1 : 0
          if (data != item.actionData) item.actionData = data
        }
      })
      this.$emit("takePhotoData", this.selectedIndex,  JSON.parse(JSON.stringify(this.actionElementList)))
    },

    /**
     * 데이터 초기화.
     * Modal 에서 다른 index의 액션버튼을 누를때 호출됨
     */
    clear() {
      console.log("clear")
      this.selectedIndex = -1
      this.selectMarkerIndex = -1
      this.actionElementList = []

      this.selectCameraType = {
        followRoute: false,
        cameraTypeList: {
          wide: false,
          zoom: false,
          ir: false
        }
      }
    },

  }
}
</script>

<style scoped>

.set-toggle{display: flex;justify-content: space-between;align-items: center;padding-bottom: 15px;border-bottom: 1px solid #ddd;}
.set-toggle > h4{font-size: 1.4rem;font-weight: 500;}
.set-content{width: 500px;padding: 15px;}
.set-sub-toggle{padding: 15px 0;}
.set-sub-toggle > li{display: flex;justify-content : space-between;align-items: center;margin-bottom: 15px;}
.set-sub-toggle > li > h4{font-size: 1.4rem;font-weight: 500;}
.switch {position: relative; display: inline-block;width: 40px;height: 20px;}
</style>