<template>
  <div class="pop">
    <div class="popWrap" style="width: 100%; max-width: 100%;  height: 100%; padding:0;">

      <div class="box video" style="position: relative; z-index:6; width: 100%; height: 100%;padding:0;"  ref = "videoDiv">
        <videoPlayer ref="video" :src="viewSrc" @onTimeUpdate="onTimeUpdate" @isReadyPlay="playerReady" :playerId="'isplay'" :contentsId="contentsId" @isCanPlay="playerLoadData"/>
      </div>

      <div class="box map-box" id="map-box">
        <div style="position: relative">
          <div v-if="locationSize == 0" style="z-index:4; margin-top: 45%;font-size: 2em; right: 25%; left: 25%; line-height: 20; position: absolute; color: white; text-align: center; background-color: rgba(30, 30, 30, 0.8);">
            {{$t("popup-location-not-found")}}
          </div>
        </div>
        <div>
          <Map 
            ref="map"
            v-if="isShow"
            style="width: 100%; height:100%; z-index: 2; position: absolute;" 
            :loadMapName="loadMap"
            :isRightLngLat="true" 
            :isLeftButtonPosition="false" 
            @play-marker-lng-lat="playMarkerLngLat" 
            />
            
            <MapButtonComponent
            class="vodplay-map-button"
            ref="buttonMap"/>
        </div>
        


        <div class="box mapVodInfo"  >
          <div v-if="isShow" class="drone-info">
            <div class="flexB droneVal">
              <div class="droneData">
                <span class="bold DataName">{{$t("monitoring-data-alt")}}</span>
                <span class="vodData">{{ doneData.height == null ? 'N/A' : `${doneData.height.toFixed(0)} m` }}</span>
              </div>
              <div class="droneData">
                <span class="bold DataName">{{$t("monitoring-data-dist")}}</span>
                <span class="vodData">{{ doneData.distance == null ? 'N/A' : `${doneData.distance.toFixed(0)} m` }}</span>
              </div>
              <div class="droneData">
                <span class="bold DataName">{{$t("monitoring-data-hs")}}</span>
                <span class="vodData">{{doneData.hspeed == null ? 'N/A' :  `${doneData.hspeed.toFixed(0)} m/s`}}</span>
              </div>
              <div class="droneData">
                <span class="bold DataName">{{$t("monitoring-data-vs")}}</span>
                <span class="vodData">{{ doneData.vspeed == null ? 'N/A' : `${doneData.vspeed.toFixed(0)} m/s` }}</span>
              </div>
            </div>
            <div class="flex droneCoordinate">
              <div class="droneData flex">
                <span class="DataName bold">{{$t("monitoring-data-lat")}}</span>
                <span class="vodData">{{lat == null ? 'N/A' : `${lat.toFixed(9)}`}}</span>
              </div>
              <div class="droneData flex">
                <span class="DataName bold">{{$t("monitoring-data-long")}}</span>
                <span class="vodData">{{lng == null ? 'N/A' : `${lng.toFixed(9)}`}}</span>
              </div>
              <div class="droneData flex" style="justify-content: flex-start !important;" :style="accountId == 'komipo'? 'display: visible':'display: none'">
                <img src="@/assets/images/icon_svg/ic_heart_rate.svg" style="width: 2rem; height: 2rem;"/>
                <span class="small vodData" style="line-height: 1.8rem;">{{ doneData.heartRate == null ? 'N/A' : `${doneData.heartRate} bpm` }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="vod-info" id="vod-info">
        <div class="info-top">
          <span class="vod-pointer">VOD</span>
          <div class="info-region">
            <h2>{{ contentObj.eventName }}</h2>
            <div class="info-Address">
              <span>{{contentObj.title}}</span>
              <span>{{ contentObj.registerUserName }}</span>
              <span>{{ contentObj.registerUserDeptName }}</span>
              <span>{{ moment(contentObj.registerDate).format("YYYY.MM.DD HH:mm") }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="video-info-box" id = "video-info-box">
        <span>{{ contentObj.deviceType == null && contentObj.deviceName == null ? $t("channel-device-type-mobile-name")+ ' ' : contentObj.deviceName }}</span>
        <span>│</span>
        <span> {{changePlayTime == null ? '-' : changePlayTime}}</span>
      </div>
    </div>
  </div>
</template>
<style>
.mapVodInfo{
  width: 100%;
  position: absolute;
  padding: 5px 10px;
  z-index: 10;
  left: 50%;
  bottom: -36px;
  transform: translate(-50%);
  border-radius: 0;
  background: #232323;
}
.mapVodInfo > .drone-info > .droneVal{display: flex;align-items: center;justify-content: space-between;margin-bottom: 10px;}
.mapVodInfo > .drone-info > .droneVal > .droneData{display: flex;justify-content: space-between;align-items: center;width: 25%;}
.mapVodInfo > .drone-info > .droneVal > .droneData > .DataName{color: #969696;}
.mapVodInfo > .drone-info > .droneVal > .droneData > .vodData{color: #fff;font-size: 1.2rem;}

.droneCoordinate{display: flex;align-items: center;justify-content: space-between;margin-bottom: 5px;}
.droneCoordinate > .droneData{display: flex;justify-content: space-between;align-items: center;width: 50%;}
.droneCoordinate > .droneData > .DataName{color: #969696;}
.droneCoordinate > .droneData > .vodData{color: #fff;font-size: 1.2rem;}

.droneData{
  margin-left: 10px;
}
.droneData:nth-child(1){
  margin-left: 0;
}
.vodData{
  color: #000;
  font-size: 1.2rem;
  margin-left: 5px;
}
.DataName{
  color: #6ebeff;
  font-size: 1.2rem;
}
/*사진캡처 버튼*/
.imgCapt{
  width: 96px;
  height: 36px;
  text-align: center;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
  background: white;
  border-radius: 4px;
  position: absolute;
  z-index: 2000;
  margin: 10px;
  padding: 6px 10px;
  color:black;
  border: 1px solid white;
}
.imgCapt>span{
  line-height: 23px;
  font-weight: bold;
  font-size: 1.2rem;
}
.imgCapt:hover{
  background: #94d9ef;
}

.vod-info{position: absolute;top: 10px;left: 20px;z-index: 1001;background: #3c3c3ccc;border-radius: 5px;padding: 5px 10px;}

.info-top{display: flex;justify-content: space-between;align-items:center;}
.info-top > .info-region{margin-bottom: 0px;display: flex;align-items: center;margin-left: 10px;}
.info-top > .info-region > h2{color: #fff;font-size: 1.4rem;margin-bottom: 0px;}
.info-top > .info-region > .info-Address{color: #dddddd;margin-left: 10px;margin-left: 40px;}
.info-top > .info-region > .info-Address > span{border-right: 1px solid #ddd;margin-right: 10px;padding-right: 10px;}
.info-top > .info-region > .info-Address > span:nth-child(4){border-right: 0px solid #ddd;}
.info-top > .vod-pointer{background: #232323;border-radius: 10px;padding: 4px 10px;font-size: 1.2rem;color: #fff;}

.video-player .video-js {
    width: 100%;
    height: 100vh;
    padding-top: 0%;
}
.map-box{
  position: absolute;
  bottom: 52px;
  left: 20px;
  width: 400px;
  height:300px;
  position: absolute;
  z-index: 1000;
  padding: 0;
}

.model-info{display: flex;justify-content: flex-start;align-items: center;border-bottom: 1px solid #636363;padding: 5px 0 10px 0;margin-bottom: 10px;}
.model-info > .model-img{background: #000;border-radius: 5px;width: 60px;height: 40px;text-align: center;}
.model-info > .model-img > img{width: 80%;padding-top: 8px;}
.model-info > .model-name{margin-left: 10px;}
.model-info > .model-name > h2{font-size: 1.6rem;color: #fff;margin-bottom: 3px;}
.model-info > .model-name > h4{font-size: 1.2rem;color: #b9b9b9;font-weight: 400;}

.vod-ai-mark{position: absolute;z-index: 100;right: 105px;top: 10px;background: #0b0a11b5;padding: 11px 10px;display: flex;justify-content: space-between;align-items: center;border-radius: 5px;}
.vod-ai-mark > .vod-ai-btn{display: flex;align-items: center;}
.vod-ai-mark > .vod-ai-btn > label{font-size: 1.2rem;color: #fff; margin-right: 10px;}

.video-info-box{position: absolute;top: 10px;left: 50%;transform: translate(-50%, 0%);background: #1010108a;padding: 8px 20px;color: #fff;border-radius: 5px;z-index: 100;}
.video-info-box > span{font-size: 1.4rem;}
.vodplay-map-button {
  position: absolute;
  top: 10px;
  right: 5px;
  z-index: 10;
}
</style>
<script>
import moment from "moment";
import Map from "@/components/map/Map";
import MapLineData from "@/components/map/data/MapLineData";
import MapMarkerData from "@/components/map/data/MapMarkerData";
import MapDataType from "@/components/map/enum/MapDataType";

import videoPlayer from "@/components/video/videoPlayer";
import { fetchVodDetail } from "@/api/contents";
import { fetchVodUrlList } from "@/api/server";
import { fetchVodSrc } from "@/api/file";
import { fetchLocation } from "@/api/location";
import { getDeviceTypeName } from "@/api/channel";
import { fetchEvent} from "@/api/event";
import { fetchPointList, getPointTypeName } from "@/api/point";
import {mapState} from "vuex";
import {getDefaultMapZoomLevel} from "@/util/common";
import MapButtonComponent from "@/components/map/MapButtonComponent.vue";
import mapButtonEventBus from "@/components/map/mapButtonEventBus";
export default {
  components: { videoPlayer, Map, MapButtonComponent},
  data() {
    return {
      moment: moment,
      contentsId: "",
      contentObj: {},
      viewSrc: "",
      vodUrl: "",
      location: [],
      locationSize : null,
      lat : null,
      lng : null,
      isShow : false,
      height : 0,
      loadMap : 'kakao',
      isPlayStatus: false,

      isFollow : true,

      eventId:null,

      doneData : {
        distance : null,
        hspeed : null,
        vspeed : null,
        height : null,
        battery : null,
        heartRate : null,
      },

      changePlayTime : null,
    };
  },
  computed: {
    ...mapState({
      currentLang: state => state.store.currentLang,
      accountId:state => state.store.accountId,
      selectedEventId: state => state.store.selectedEventId
    }),
  },
  watch : {
    currentLang(value){
      this.initMapData(value);
    },
  },
  async mounted() {
    this.contentsId = this.$route.query.contentsId;
    await this.getVodEndpoint();
    this.getVodDetail();
    await this.getVodUrl();
    this.initMapData(this.$store.state.store.currentLang );
    this.initButtonMap()
  },
  methods: {
    initButtonMap() {
      this.$refs.buttonMap.showMapTypeChange()
      this.$refs.buttonMap.showMapCenter()
      this.$refs.buttonMap.showFollow()
    },
    
    playerLoadData(id, isLoad) {
      this.isPlayStatus = isLoad;
    },
    playerReady(player){
      player
       this.$refs.video.onPlayerAddDom("map-box");
       this.$refs.video.onPlayerAddDom("vod-info");
       this.$refs.video.onPlayerAddDom("video-info-box");
    },

    createMapMarker(mapDataType, id, name, location, isMain) {
      let mapMarkerData = new MapMarkerData();
      mapMarkerData.id = id;
      mapMarkerData.lng = location.lng;
      mapMarkerData.lat = location.lat;
      mapMarkerData.isMain = isMain;
      mapMarkerData.name = name;
      if (this.$refs.map.onIsMarker(mapDataType, id)) {
        this.$refs.map.onModifyMarkerAt(mapDataType,  mapMarkerData);
      } else {
        this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
      }
    },

    playMarkerLngLat(lng,lat,index) {
      this.$refs.map.onSetLngLat(lng,lat);
      if(this.location.length > 0 ) {
        let locationData = this.location[index];
        this.doneData.distance = locationData.distance;
        this.doneData.hspeed = locationData.hspeed;
        this.doneData.vspeed = locationData.vspeed;
        this.doneData.height = locationData.height;
        this.doneData.battery = locationData.battery;
        this.doneData.heartRate = locationData.heartRate;
        this.lng = lng;
        this.lat = lat;
      }
    },
    initDoneData(){
      this.doneData.distance = null;
      this.doneData.hspeed = null;
      this.doneData.vspeed = null;
      this.doneData.height = null;
      this.doneData.battery = null;
      this.doneData.heartRate = null;
    },
    initMapData(value){
      if(value == 'en') {
        this.loadMap = 'google'
      } else {
        this.loadMap = 'kakao'
      }
      this.getEventPoint();
      this.getLocation();
    },
    
    onTimeUpdate(time) {
      if(this.contentObj.recordDate != undefined) {
        let changeTIme = moment(new Date(this.contentObj.recordDate)).add('milliseconds', time* 1000 );
        this.changePlayTime = changeTIme.format("YYYY-MM-DD HH:mm:ss");
      }

      if(this.$refs.map != undefined) {
        this.$refs.map.onPlayUpdatePosition(time);
      }
    },
    getDeviceTypeName(type){
      return getDeviceTypeName(type)
    },
    async getEventPoint() {
      if(this.eventId == null){
        return;
      }
      
      let params = {
        eventId : this.eventId,
        serverTime : null,
      };
      this.$refs.map.onClearMapData();
      await fetchPointList(params).then((res) => {
        if (res.data.result == 0) {
          this.pointServerTime = res.data.data.serverTime
          this.pointCount = res.data.data.total
          this.pointList = res.data.data.content
          
          // 이벤트포인트 마커  추가
          this.pointList.forEach((element) => {
            element.selectId = element.index;
            element.selectType = "Point";
            element.isOwner = (this.isAdmin || this.userId == element.registerUserId);
            let pointMapMarker = new MapMarkerData();
            element.name = this.$t(getPointTypeName(element.type));
            pointMapMarker.setPointData(element);
            this.$refs.map.onCreateMarker(MapDataType.ID_POINT, pointMapMarker);
          })

          
          
        }
      });
    },
    getVodDetail() {
      fetchVodDetail(this.contentsId).then((res) => {

        this.contentObj = res.data.data;
        console.log("contentsObject",this.contentObj);
        this.eventId = this.contentObj.eventId;
        
        mapButtonEventBus.$emit('selectEventId',this.eventId)

        let changeTime = moment(new Date(this.contentObj.recordDate))
        this.changePlayTime = changeTime.format("YYYY-MM-DD HH:mm:ss");


        fetchEvent(this.contentObj.eventId).then((res) => {
          this.$refs.map.onSetLngLat(res.data.data.lng, res.data.data.lat);
          const location = {
              lng : res.data.data.lng,
              lat : res.data.data.lat
            }
            // 현장마커 추가.
          this.createMapMarker(MapDataType.ID_SITE, "MapMarker", "", location, false);
        });
        
        this.initMapData(this.$store.state.store.currentLang);
        
      });
    },

    async getVodEndpoint() {
      await fetchVodUrlList().then((res) => {
        this.vodUrl = res.data.data.properties.vodStreamUrl + "/" + res.data.data.properties.application;
      });
    },

    async getLocation() {
      this.location = [];
      this.initDoneData();
      let params = {
        contentsId: this.contentsId,
      };
      await fetchLocation(params).then((res) => {
        if (res.data.result == 0) {
          this.locationSize = res.data.data.content.length;
          if(this.locationSize == 0) {
            return;
          }
          if(res.data.data.content.length == 0 && this.lng != null &&  this.lat != null) {
            this.$refs.map.onLngLatToMoveCenter(this.lng, this.lat)
            return;
          }
          this.location = res.data.data.content.sort(function(a, b) {
            return a.index - b.index;
          })
          var first = res.data.data.content[0];
          this.lng = first.lng;
          this.lat = first.lat;
          this.$refs.map.onLngLatToMoveCenter(first.lng, first.lat)
          var mapLineData = new MapLineData();
          mapLineData.id = this.contentsId;
          mapLineData.mapDataType = this.contentObj.deviceLogId == null ? MapDataType.ID_STREAMER : MapDataType.ID_DRONE
          mapLineData.locationGroup = res.data.data.content;

          this.$refs.map.onPlayCreateData(mapLineData);
        }
      });
    },
    async getVodUrl() {
      await fetchVodSrc(this.contentsId).then((res) => {
        this.height = this.$refs.videoDiv.clientHeight;
        this.isShow = true;
        this.$nextTick(function(){
          this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
        })
        this.viewSrc = this.vodUrl + res.data.data.streamUri;
      });
    },
  },
};
</script>
