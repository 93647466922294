
<template>
  <div>
    <div class="set-toggle">


    </div>

    <ul class="set-sub-toggle">
      <li v-for="[key,value,i] in  Object.entries(cameraTypeList)" :key="i">
        <h4>{{ key }}</h4>
        <label class="switch">
          <input
              type="checkbox"
              v-model="cameraTypeList[key]"
          >
          <span class="slider round"></span>
        </label>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PanoShot",
  data() {
    return {
      selectedIndex : -1, //선택된 액션 인덱스
      selectMarkerIndex : -1, //마커의 번호.
      actionElementList : [],
      cameraTypeList: {
        wide: false,
        zoom: false,
        ir: false
      }
    }
  },
  watch: {
    cameraTypeList : {
      handler (event) {
        for(let key in event) {
          this.cameraTypeList[key] = event[key]
          this.$set(this.cameraTypeList, key, event[key]);
        }
        this.sendToModal()
      },
      deep: true,
    },
  },

  methods : {

    /**
     * 모달에서 액션을 선택, 추가시, 해당 이벤트 호출
     * @param selectMarkerIndex
     * @param selectedIndex
     * @param data
     */
    onSettingData(selectMarkerIndex, selectedIndex, data){
      console.log(selectedIndex, selectedIndex, data)
      this.actionElementList = data
      this.selectMarkerIndex = selectMarkerIndex
      this.selectedIndex = selectedIndex
      this.init()
    },

    /**
     * 데이터를 새로추가인지,
     * 기존 데이터를 가지고 추가하는것인지 체크
     * 새로추가하는거라면 cameraTypeList 안 데이터를 를 true 로 변경후 return -> watch를 타게됨
     */
    init(){
      for (let i = 0; i < this.actionElementList.length; i++) {
        if (this.actionElementList[i].actionName === "payloadLensIndex"){
          if(this.actionElementList[i].actionData == null) {
            this.cameraTypeList = {
              wide: true,
              zoom: true,
              ir: true
            }
            return
          }
        }
      }
      this.setSelectCameraType()
    },

    /**
     * 이전 데이터를 기반으로 토글버튼 세팅
     */
    setSelectCameraType() {
      for(let i = 0; i < this.actionElementList.length; i++) {

        const action = this.actionElementList[i]

        if (action.actionName === "useGlobalPayloadLensIndex") {
          if (action.actionData != null || action.actionData != undefined) {
            if(action.actionData == 1){
              this.cameraTypeList = {
                wide: true,
                zoom: true,
                ir: true,
              }
              break
            }
          }
        }

        if (action.actionName === "payloadLensIndex") {
          if (action.actionData !== null && action.actionData !== undefined && action.actionData !== '') {
            const cameraData = action.actionData.split(',');
            if (cameraData.length !== 0) {
              cameraData.forEach((key) => {
                this.cameraTypeList[key] = true
              });
            }
          }
        }
      }

    },

    /**
     * 토글액션이 일어나는 경우.
     * 모달로 현재 설정된 데이터 전송.
     */
    sendToModal(){
      this.actionElementList.forEach(item =>{
        if( item.actionName === "payloadLensIndex"){
          const cameraTypes = []

          for (let key in this.cameraTypeList) {
            if (this.cameraTypeList[key]) {
              cameraTypes.push(key);
            }
          }

          const result = cameraTypes.join(',')
          if(item.actionData != result) item.actionData = result
        }

        if( item.actionName == "useGlobalPayloadLensIndex"){
          item.actionData = 0
        }

        if(item.actionName == "panoShotSubMode"){
          item.actionData = "panoShot_360"
        }
      })
      this.$emit("panoShotData", this.selectedIndex,  JSON.parse(JSON.stringify(this.actionElementList)))
    },

    clear(){
      this.selectedIndex = -1
      this.selectMarkerIndex = -1
      this.actionElementList = []

      this.cameraTypeList = {
        wide: false,
        zoom: false,
        ir: false,
      }
    },
  }

}
</script>





<style scoped>

</style>