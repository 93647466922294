<template>
    <div class="al-list">
        <ul>
            <li>
                <span class="al-bar al-1"></span>
                <h4>{{ $t("air-legend-limits") }}</h4>
            </li>
            <li>
                <span class="al-bar al-2"></span>
                <h4>{{ $t("air-legend-prohibition") }}</h4>
            </li>
            <li>
                <span class="al-bar al-3"></span>
                <h4>{{ $t("air-legend-control") }}</h4>
            </li>
            <li>
                <span class="al-bar al-4"></span>
                <h4>{{ $t("air-legend-boundary") }}</h4>
            </li>
            <li>
                <span class="al-bar al-5"></span>
                <h4>{{ $t("air-legend-danger") }}</h4>
            </li>
        </ul>
    </div>
</template>

<script>
import { format } from "@/mixins/format";
import moment from "moment";
export default {
    mixins: [format],
    name: "AirspaceLegend",
    data() {
        return{
            moment: moment,
            isVisible: false,
        }
    },
    created() {

    },
    mounted() {
        
    },
    methods: {
        
    }

}
</script>

<style scoped>
.al-list{background: rgb(16 16 16 / 70%);padding: 15px;border-radius: 5px;}
.al-list > ul{display: flex;align-items: center;}
.al-list > ul > li{display: flex;align-items: center;margin-right: 20px;}
.al-list > ul > li:last-child{margin-right: 0;}
.al-list > ul > li > h4{font-size: 1.4rem;font-weight: 500;color: #fff;}

.al-bar{width: 30px;height: 13px;background: #ababab80;border: 1px solid #ababab;margin-right: 5px;}
.al-1{background: #4de60080;border: 1px solid #4de600;}
.al-2{background: #e6000080;border: 1px solid #e60000;}
.al-3{background: #C3CEB980;border: 1px solid #C3CEB9;}
.al-4{background: #855e1f80;border: 1px solid #855e1f;}
.al-5{background: #40dab080;border: 1px solid #40dab0;}

.status-button > .al-list{
  position: absolute;
  bottom: 30px;
  right: 15px;
}
</style>