export const baseUrl = "https://dronework.teixon.com";
// export const baseUrl = "http://dw.teixon.com:8080";
// export const baseUrl = "http://onsite.teixon.com:28080";
// export const baseUrl = "http://localhost:9000";
// export const baseUrl = "http://116.67.84.254";
// export const baseUrl = "http://dms.forest.go.kr";
// export const baseUrl = "http://172.19.201.19:9000";
// export const baseUrl = "https://fly.hshi.co.kr";

//지금 로컬 테스트 중(서버 올리면 동일 URL)

export const StationBaseUrl = "https://dronework.teixon.com";
// export const StationBaseUrl = "http://192.168.254.227:12000";
// export const StationBaseUrl = "https://fly.hshi.co.kr";

// export const StationBaseUrl = "http://172.19.201.29:12000";

export const StationBaseApiPreFix = "/station"


export const StationWebsocketURL = "wss://dronework.teixon.com/api/v1/station/ws"
// export const StationWebsocketURL = "ws://192.168.254.227:12000/api/v1/station/ws"
// export const StationWebsocketURL = "ws://172.19.201.29:12000/api/v1/station/ws"

// export const aiBaseUrl = "http://172.19.200.52:5000"
export const aiBaseUrl = "https://dronework.teixon.com"
// export const aiBaseUrl = "https://fly.hshi.co.kr"
export const aiApiUrl = "/ai/api"
export const aiLiveUrl = "/ai/live"

//14.40.77.68
//221.146.70.207

export const baseApiVersion = "/api/v1";

export let headers = {
  "Content-Type": "application/json;charset=UTF-8",
  Accept: "application/json",
  "Access-Control-Allow-Origin": "*",
  "X-Content-Type-Options": "nosniff",
  "x-token": localStorage.getItem("token"),
};


export const STATION_CURRENT_CONFIG = {
  // dji license
  appId: '136525', // You need to go to the development website to apply.
  appKey: 'fa67b421e30e6b24804f1489dba2dc5', // You need to go to the development website to apply.
  appLicense: 'hmxuYL/ANK8pyt9hmHhiOigVVz1NMDeM/GDIES+5Q57Xn5QjwPp8cfhEdWBfz/TxUViKngjSJmOMH6HffiufnayjWLIzuPJy+o8dW/QEMIG6ZAAuNDyg0DcQmzmOk/Nr9aqMdKoxi4kNUUgH3uk1qvrDbpRZcmdLP5AkWXdbTmA=', // You need to go to the development website to apply.
}

// 로컬에서 확인하려면 주석처리된 key 풀어주세요
export const VWORD_CONFIG = {
  key: 'CF2F5B94-545F-3479-99FC-6F3251ABE829',
  // key: 'DADF35B2-0A69-3D77-BDF7-F79E4BC46CB1',
  domain : 'https://dronework.teixon.com',
}
