<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-title">
          <h3 style="margin-bottom: 0;">{{ $t("mission-action-modal-title") }}</h3>
          <button @click="closeModal"><img src="@/assets/images/icon_svg/close-icon.png" alt="icon"
              style="width: 15px;" /></button>
        </div>
        <div class="modal-content">
          <div class="camera-data-info">
            <h3>{{ $t("mission-action-modal-count") }}<span style="margin-left: 5px;">{{ missionActions.length }}</span></h3>
            <button @click="addSetting()"><img src="@/assets/images/ic_action_add.png" alt="icon" />{{ $t("mission-action-modal-add-btn") }}</button>
          </div>
          <div class="camera-set">
            <!--typeSelect-->
            <div class="set-list" v-if="missionActions.length > 0">
              <div class="set-list-box"
                   v-for="(action, index) in missionActions" :key="index"
                   @click="changeSelectIndex(index)"
                   :class="{ active: index === selectedIndex }">
                <div class="set-list-value">
                  <h4>{{ index + 1 }}.</h4>
                  <h6>{{ actionInfoList.length }}</h6>
                  <select
                      v-model="action.actionType"
                      @change="updateSetting(index, $event.target.value)">
                    <option
                        v-for="(actionType, actionIndex) in actionInfoList"
                        :key="'action_' + actionIndex"
                        :value="actionType.actionType">
                      {{ actionType.actionType !== undefined ? actionType.actionType : '-' }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="set-content" v-if="missionActions.length >0 ">
              <!--              type별 Component를 만들어서 호출하도록 관리해야함. component화 가능-->
              <div class="set-content-title" >
                <h2 style="margin: 0">{{ missionActions[selectedIndex].actionType }}</h2>
                <button @click="removeSetting(selectedIndex)"><img src="@/assets/images/ic_action_del.png" alt="icon" />{{ $t("mission-action-modal-del-btn") }}</button>
              </div>
                <TakePhoto v-show="missionActions[selectedIndex].actionType === 'TakePhoto'" ref="TakePhoto" @takePhotoData="saveActionElementItem"  />
                <PanoShot v-show="missionActions[selectedIndex].actionType === 'PanoShot'" ref="PanoShot" @panoShotData="saveActionElementItem" />
            </div>
          </div>
        </div>
        <div class="modal-btn">
          <button class="btn-save" @click="saveSettings">{{ $t("btn-confirm") }}</button>
          <button @click="closeModal">{{ $t("btn-cancel") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchMissionActionInfoList } from "@/api/mission";
import TakePhoto from "@/components/mission/action/TakePhoto.vue"
import PanoShot  from "@/components/mission/action/PanoShot.vue"

export default {
  name: "Modal.vue",
  components: { TakePhoto, PanoShot  },
  data() {
    return {
      selectedIndex: -1, //선택된 액션 인덱스
      selectActionType: "TakePhoto",
      missionActions: [], // 추가한 액션을 담는곳
      actionInfoList: [], // 액션종류
    }
  },
  props: {
    markerIndex: {
      type: Number,
      default: null
    },
    prevAction: {
      type: Array,
      default: null
    }
  },
  mounted() {
    this.getMissionActionInfoList()
    this.setSetting()
  },
  watch: {
    selectedIndex() {
      setTimeout(()=>{
        this.initAction()
      },0)
    },
    selectActionType() {
      setTimeout(()=>{
        this.initAction()
      },0)
    },
  },

  methods: {
    async getMissionActionInfoList() {
      const res = await fetchMissionActionInfoList()
      if (res.data.result !== 0) { return }

      /**변수명 변경*/
      const actionList = res.data.data.map(data => {
        const actionElementList = data.elements
            .map(element => {
              return {
                "actionName": element.actionName,
                "valueType": element.missionActionValueType,
                "actionData": null,
              }
            })
        return {
          "actionType": data.actionType,
          "actionElementList": actionElementList,
        }
      })

      /** 사용가능한 actionType 추출해서 넣기.
       * 모든 액션이 가능해지면 tempList에서 filter 하는게 아니라
       *  this.actionInfoList = actionList로 처리 가능.
       */
      const tempList = actionList.filter(action => {
        if (action.actionType == 'TakePhoto') return action
        if (action.actionType =='PanoShot') return action
      })
      this.actionInfoList = tempList
    },

    /**
     * update, 이전에 설정한 데이터가 있는경우
     * */
    setSetting() {
      if(this.prevAction === null) { return }
      this.prevAction.forEach((action) => {
        this.addSetting(action)
      })
    },

    closeModal() {
      this.$emit("closeModal", false);
    },

    /**
     * action 데이터 추가
     * @param prevAction 이전에 추가한 데이터가 있는경우, 없으면 null
     */
    addSetting(prevAction=null) {
      this.selectActionType = prevAction === null ? 'TakePhoto' : prevAction.actionType
      const action = {
          "index": prevAction !== null ? prevAction.index : this.missionActions.length,
          "missionLocationIndex": this.markerIndex,
          "actionType": this.selectActionType,
          "actionElementList": prevAction !== null ? prevAction.actionElementList : this.actionInfoList[0].actionElementList
      }
      this.missionActions.push(action)
      this.changeSelectIndex(this.missionActions.length - 1)
    },

    /**
     * 현재 선택된 index를 변경
     * 선택된 index가 변경시, watch를 타게됨.
     * @param index 선택한 index
     */
    changeSelectIndex(index) {
      if(this.selectedIndex === index) return
      this.selectedIndex = index;
    },

    /**
     * actionType을 변경할경우 호출
     * actionType이 변경시, watch를 타게됨
     * @param index
     * @param action
     */
    updateSetting(index, action) {
      const findItem = this.actionInfoList.find(item => {
        return item.actionType == action
      })

      if (findItem != undefined && findItem != null) {
        this.selectActionType = action
        const actionElementList = JSON.parse(JSON.stringify(findItem.actionElementList)) //메모리 객체참조 문제발생함.
        this.missionActions[index].actionType = findItem.actionType
        this.missionActions[index].actionElementList = actionElementList
      }
    },

    /** 액션 지우기 */
    removeSetting(index) {
      this.missionActions.splice(index, 1);
      if (index - 1 < 0) {
        this.selectedIndex = 0
      } else {
        this.selectedIndex = index - 1
      }
    },

    /** missionCreate, missionUpdate 등의 뷰로 actionData 전달 */
    saveSettings() {
      this.$emit("saveActionItem", this.markerIndex,this.missionActions)
    },

    clear() {
      this.selectedIndex = 0
      this.selectActionType = "TakePhoto"
      this.missionActions = []
    },

    saveActionElementItem(selectedIndex, actionData) {
      this.missionActions[selectedIndex].actionElementList = actionData
    },

    initAction() {
      const action = JSON.parse(JSON.stringify(this.missionActions[this.selectedIndex]))
      this.$refs[action.actionType].clear()
      this.$refs[action.actionType].onSettingData(this.markerIndex, this.selectedIndex, action.actionElementList)
    }
  }
}
</script>

<style scoped>
/* modal start */
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-wrapper {
  width: auto;
}

.modal-container {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

.modal-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
  padding-bottom: 10px;
}

.modal-title>h3 {
  font-size: 1.6rem;
}

.modal-title>button {
  border: 0;
}

.modal-btn {
  margin-top: 15px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.modal-btn>button {
  margin: 0 5px;
  width: 120px;
  height: 35px;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #969696;
  font-size: 1.4rem;
}

.modal-btn>button.btn-save {
  background: #0080ff;
  color: #fff;
  border: 1px solid #0080ff;
}

.camera-data-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}

.camera-data-info>h3 {
  font-size: 1.4rem;
  font-weight: 500;
  color: #969696;
}

.camera-data-info>h3>span {
  color: #0080ff;
}

.camera-data-info>button {
  border: 0;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  color: #969696;
}

.camera-data-info>button>img {
  margin-right: 5px;
  padding-top: 2px;
}

.camera-set {
  display: flex;
  border-bottom: 1px solid #ddd;
  height: 350px;
  width: 715px;
}

.set-list {
  height: 350px;
  max-height: 350px;
  overflow-y: auto;
  padding: 15px 15px 15px 0px;
  border-right: 1px solid #ddd;
}

.set-list-box {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.set-list-box.active {
  border-color: #007bff;
}

.set-list-value {
  display: flex;
  align-items: center;
}

.set-list-value>h4 {
  font-size: 1.4rem;
}

.set-list-value>select {
  border: 0;
  margin-left: 5px;
  font-size: 1.4rem;
  font-weight: 500;
}

.set-list-box>h5 {
  font-size: 1.2rem;
  color: #969696;
  font-weight: 300;
  padding-left: 28px;
  margin-top: 5px;
}

.set-content {
  width: 500px;
  padding: 15px;
}

.set-content-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.set-content-title>h3 {
  font-size: 1.4rem;
}

.set-content-title>button {
  border: 0;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  color: #969696;
}

.set-content-title>button>img {
  margin-right: 5px;
  padding-top: 2px;
}




/* 토글 슬라이더 */

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 1px;
  background-color: white;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  transform: translateX(18px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.switch-title {
  margin-left: 30px;
  display: inline-block;
  margin-right: 5px;
  border-radius: 10px;
  color: #c9c9c9;
  font-size: 1.2rem;
}

.action-mark {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: #bbbbbb;
  margin-right: 5px;
}

.action-mark.mark-on {
  background: #0ccd49;
}

.message-warning {
  color: #969696;
  font-size: 1.2rem;
  margin-top: 10px;
  padding: 10px;
  background: #eee;
  border-radius: 5px;
  text-align: center;
}

/* modal end */
</style>