import ColorHSV from "./commonData"
import i18n from "@/lang";
import axios from "axios";

const logoJson = require("./logo.json")

export function getIpGet(){

    // return axios.get("http://ip-api.com/json");
    return axios.get("https://api64.ipify.org?format=json");
}

export function getIpLatLng(ip){
    return axios.get(`https://ipapi.co/${ip}/json`);
    // return axios.get(`https://ip-api.com/json/${ip}`);
}

export function getDefaultMapZoomLevel(lang) {
    return lang == "en" ? 13 : 3
}

export function getRGBtoHSV(r, g, b) {
    var r_percent = r / 255;
    var g_percent = g / 255;
    var b_percent = b / 255;

    var max_color = 0;
    var min_color = 0;

    if(r_percent >= g_percent && r_percent >= b_percent) {
        max_color = r_percent
    }
    if(g_percent >= r_percent && g_percent >= b_percent) {
        max_color = g_percent
    }
    if(b_percent >= r_percent && b_percent >= g_percent) {
        max_color = b_percent
    }

    if(r_percent <= g_percent && r_percent <= b_percent) {
        min_color = r_percent
    }
    if(g_percent <= r_percent && g_percent <= b_percent) {
        min_color = g_percent
    }
    if(b_percent <= r_percent && b_percent <= g_percent) {
        min_color = b_percent
    }

    var l = 0;
    var s = 0;
    var h = 0;

    l = (max_color + min_color)/2;

    if(max_color == min_color) {
        s = 0;
        h = 0;
    } else {
        if(l < 0.5) {
            s = (max_color - min_color) / (max_color + min_color)
        } else {
            s = (max_color - min_color) / (2 - max_color - min_color)
        }

        if(max_color == r_percent) {
            h = (g_percent - b_percent) / (max_color - min_color)
        }

        if(max_color == g_percent) {
            h = 2 + (b_percent - r_percent) / (max_color - min_color)
        }

        if(max_color == b_percent) {
            h = 4 + (r_percent - g_percent) / (max_color - min_color)
        }
    }

    h = h * 60
    if(h < 0) h = h + 360

    var colorHSV = new ColorHSV();
   colorHSV.h = h;
   colorHSV.s = s;
   colorHSV.l = l
    return colorHSV;
}

 // TODO: 임시 데모 수정. 완료후엔 주석풀고 주석밑에 코드 삭제하셈.
 /**
     * accountId 종류 
     * @param {String} accountId 
     * @param {String} type 
     * 이미지 변경을 위해서 사용한다.
     * 이미지 추가시 logo.json에 accountid 및 이미지 주소를 추가하면 된다.
     */
 export function getImageSrc(accountId,type){
   var rootUrl = "/images/" // 여기서 /src/assets를 같이주면 렌더가안됨. 렌더영역에서 assets를 더해줘야함.
   var result = ''
     var imageUrl= ''
   const typeData = logoJson[type];
    if(typeData[accountId] == undefined || typeData[accountId] == null){
        imageUrl = typeData.default
        if(imageUrl == null) return null
    }else {
        imageUrl = typeData[accountId]
    }
   result = rootUrl+imageUrl
   return result
}
export function getContent(accountId,type){
    var result = ""
    switch(type){
        case 'title': //Gnb에 두번째로 뜨는 이미지 밑에와 로그인 창에 나타나는 문구 
            if(accountId == 'hsg'){
                result = i18n.t("login-external-title")
            } else {
                return null
            }
            break;
        case 'login-title' : //login하면에서 아이디 입력 위에칸에 뜨는 문구.
            if(accountId == 'hsg'){
                result = i18n.t("login-external-title")
            }
            else {
                result = i18n.t("login-main-title")
            }
            break;
   }
   return result
}

