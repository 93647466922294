import Vuex from "vuex";
import Vue from "vue";
import store from "./store"
import station from "../station/store/store"

Vue.use(Vuex);

const vuexStore = new Vuex.Store({
  modules: {
    store,
    station
  },
});

const token = localStorage.getItem("token");
if (token) {
  vuexStore.commit("store/LOGIN", { token });
}
export default vuexStore;
