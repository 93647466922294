import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "@/lang";
import state from "@/store/state";
import {postCheckDepartment} from"@/api/event";
import {stationLicenceCheck,aiLicenceCheck} from "@/util/Licence";

Vue.use(VueRouter);

const requireAuth = () => (to, from, next) => {
  checkSession();
  checkDepartment(to).then(res => {
    let accesstoken = localStorage.getItem("token");
    /*let userType = localStorage.getItem("userType");*/
    if(to.params.accountId == 'undefined'){
      to.params.accountId = state.accountId
    }
    if (accesstoken == null || accesstoken == undefined || state.accountId == undefined || state.accountId == null) {
      if(from.name != "error404") {
        alert(i18n.t("856"));
      }
      next({
        path: `/${to.params.accountId}/login`,
      });
    } else {
      if((localStorage.getItem("isAdmin") == "false" && ( !checkIsUserUrl(to.path)))){
        alert("잘못된 접근 입니다.");
        next({
          path:`/${to.params.accountId}/eventControl/liveListControlUser`,
        });
      }else if(res == -10 || res == -20 ){
        var message = res== -10 ? "해당 현장 소속이 아닙니다." : "제한된 현장 입니다."
        alert(message)
      //  alert("잘못된 접근 입니다.");
        next({
          path:`/${to.params.accountId}/eventControl/liveListControlUser`,
        });
      } else {
        next();
      }
    }
  })
  // next();
};

function checkIsUserUrl(toPath){
  let userType = localStorage.getItem("userType")

  switch (userType){
    case "Admin" :
      /*if(toPath.search("overallAdmin") != -1){
        return true;
      }*/
      if(toPath.search("userEdit") != -1){
        return true;
      }
      if(toPath.search("vodPlay") != -1) {
        return true;
      }
      if(toPath.search("livePlay") != -1) {
        return true;
      }
      if(toPath.search("event") != -1){
        return true;
      }
      if(toPath.search("statistics") != -1){
        return true;
      }
      break;
    case "Manager" :
      if(toPath.search("overallManager") != -1){
        return true;
      }
      if(toPath.search("DroneLiveMap") != -1){
        return true;
      }
      if(toPath.search("DroneLiveMultiView") != -1){
        return true;
      }
      if(toPath.search("liveListControl") != -1){
        return true;
      }
      if(toPath.search("userEdit") != -1){
        return true;
      }
      if(toPath.search("userConfirmList") != -1){
        return true;
      }
      if(toPath.search("vodPlay") != -1) {
        return true;
      }
      if(toPath.search("livePlay") != -1) {
        return true;
      }
      if(toPath.search("event") != -1){
        return true;
      }
      if(toPath.search("user") != -1){
        return true;
      }
      if(toPath.search("statistics") != -1){
        return true;
      }
      if(toPath.search("dashDeviceList") != -1){
        return true;
      }
      if(toPath.search("dashContentsList") != -1){
        return true;
      }
      break;
    case "User" :
      if(toPath.search("DroneStatusMap") != -1){
        return true;
      }
      if(toPath.search("liveListControlUser") != -1){
        return true;
      }
      if(toPath.search("userEdit") != -1){
        return true;
      }
      if(toPath.search("eventControl") != -1){
        return true;
      }
      if(toPath.search("vodPlay") != -1) {
        return true;
      }
      if(toPath.search("livePlay") != -1) {
        return true;
      }
      break;
  }
  return false;
}

function checkSession(){
  let time = localStorage.getItem("sessionTime");
  if(time == null || time == undefined) {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("accountId");
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("userType");
    localStorage.removeItem("sessionTime");
  }

  if (time && (new Date().getTime() - time > 180.0 * 1000)) {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("accountId");
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("userType");
    localStorage.removeItem("sessionTime");
  }
}

async function checkDepartment(data){
  var result = 0
  var isParams = data.params != null && data.params != undefined
  if(!isParams) return result
  var isEventId = data.params.eventId != null && data.params.eventId != undefined
  if(!isEventId) return result

  await postCheckDepartment(data.params.eventId).then(res => {
    result = res.data.result
  })
  return result
}
const redirect = () => (to, from, next) => {
  checkSession();

  let accountId = localStorage.getItem("accountId");
  let accesstoken = localStorage.getItem("token");
  let isAdmin = localStorage.getItem("isAdmin");
  let userType = localStorage.getItem("userType");
  isAdmin;
  userType;

  if (accesstoken != undefined && accesstoken != null) {
    next(`/${accountId}/eventControl/liveListControlUser`);
    if(isAdmin == "true") {
      next(`/${accountId}/dashboard/overallAdmin`);
    } else {
      next(`/${accountId}/eventControl/liveListControlUser`);
    }
  } else {
    next();
  }
};

const checkAiLicence = () => (to, from, next) => {
  const account = localStorage.getItem("accountId");
  const isUse = aiLicenceCheck(account)
  if(isUse){
    return next()
  }else{
    return next("/demo/404")
  }
};

const checkStationLicence = () => (to, from, next)=> {
  const account = localStorage.getItem("accountId");
  const isUse = stationLicenceCheck(account)
  if(isUse){
    return next()
  }else{
    return next("/demo/404")
  }
}


// const checkStationLicense = () => (to, from, next) => {
//   if()
// };


const routes = [
  {
    path: "/:accountId",
    name: "home",
    component: () => import("../views/account/Login.vue"),
    beforeEnter: redirect(),
  },
  {
    path: "/:accountId/aiView",
    name: "aiView",
    component: () => import("../views/account/AIView.vue"),
    beforeEnter: redirect(),
  },
  {
    path: "/:accountId/waypoint/file",
    name: "waypointFile",
    component: () => import("../views/tempWaypoint/tempFileParse.vue"),
  },
  {
    path: "/:accountId/login",
    name: "login",
    component: () => import("../views/account/Login.vue"),
    beforeEnter: redirect(),
  },
  {
    path: "/:accountId/guestLogin",
    name: "guestLogin",
    component: () => import("../views/account/GuestLogin.vue"),
  },
  {
    path: "/:accountId/portal",
    name: "portal",
    component: () => import("../views/account/portal.vue"),
  },
  {
    path: "/:accountId/profileEdit",
    name: "profileEdit",
    component: () => import("../views/account/profileEdit.vue"),
  },
  {
    path: "/:accountId/joinTermsAgree",
    name: "joinTermsAgree",
    component: () => import("../views/account/JoinTermsAgree.vue"),
  },
  {
    path: "/:accountId/termsViewer/:termsNum",
    name: "termsViewer",
    component: () => import("../views/account/TermsViewer.vue"),
  },
  {
    path: "/:accountId/join",
    name: "join",
    component: () => import("../views/account/Join.vue"),
  },
  {
    path: "/:accountId/joinDone",
    name: "joinDone",
    component: () => import("../views/account/JoinDone.vue"),
    props: true,
  },
  {
    path: "/:accountId/findEmail",
    name: "findEmail",
    component: () => import("../views/account/FindEmail.vue"),
  },
  {
    path: "/:accountId/findEmailDone",
    name: "findEmailDone",
    component: () => import("../views/account/FindEmailDone.vue"),
  },
  {
    path: "/:accountId/findPassword",
    name: "findPassword",
    component: () => import("../views/account/FindPassword.vue"),
  },
  {
    path: "/:accountId/resetPassword",
    name: "resetPassword",
    component: () => import("../views/account/ResetPassword.vue"),
  },
  {
    path: "/:accountId/changePassword",
    name: "changePassword",
    component: () => import("../views/account/ChangePassword.vue"),
  },
  {
    path: "/:accountId/changePasswordDone",
    name: "changePasswordDone",
    component: () => import("../views/account/ChangePasswordDone.vue"),
  },
  {
    path: "/:accountId/release",
    name: "release",
    component: () => import("../views/account/Release.vue"),
  },
  {
    path: "/:accountId/suspended",
    name: "suspended",
    component: () => import("../views/account/Suspended.vue"),
  },

  {
    path: "/:accountId/indexList",
    name: "indexList",
    component: () => import("../views/IndexList.vue"),
  },
  {
    path: "/:accountId/niceCheck",
    name : "niceCheck",
    component: () => import("../views/account/NiceCheck.vue"),
    props : true,
  },
  {
    path: "/:accountId/nicePop",
    name : "nicePop",
    component: () => import("../views/pop/NicePop.vue"),
    props : true,
  },
  {
    path: "/:accountId/dashboard",
    name: "dashboard",
    component: () => import("../views/common/Container.vue"),
    beforeEnter: requireAuth(),
    children: [
      {
        path: "overallManager",
        name: "overallManager",
        component: () => import("../views/dashboard/Overall.vue"),
      },
      {
        path: "overallAdmin",
        name: "overallAdmin",
        component: () => import("../views/dashboard/Overall_Admin.vue"),
      },
      {
        path: "DroneStatusMap",
        name: "DroneStatusMap",
        component: () => import("../views/dashboard/DroneStatusMap.vue"),
      },
      {
        path: "dashContentsList",
        name: "dashContentsList",
        component: () => import("../views/dashboard/DashContents.vue"),
      },
      {
        path: "dashChannelList",
        name: "dashChannelList",
        component: () => import("../views/dashboard/DashChannelList.vue"),
      },
      {
        path: "dashDeviceList",
        name: "dashDeviceList",
        component: () => import("../views/dashboard/DashDeviceList.vue"),
      },
      {
        path: "encoder",
        name: "encoder",
        component: () => import("../views/dashboard/Encoder.vue"),
      },
      {
        path: "transcoding",
        name: "transcoding",
        component: () => import("../views/dashboard/Transcoding.vue"),
      },
      {
        path: "transcoderServer",
        name: "transcoderServer",
        component: () => import("../views/dashboard/TranscoderServer.vue"),
      },
    ],
  },
  {
    path: "/:accountId/statistics",
    name: "statistics",
    component: () => import("../views/common/Container.vue"),
    beforeEnter: requireAuth(),
    children: [
      {
        path: "eventStatistics",
        name: "eventStatistics",
        component: () => import("../views/statistics/eventStatistics.vue"),
      },
      {
        path: "eventUserStatistics",
        name: "eventUserStatistics",
        component: () => import("../views/statistics/eventUserStatistics.vue"),
      },
      {
        path: "addressEventStatistics",
        name: "addressEventStatistics",
        component: () => import("../views/statistics/addressEventStatistics.vue"),
      },
    ],
  },
  {
    path: "/:accountId/eventControl",
    name: "eventControl",
    component: () => import("../views/common/Container.vue"),
    beforeEnter: requireAuth(),
    children: [
      {
        path: "liveListControl",
        name: "liveListControl",
        component: () => import("../views/eventController/LiveEventList.vue"),
      },
      {
        path: "liveListControlUser",
        name: "liveListControlUser",
        component: () => import("../views/eventController/LiveEventListUser.vue"),
      },
    ],
  },
  {
    path: "/:accountId/dashboard/monitoring",
    name: "newPopDashboard",
    component: () => import("../views/common/NewPopContainer.vue"),
    beforeEnter: requireAuth(),
    children: [
      {
        path: "DroneLiveMap",
        name: "DroneLiveMap",
        component: () => import("../views/dashboard/monitoring/DroneLiveMap.vue"),
      },
      {
        path: "DroneLiveMultiView",
        name: "DroneLiveMultiView",
        component: () => import("../views/dashboard/monitoring/DroneLiveMultiView.vue"),
      },
    ]

  },
  /*Site Control 팝업 라우터*/
  {
    path: "/:accountId/eventControl",
    name: "SiteControl",
    component: () => import("../views/common/PopupContainer.vue"),
    beforeEnter: requireAuth(),
    children: [
      {
        path: "liveInfoControl",
        name: "liveInfoControl",
        component: () => import("../views/eventController/LiveEventInfo.vue"),
      },
      {
        path: `:eventId/userEdit`,
        name: "userEdit2",
        component: () => import("../views/user/Edit.vue"),
      },
      {
        path: `:eventId/SkyView`,
        name: "SkyView",
        component: () => import("../views/eventController/monitoring/Map.vue"),
        props: true,
        meta: { group: "EventControl-SkyView" }
      },
      {
        path: `:eventId/Comparison`,
        name: "Comparison",
        component: () => import("../views/eventController/monitoring/Comparison.vue"),
        props: true,
        meta: { group: "EventControl-Data" }
      },
      {
        path: `:eventId/liveStream`,
        name: "liveStream",
        component: () => import("../views/eventController/monitoring/Live.vue"),
        props: true
      },
      {
        path: `:eventId/Information`,
        name: "Information",
        component: () => import("../views/eventController/monitoring/Info.vue"),
        props: true,
        meta: { group: "EventControl-Data" }
      },
      {
        path: `:eventId/VOD`,
        name: "VOD",
        component: () => import("../views/eventController/contents/Vod.vue"),
        props: true,
        meta: { group: "EventControl-Data" }
      },
      {
        path: `:eventId/Panorama`,
        name: "Panorama",
        component: () => import("../views/eventController/panorama/Panorama.vue"),
        props: true,
        meta: { group: "EventControl-Data" }
      },
      {
        path: `:eventId/Point`,
        name: "Point",
        component: () => import("../views/eventController/contents/Recode_Point.vue"),
        props: true,
        meta: { group: "EventControl-Data" }
      },
      {
        path: `:eventId/Notice`,
        name: "Notice",
        component: () => import("../views/eventController/monitoring/Notice.vue"),
        props: true,
        meta: { group: "EventControl-Data" }
      },
      {
        path: `:eventId/NoticeDetail`,
        name: "NoticeDetail",
        component: () => import("../views/eventController/monitoring/notice/NoticeDetail.vue"),
        props: true,
        meta: { group: "EventControl-Data" }
      },
      {
        path: `:eventId/NoticeCreate`,
        name: "NoticeCreate",
        component: () => import("../views/eventController/monitoring/notice/NoticeCreate.vue"),
        props: true,
        meta: { group: "EventControl-Data" }
      },
      {
        path: `:eventId/MissionVOD`,
        name: "MissionVOD",
        component: () => import("../views/eventController/contents/Mission_Vod.vue"),
        props: true,
        meta: { group: "EventControl-Data" }
      },
      {
        path: `:eventId/LiveMap`,
        name: "LiveMap",
        component: () => import("../views/eventController/monitoring/liveMap/LiveMapView_new.vue"),
        props: true,
        meta: { group: "EventControl-Data" }
      },
      {
        path: `:eventId/Drone`,
        name: "Drone",
        component: () => import("../views/eventController/contents/Recode_Device.vue"),
        props: true,
        meta: { group: "EventControl-Data" }
      },
      {
        path: `:eventId/flight`,
        name: "flight",
        component: () => import("../views/eventController/contents/Recode_Flight.vue"),
        props: true,
        meta: { group: "EventControl-Data" }
      },
      {
        path: `:eventId/DroneStation`,
        name: "DroneStation",
        component: () => import("../views/eventController/contents/Recode_DroneStation.vue"),
        props: true
      },
      {
        path: `:eventId/Member`,
        name: "Member",
        component: () => import("../views/eventController/contents/Recode_Member.vue"),
        props: true
      },
      {
        path: `:eventId/Mission`,
        name: "Mission",
        component: () => import("../views/eventController/missionFlight/MissionList.vue"),
        props: true,
        meta: { group: "EventControl-MissionControl" }
      },
      {
        path: `:eventId/MissionDetail`,
        name: "MissionDetail",
        component: () => import("../views/eventController/missionFlight/MissionDetail.vue"),
        props: true,
        meta: { group: "EventControl-MissionControl" }
      },
      {
        path: `:eventId/MissionUpdate`,
        name: "MissionUpdate",
        component: () => import("../views/eventController/missionFlight/MissionUpdate.vue"),
        props: true,
        meta: { group: "EventControl-MissionControl" }
      },
      {
        path: `:eventId/MissionCreate`,
        name: "MissionCreate",
        component: () => import("../views/eventController/missionFlight/MissionCreate.vue"),
        props: true,
        meta: { group: "EventControl-MissionControl" }
      },
      {
        path: `:eventId/Upload`,
        name: "Upload",
        component: () => import("../views/eventController/missionFlight/MissionUpload.vue"),
        props: true,
        meta: { group: "EventControl-Data" }
      },
      {
        path: `:eventId/LiveMapThermalDetail`,
        name: "LiveMapThermalDetail",
        component: () => import("../views/eventController/monitoring/liveMap/thermal/LiveMapThermalDetail.vue"),
        props: true,
        meta: { group: "EventControl-Data" }
      },
      {
        path: `:eventId/LiveMapOpticsDetail`,
        name: "LiveMapOpticsDetail",
        component: () => import("../views/eventController/monitoring/liveMap/optics/LiveMapOpticsDetail.vue"),
        props: true,
        meta: { group: "Data" }
      },
      //Mapping Router 추가 2024-01-17-유원규
      {
        path: `:eventId/Mapping`,
        name: "Mapping",
        component: () => import("../views/eventController/mapping/MappingList.vue"),
        props: true,
        meta: { group: "EventControl-MissionControl" }
      },

      {
        path: `:eventId/MappingDetail`,
        name: "MappingDetail",
        component: () => import("../views/eventController/mapping/MappingDetail.vue"),
        props: true,
        meta: { group: "EventControl-MissionControl" }
      },
      {
        path: `:eventId/MappingCreate`,
        name: "MappingCreate",
        component: () => import("../views/eventController/mapping/MappingCreate.vue"),
        props: true,
        meta: { group: "EventControl-MissionControl" }
      },
      {
        path: `:eventId/MappingUpload`,
        name: "MappingUpload",
        component: () => import("../views/eventController/mapping/MappingUpload.vue"),
        props: true,
        meta: { group: "EventControl-MissionControl" }
      },
      {
        path: `:eventId/MappingUpdate`,
        name: "MappingUpdate",
        component: () => import("../views/eventController/mapping/MappingUpdate.vue"),
        props: true,
        meta: { group: "EventControl-MissionControl" }
      },
      {
        path: `:eventId/SchedulePlan`,
        name: "SchedulePlan",
        component: () => import("../views/eventController/schedule/SchedulePlan.vue"),
        meta: { group: "EventControl-MissionControl" }
      },
      {
        path: `:eventId/SchedulePlanCreate`,
        name: "SchedulePlanCreate",
        component: () => import("../views/eventController/schedule/SchedulePlanCreate.vue"),
        props: true,
        meta: { group: "EventControl-MissionControl" }
      },
      {
        path: `:eventId/SchedulePlanUpdate/:scheduleId`,
        name: "SchedulePlanUpdate",
        component: () => import("../views/eventController/schedule/SchedulePlanUpdate.vue"),
        props: true,
        meta: { group: "EventControl-MissionControl" }
      },
      {
        path: `:eventId/ScheduleHistory`,
        name: "ScheduleHistory",
        component: () => import("../views/eventController/schedule/ScheduleHistory.vue"),
        props: (route) => ({
          eventId: route.params.eventId,
          page: route.query.page,
          keyword: route.query.keyword,
          keywordType: route.query.keywordType,
          startYmd: route.query.startYmd,
          endYmd: route.query.endYmd,
        }),
        meta: { group: "EventControl-MissionControl" }
      },
      {
        path: `:eventId/ScheduleHistoryDetail/:scheduleItemLogId`,
        name: "ScheduleHistoryDetail",
        component: () => import("../views/eventController/schedule/ScheduleHistoryDetail.vue"),
        props: true,
        meta: { group: "EventControl-MissionControl" }
      },
      {
        path: `:eventId/StationInfo`,
        name: "StationInfo",
        component: () => import("../views/eventController/station/StationInfo.vue"),
        props: true,
        meta: { group: "EventControl-Station" },
        // beforeEnter: checkStationLicence(),
      },
      {
        path: `:eventId/AiChannelList`,
        name: "AiChannelList",
        component: () => import("../views/eventController/ai/AiChannelList.vue"),
        props: true,
        beforeEnter: checkAiLicence(),
        meta: { group: "EventControl-AI" }
      },
      {

        path: `:eventId/AiChannelDetail`,
        name: "AiChannelDetail",
        component: () => import("../views/eventController/ai/AiChannelDetail.vue"),
        props: true,
        beforeEnter: checkAiLicence(),
        meta: { group: "EventControl-AI" }
      },
      {
        path: `:eventId/AiVodList`,
        name: "AiVodList",
        component: () => import("../views/eventController/ai/AiVodList.vue"),
        props: (route) => ({
          eventId: route.params.eventId,
          page: route.query.page,
          keyword: route.query.keyword,
          keywordType: route.query.keywordType,
          startYmd: route.query.startYmd,
          endYmd: route.query.endYmd,
        }),
        beforeEnter: checkAiLicence(),
        meta: { group: "EventControl-AI" }
      },
      {
        path: `:eventId/AiVodDetail/:contentsId`,
        name: "AiVodDetail",
        component: () => import("../views/eventController/ai/AiVodDetail.vue"),
        props: true,
        meta: { group: "EventControl-AI" },
        beforeEnter: checkAiLicence()
      },
      {
        path: `:eventId/AiStreaming`,
        name: "AiStreaming",
        component: () => import("../views/eventController/ai/AiStreaming.vue"),
        props: (route) => ({
          eventId: route.params.eventId,
          page: route.query.page,
          keyword: route.query.keyword,
          keywordType: route.query.keywordType,
          startYmd: route.query.startYmd,
          endYmd: route.query.endYmd,
        }),
        meta: { group: "EventControl-AI" },
        beforeEnter: checkAiLicence()
      },
      {
        path: `:eventId/AiStreamingDetail/:contentsId`,
        name: "AiStreamingDetail",
        component: () => import("../views/eventController/ai/AiStreamingDetail.vue"),
        props: true,
        meta: { group: "EventControl-AI" },
        beforeEnter: checkAiLicence()
      },
    ],
  },


  {
    path: "/:accountId/event",
    name: "event",
    component: () => import("../views/common/Container.vue"),
    beforeEnter: requireAuth(),
    children: [
      {
        path: "list",
        name: "eventList",
        component: () => import("../views/event/EventList.vue"),
      },
      {
        path: "register",
        name: "eventRegister",
        component: () => import("../views/event/EventRegister.vue"),
      },
    ],
  },
  {
    path: "/:accountId/station",
    name: "station",
    component: () => import("../views/common/Container.vue"),
    beforeEnter: requireAuth(),
    children: [
      {
        path: "list",
        name: "stationList",
        component: () => import("../views/station/StationList.vue"),
        beforeEnter: checkStationLicence(),
      },
      {
        path: "/:accountId/station/:stationDeviceId/register",
        name: "stationRegister",
        component: () => import("../views/station/StationRegister.vue"),
        beforeEnter: checkStationLicence(),
      },
    ],
  },
  {
    path: "/:accountId/device",
    name: "device",
    component: () => import("../views/common/Container.vue"),
    beforeEnter: requireAuth(),
    children: [
      {
        path: "deviceList",
        name: "deviceList",
        component: () => import("../views/device/DeviceList.vue"),
      },
      {
        path: "deviceRegister",
        name: "deviceRegister",
        component: () => import("../views/device/DeviceRegister.vue"),
      },
      {
        path: "deviceLogList",
        name: "deviceLogList",
        component: () => import("../views/device/DeviceLogList.vue"),
      },
    ],
  },
  {
    path: "/:accountId/user",
    name: "user",
    component: () => import("../views/common/Container.vue"),
    beforeEnter: requireAuth(),
    children: [
      {
        path: "userEdit",
        name: "userEdit",
        component: () => import("../views/user/Edit.vue"),
      },
      {
        path: "userList",
        name: "userList",
        component: () => import("../views/user/UserList.vue"),
      },
      {
        path: "userRegister",
        name: "userRegister",
        component: () => import("../views/user/UserRegister.vue"),
      },
      {
        path: "teamList",
        name: "teamList",
        component: () => import("../views/user/TeamList.vue"),
      },
      {
        path: "teamRegister",
        name: "teamRegister",
        component: () => import("../views/user/TeamRegister.vue"),
      },
      {
        path: "accessLogList",
        name: "accessLogList",
        component: () => import("../views/user/AccessLog.vue"),
      },
      {
        path: "guestAccessLogList",
        name: "guestAccessLogList",
        component: () => import("../views/user/AccessLogbyGuest.vue"),
      },
      {
        path: "userConfirmList",
        name: "userConfirmList",
        component: () => import("../views/user/UserConfirmList.vue"),
      },
    ],
  },
  {
    path: "/:accountId/contents",
    name: "contents",
    component: () => import("../views/common/Container.vue"),
    beforeEnter: requireAuth(),
    children: [
      {
        path: "contentsList",
        name: "contentsList",
        component: () => import("../views/contents/ContentsList.vue"),
      },
      {
        path: "contentsDetail",
        name: "contentsDetail",
        component: () => import("../views/contents/ContentsDetail.vue"),
      },
    ],
  },
  {
    path: "/:accountId/channel",
    name: "channel",
    component: () => import("../views/common/Container.vue"),
    beforeEnter: requireAuth(),
    children: [
      {
        path: "channelDetail",
        name: "channelDetail",
        component: () => import("../views/channel/ChannelDetail.vue"),
      },
      {
        path: "channelList",
        name: "channelList",
        component: () => import("../views/channel/ChannelList.vue"),
      },
      {
        path: "channelLogList",
        name: "channelLogList",
        component: () => import("../views/channel/ChannelLogList.vue"),
      },
    ],
  },
  {
    path: "/:accountId/livePlay",
    name: "livePlay",
    component: () => import("../views/pop/LivePlay.vue"),
    beforeEnter: requireAuth(),
  },
  {
    path: "/:accountId/vodPlay",
    name: "vodPlay",
    component: () => import("../views/pop/VodPlay.vue"),
    beforeEnter: requireAuth(),
  },
  {
    path: "/:accountId/stationControl/:stationDeviceId",
    name: "stationControl",
    component: () => import("../views/pop/stationControl.vue"),
    beforeEnter: requireAuth(),
  },
  {
    path: "/:accountId/stationControlMode/:stationDeviceId",
    name: "stationControlMode",
    component: () => import("../views/pop/stationControlMode.vue"),
    beforeEnter: requireAuth(),

  },
  {
    path: "/:accountId/mapChoice",
    name: "mapChoice",
    component: () => import("../views/pop/MapChoice.vue"),
    beforeEnter: requireAuth(),
  },
  {
    path: "/:accountId/encoderLog",
    name: "encoderLog",
    component: () => import("../views/pop/EncoderLog.vue"),
    beforeEnter: requireAuth(),
  },
  {
    path: "/:accountId/error503",
    name: "error503",
    component: () => import("../views/pop/Error503.vue"),
  },
  {
    path: '/:accountId/(.*)*',
    name: "error404",
    component: () => import("../views/pop/Error404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

function getIsNotRemoveSearchName(from) {
  var name = null
  switch (from.name) {
    case "userList":
    case "userRegister":
      name = "user";
      break;
    case "teamList":
    case "teamRegister":
      name = "team";
      break;
    case "contentsList" :
    case "contentsDetail" :
      name = "contents";
      break;

    case "channelList" :
    case "channelDetail" :
      name = "channel";
      break;

    case "deviceList" :
    case "deviceRegister" :
      name = "device";
      break;

    case "eventList" :
    case "eventRegister":
      name = "event";
      break;

    case "LiveMap" :
    case "LiveMapThermalDetail" :
    case "LiveMapOpticsDetail" :
      name = "liveMap";
      break;
  }
  return name;
}

function getIsNotRemoveSearchData(from, to) {
  var isNotRemove = true;

  switch (from.name) {
    case "userList":
    case "userRegister":
      if(to.name == "userList" || to.name == "userRegister") {
        isNotRemove = false;
      }
      break;
    case "teamList":
    case "teamRegister":
      if(to.name == "teamList" || to.name == "teamRegister") {
        isNotRemove = false;
      }
      break;
    case "contentsList" :
    case "contentsDetail" :
      if(to.name == "contentsList" || to.name == "contentsDetail") {
        isNotRemove = false;
      }
      break;

    case "channelList" :
    case "channelDetail" :
      if(to.name == "channelList" || to.name == "channelDetail") {
        isNotRemove = false;
      }
      break;

    case "deviceList" :
    case "deviceRegister" :
      if(to.name == "deviceList" || to.name == "deviceRegister") {
        isNotRemove = false;
      }
      break;

    case "eventList" :
    case "eventRegister":
      if(to.name == "eventList" || to.name == "eventRegister") {
        isNotRemove = false;
      }
      break;

    case "LiveMap" :
    case "LiveMapThermalDetail" :
    case "LiveMapOpticsDetail" :
      if(to.name == "LiveMap" || to.name == "LiveMapThermalDetail" || to.name == "LiveMapOpticsDetail"){
        isNotRemove = false;
      }
      break;
  }
  return isNotRemove;
}

router.beforeEach(function (to, from, next) {

  // to: 이동할 url에 해당하는 라우팅 객체
  var name = getIsNotRemoveSearchName(from,to);
  var isRemoveData = getIsNotRemoveSearchData(from,to);
  if(name != null && isRemoveData) {
    localStorage.removeItem(name +"_searchData");
    if(name === "liveMap"){//라이브 맵의 tab 정보 지우는 부분.
      localStorage.removeItem(name +"_visibleTab");
      localStorage.removeItem(name +"_optics_searchData");
      localStorage.removeItem(name +"_thermal_searchData");
    }
  }
  next(); // 페이지 전환
});

export default router;
