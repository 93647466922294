<template>
    <div class="modal-wrapper">
        <div class="modal-container">
            <div class="modal-title">
                <h3>{{ $t("schedule-modal-station-title") }}</h3>
                <button @click="closeStationModal"><img src="@/assets/images/icon_svg/close-icon.png" alt="icon"
                        style="width: 12px;" /></button>
            </div>
            <div class="modal-input">
                <div class="modal-search">
                    <span>{{ $t("search-keyword-title") }}</span>
                    <select v-model="keywordType" style="color: black;">
                        <option value="all">{{ $t("search-keyword-all") }}</option>
                        <option value="stationName">{{ $t("select-station-name") }}</option>
                        <option value="droneName">{{ $t("select-drone-name") }}</option>
                        <option value="userName">{{ $t("mission-data-way-registrant") }}</option>
                    </select>
                    <input type="text" v-model="keyword" @keydown.enter="search()" style="width: 375px;" />
                </div>
                <div class="buttonWrap">
                    <button @click="getStationList">
                        {{ $t("btn-searching") }}
                    </button>
                    <button @click="setStationMarker">
                        {{ $t("btn-reset") }}
                    </button>
                </div>
            </div>
            <div class="modal-content">
                <div class="map">
                    <Map ref="map" style="width: 100%; height: 100%; border-radius:5px;" :loadMapName="loadMap"
                        :isLeftButtonPosition="false" />
                    <span v-if="stations.length === 0" style="position:absolute; z-index:4; top: 50%; left: 50%; transform: translate(-50%, -50%); height:auto; font-size: 2rem; font-weight: bold; color: white; background: rgba(0,0,0,0.8); padding: 2px 8px; ">스테이션을 등록후 이용해주세요</span>
                    <span v-if="isEmptyLatLng" style="position:absolute; z-index:4; top: 50%; left: 50%; transform: translate(-50%, -50%); height:auto; font-size: 2rem; font-weight: bold; color: white; background: rgba(0,0,0,0.8); padding: 2px 8px; ">선택한 스테이션의 위치를 찾을수 없습니다</span>
                </div>
                <div class="modal-list">
                    <div class="modal-list-title">
                        <h4>{{ $t("schedule-modal-station-list") }}</h4>
                        <h5>{{ $t("data-total") }}<span>{{ stations.length }}</span>{{ $t("overall-data-event-ea") }}</h5>
                    </div>
                    <ul>
                        <li v-for="(station, index) in stations" :key="station.device.managerData.refDeviceId"
                            @click="selectStation(index)" :class="{ active: activeIndex === index }">
                            <h4>{{ station.device.deviceName }}</h4>
                            <p class="m-b-5"><img src="@/assets/images/icon_svg/ic_b_drone.png" alt="icon" />{{
                                station.children.deviceName }}</p>
                            <p><img src="@/assets/images/icon_svg/ic_b_drone.png" alt="icon" />위도: {{station.device.managerData.lat.toFixed(3) || 0}} 경도: {{station.device.managerData.lng.toFixed(3) || 0}}</p>
                        </li>
                    </ul>
                </div>

            </div>
            <div class="modal-btn">
                <button @click="saveStation" class="btn-save">{{ $t("btn-confirm") }}</button>
                <button @click="closeStationModal">{{ $t("btn-confirm-close") }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import Map from '@/components/map/Map.vue';
import MapDataType from "@/components/map/enum/MapDataType";
import MapMarkerData from "@/components/map/data/MapMarkerData";
import { fetchStationDeviceDockList } from "@/api/station/stationDevice";

export default {
    name: "StationModal",
    props: {
        eventId: {
            type: String,
            required: true
        },
    },
    components: {
        Map
    },
    data() {
        return {
            activeIndex: null,
            loadMap: 'kakao',
            showWaySelectModal: false,
            stations: [],
            keyword: '',
            keywordType: 'all',
            isEmptyLatLng: false,
            selectIndex: null
        }
    },
    created() {
        this.getStationList()
    },
    mounted() {
      this.$refs.map.onSetZoomLevel(13)
    },
    methods: {
        getStationList() {
            const params = { eventId: this.eventId }

            fetchStationDeviceDockList(params)
                .then((res) => {
                  if (res.status === 200) {
                      this.stations = res.data.data.content
                      console.log(this.stations)
                      this.setStationMarker()
                  }
                })
        },

        // 리스트에서 스테이션 셀랙트
        selectStation(selectedIndex) {
            // 셀렉트에서 index 찾아서 반짝하게.

            this.activeIndex = selectedIndex
            const lng = this.stations[this.activeIndex].device.managerData.lng
            const lat = this.stations[this.activeIndex].device.managerData.lat

            if (lng === null || lat === null || lng === undefined || lat === undefined || lng === 0 || lat === 0 ) {
                this.isEmptyLatLng = true
                alert(this.t$('schedule-modal-alert-station-no-location'))
            } else {
                this.isEmptyLatLng = false
                this.setStationMarker()
                this.$refs.map.onLngLatToMoveCenter(lng, lat)
            }
        },

      setStationMarker() {
            if (this.stations.length < 0 || this.$refs.map === undefined) { return }

            // 기존 마커 삭제
            this.$refs.map.onClearMapData()

            const mapDataType = MapDataType.ID_SCHEDULE_STATION
            for (let index=0; index<this.stations.length; index++) {
              const item = this.stations[index]

              // 스테이션의 위치가 없을때
              if(item.device.managerData.lng === 0 || item.device.managerData.lat === 0 || item.device.managerData.lng === null || item.device.managerData.lat === null) { continue }

              const mapMarkerData = new MapMarkerData();
              mapMarkerData.id = item.device.stationDeviceId;
              mapMarkerData.name = item.device.deviceName;
              mapMarkerData.lng = item.device.managerData.lng;
              mapMarkerData.lat = item.device.managerData.lat;

              // 선택한 인덱스의 경우 파란색마커로 변경
              if (this.activeIndex === index) { mapMarkerData.isMain = true }

              if (this.$refs.map.onIsMarker(mapDataType, index)) {
                this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
              } else {
                this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
              }
            }
        },

        search() {
            console.log("대충 검색내용")
        },
        closeStationModal() {
            this.$emit('isStationModal', false)
        },
        saveStation() {
            this.$emit('saveStation', this.stations[this.activeIndex])
        },

    }
}
</script>
