<template>
    <div class="contents">
        <div style="display: flex;justify-content: space-between;align-items: center;">
            <h1 style="height: 50px;line-height: 50px;margin-top:50px;">{{ $t("top-select-ai-live-data-ch") }}</h1>
            <div class="ai-view-tab">
                <button @click="aiChangeTab(1)" :class="{ active: aiScreenVisible === 1 }">
                    <img src='@/assets/images/ic_ai_oneView.png' alt="icon" class="ai-tab-off" v-if="aiScreenVisible !== 1">
                    <img src='@/assets/images/ic_ai_oneView_on.png' alt="icon" class="ai-tab-on" v-else>
                </button>
                <button @click="aiChangeTab(2)" :class="{ active: aiScreenVisible === 2 }">
                    <img src='@/assets/images/ic_ai_fourView.png' alt="icon" class="ai-tab-off" v-if="aiScreenVisible !== 2">
                    <img src='@/assets/images/ic_ai_fourView_on.png' alt="icon" class="ai-tab-on" v-else>
                </button>
                <button @click="aiChangeTab(3)" :class="{ active: aiScreenVisible === 3 }">
                    <img src='@/assets/images/ic_ai_nineView.png' alt="icon" class="ai-tab-off" v-if="aiScreenVisible !== 3">
                    <img src='@/assets/images/ic_ai_nineView_on.png' alt="icon" class="ai-tab-on" v-else>
                </button>
            </div>
        </div>

        <div class="ai-monitoring-tab" v-show="aiScreenVisible === 1">
            <div class="flexB" style="display: flex; align-items: stretch;margin-bottom: 15px;">
                <div class="video-list" style="position: relative; width:60%;">

                    <div v-if="getLiveData(0) != null">
                      <div class="multi-spinner-container" v-if="getLiveData(0).isLoad === false">
                        <div class="multi-spinner"></div>
                      </div>

                      <WebRTC_WOWZA
                          class="play-on play-3-view"
                          v-if="getLiveData(0) != undefined && aiScreenVisible === 1"
                          v-show="getLiveData(0).isLoad == true"
                          :ref="`video_${getLiveData(0).channelId}`"
                          :sdpUrl="getLiveData(0).streamServer.properties.sdpUrl"
                          :streamName="getLiveData(0).linkedAiId+getLiveData(0).streamServer.properties.streamPostfix"
                          :applicationName="getLiveData(0).streamServer.properties.aiApplication"

                          :playerId="getLiveData(0).channelId"
                          :selectStreamId ="selectedSoundId"
                          :channelIndex="1"
                          :deviceType="getLiveData(0).assignedDeviceType"
                          :isSpeakerTc ="false"
                          @toggleSound ="toggleSound"
                          @isLoadedmetadata="playerLoadData"
                          @playerOpen="playerOpen"
                          @playerRemoveId="playerRemove"
                      />

                    </div>
                    <div v-else class="ai_view_off">
                        <div v-show="getLiveData(0) == undefined || getLiveData(0).isLoad == false" class="play-off">
                        <img src="@/assets/images/play-off-icon.png" alt="icon" />
                        <h2>{{$t("monitoring-view-no-signal")}}</h2>
                        </div>
                    </div>
                </div>
                <div class="ai-live box m-b-0">
                    <div class="mapWrap" style="width:100%; height:338px; padding:0px;" >
                        <div class="map" style="height: 100%;">
                            <Map ref="map" style="width: 100%; height: 100%; border: 1px solid #d3d3d3;"
                                :loadMapName="loadMap"
                                :isLeftButtonPosition="false"/>
                                <MapButtonComponent ref="buttonMap" />
                        </div>
                    </div>
                    <div class="ai-live-info">
                        <div class="ai-info-top">
                            <div class="ai-drone-info">
                                <div class="ai-emphysema-img">
                                    <div class="drone-image" v-if="getLiveData(0) != undefined && getLiveData(0).assignedDeviceType != 'Mobile'&& getLiveData(0).isLoad == true">
                                        <img :src="getDeviceImg(getLiveData(0))" alt="icon" />
                                    </div>
                                    <div class="phone-image" v-if="getLiveData(0) != undefined && getLiveData(0).assignedDeviceType === 'Mobile'&& getLiveData(0).isLoad == true">
                                        <img src="@/assets/images/phone-img.png" alt="icon" />
                                    </div>
                                </div>
                                <div class="ai-drone-value">
                                    <h2>{{getLiveData(0) == undefined || getLiveData(0).isLoad == false ? ' - ' : getLiveData(0).assignedDeviceType != 'Mobile' ? getLiveData(0).assignedDeviceName : getLiveData(0).assignedDeviceType}}</h2>
                                    <h4>
                                        <span>{{getLiveData(0) == undefined || getLiveData(0).isLoad == false ? ' - ' : getLiveData(0).assignedDepartmentName}}</span>│
                                        <span>{{getLiveData(0) == undefined || getLiveData(0).isLoad == false ? ' - ' : getLiveData(0).assignedDeviceType != 'Mobile' ? getLiveData(0).assignedUserName : getLiveData(0).assignedUserName }}</span>
                                    </h4>
                                </div>
                            </div>
                            <div class="ai-battery-info">
                                <h4>
                                    <span>{{getLiveData(0) == undefined || getLiveData(0).isLoad == false || getLiveData(0).battery== undefined ? ' - ' : getLiveData(0).battery}}</span>
                                    <span>%</span>
                                </h4>
                                <ul>
                                    <li v-for="(b_index) in 5" :key="'battery' + b_index"  :class=" getLiveData(0) == undefined || getLiveData(0).isLoad == false || getLiveData(0).battery ==undefined  ? 'off' : (5-b_index)*20 <= getLiveData(0).battery ? 'on' : 'off' " />
                                </ul>
                            </div>
                        </div>
                        <div class="ai-value-info">
                            <div class="ai-video-value">
                                <div class="ai-value-box m-l-0">
                                    <h4>{{$t("monitoring-data-alt")}}</h4>
                                    <h2><span>{{ getLiveData(0) == undefined || getLiveData(0).isLoad == false || getLiveData(0).height == null ? '-' : `${getLiveData(0).height.toFixed(0)} ` }}</span>m</h2>
                                </div>
                                <div class="ai-value-box">
                                    <h4>{{$t("monitoring-data-dist")}}</h4>
                                    <h2><span>{{ getLiveData(0) == undefined || getLiveData(0).isLoad == false || getLiveData(0).distance == null ? '-' : `${getLiveData(0).distance.toFixed(0)} ` }} </span>m</h2>
                                </div>
                                <div class="ai-value-box">
                                    <h4>{{$t("monitoring-data-hs")}}</h4>
                                    <h2><span>{{ getLiveData(0) == undefined || getLiveData(0).isLoad == false || getLiveData(0).hspeed == null ? '-' : `${getLiveData(0).hspeed.toFixed(0)} ` }}</span>m/s</h2>
                                </div>
                                <div class="ai-value-box">
                                    <h4>{{$t("monitoring-data-vs")}}</h4>
                                    <h2><span>{{ getLiveData(0) == undefined || getLiveData(0).isLoad == false || getLiveData(0).hspeed == null ? '-' : `${getLiveData(0).hspeed.toFixed(0)} ` }}</span>m/s</h2>
                                </div>
                            </div>
                            <div class="ai-video-coordinate">
                                <div class="ai-coordinate-row m-l-0">
                                    <h4>{{$t("monitoring-data-lat")}}</h4>
                                    <h2><span>{{ getLiveData(0) == undefined || getLiveData(0).isLoad == false || getLiveData(0).lat == null ? '-'   :  `${getLiveData(0).lat.toFixed(9)} ` }} </span></h2>
                                </div>
                                <div class="ai-coordinate-row">
                                    <h4>{{$t("monitoring-data-long")}}</h4>
                                    <h2><span>{{ getLiveData(0) == undefined || getLiveData(0).isLoad == false || getLiveData(0).lng == null ? '-' : `${getLiveData(0).lng.toFixed(9)} ` }} </span></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ai-detecting-list box m-b-0">
                <div class="ai-detecting-list-title">
                    <h2>{{$t("ai-channel-sub-title")}}</h2>
                    <div class="detecting-input">
                        <h4>{{$t("ai-channel-table-detected-target")}} : </h4>

<!--                      :id="'aiObject' + 0"-->
                      <div class="object-btn" v-for="(data, index2) in detectObj" :key="index2">
                        <input
                            type="radio"
                            :name="`liveAiObject 0`"
                            v-model="selectedDetectList[0]"
                            @click="changeDetectObject(data, 0)"
                            :value="data"
                        >
                        <label :for="'aiObject' + index2">{{ data.name }}</label>
                      </div>
                    </div>
                </div>
                <div class="ai-detecting-content">
                    <div class="detecting-box">
                        <div class="detecting-img" v-if="getLiveData(0) != undefined && getLiveData(0).isLoad == true &&  lastResultImgUrl != null" >
                            <img :src="lastResultImgUrl" alt="icon" />
                        </div>
                    </div>
                    <div class="ai-detecting-table">
                        <table>
                            <tr>
                                <th>{{$t("ai-channel-table-accuracy")}}</th>
                                <th>{{$t("ai-channel-table-image-number")}}</th>
                                <th>{{$t("ai-channel-select-object")}}</th>
                                <th>x1</th>
                                <th>x2</th>
                                <th>y1</th>
                                <th>y2</th>
                            </tr>
                            <tr v-for="data in aiLastResult" :key="data.id">
                                <td>{{data.class_name}}</td>
                                <td>{{percentage(data.confidence)}}</td>
                                <td>{{data.current_frame}}</td>
                                <td>{{data.x1}}</td>
                                <td>{{data.x2}}</td>
                                <td>{{data.y1}}</td>
                                <td>{{data.y2}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="ai-monitoring-tab" v-show="aiScreenVisible === 2">
            <div class="ai-multi-view">
                <div class="video-list" style="position: relative; width:50%;height: 50%;" v-for="(index) in 4" :key=index>
                    <div v-if="getLiveData(index-1) != null" style="position: relative; height: calc(100% - 20px); overflow: hidden;">
                      <div class="multi-spinner-container" v-if="getLiveData(index-1).isLoad === false">
                        <div class="multi-spinner"></div>
                      </div>

                        <WebRTC_WOWZA
                            class="play-on play-3-view"
                            v-if="getLiveData(index-1) != null && aiScreenVisible === 2"
                            v-show="getLiveData(index-1).isLoad == true"
                            :ref="`video_${getLiveData(index-1).channelId}`"
                            :sdpUrl="getLiveData(index-1).streamServer.properties.sdpUrl"
                            :streamName="getLiveData(index-1).linkedAiId+getLiveData(index-1).streamServer.properties.streamPostfix"
                            :applicationName="getLiveData(index-1).streamServer.properties.aiApplication"
                            :playerId="getLiveData(index-1).channelId"
                            :selectStreamId ="selectedSoundId"
                            :channelIndex="1"
                            :deviceType="getLiveData(index-1).assignedDeviceType"
                            :isSpeakerTc ="false"
                            @toggleSound ="toggleSound"
                            @isLoadedmetadata="playerLoadData"
                            @playerOpen="playerOpen"
                            @playerRemoveId="playerRemove"
                        />
                      <div class="multi-view-detecting">
                        <div class="object-btn object-nine" v-for="(data, index2) in detectObj" :key="index2">
                          <input
                              type="radio"
                              :name="`liveAiObject2 ${index-1}`"
                              v-model="selectedDetectList[index-1]"
                              @click="changeDetectObject(data, index-1)"
                              :value="data"
                          >
                          <label :for="'aiObject' + index2">{{ data.name }}</label>
                        </div>
                      </div>
                    </div>
                    <div v-else class="ai_view_off">
                        <div v-show="getLiveData(index-1) == undefined || getLiveData(index-1).isLoad == false" class="play-off">
                          <img src="@/assets/images/play-off-icon.png" alt="icon" />
                          <h2>{{$t("monitoring-view-no-signal")}}</h2>
                        </div>
                    </div>
                    <div class="ai-view-info">
                        <div class="info-value">
                            <h4>
                                <span>{{getLiveData(index-1) == undefined || getLiveData(index-1).isLoad == false ? ' - ' : getLiveData(index-1).assignedDeviceType != 'Mobile' ? getLiveData(index-1).assignedDeviceName : getLiveData(index-1).assignedDeviceType}}</span>
                            </h4>
                            <h4>
                                <span>{{getLiveData(index-1) == undefined || getLiveData(index-1).isLoad == false ? ' - ' : getLiveData(index-1).assignedDepartmentName}}</span>
                            </h4>
                            <h4>
                                <span>{{getLiveData(index-1) == undefined || getLiveData(index-1).isLoad == false ? ' - ' : getLiveData(index-1).assignedDeviceType != 'Mobile' ? getLiveData(index-1).assignedUserName : getLiveData(index-1).assignedUserName }}</span>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="ai-monitoring-tab" v-show="aiScreenVisible === 3">
            <div class="ai-multi-view">
                <div class="video-list" style="position: relative; width:33.3333%;height: 33.3333%;" v-for="(index) in 9" :key="index">
                    <div v-if="getLiveData(index-1) != null" style="position: relative; height: calc(100% - 20px); overflow: hidden;">
                      <div class="multi-spinner-container" v-if="getLiveData(index-1).isLoad === false">
                        <div class="multi-spinner"></div>
                      </div>
                        <WebRTC_WOWZA
                            class="play-on play-3-view"
                            v-if="getLiveData(index - 1) != undefined && aiScreenVisible === 3"
                            v-show="getLiveData(index-1).isLoad == true"
                            :ref="`video_${getLiveData(index-1).channelId}`"
                            :sdpUrl="getLiveData(index-1).streamServer.properties.sdpUrl"
                            :streamName="getLiveData(index-1).linkedAiId+getLiveData(index-1).streamServer.properties.streamPostfix"
                            :applicationName="getLiveData(index-1).streamServer.properties.aiApplication"

                            :playerId="getLiveData(index-1).channelId"
                            :selectStreamId ="selectedSoundId"
                            :channelIndex="1"
                            :deviceType="getLiveData(index-1).assignedDeviceType"
                            :isSpeakerTc ="false"
                            @toggleSound ="toggleSound"
                            @isLoadedmetadata="playerLoadData"
                            @playerOpen="playerOpen"
                            @playerRemoveId="playerRemove"
                        />
                      <div class="multi-view-detecting">
                        <div class="object-btn object-nine" v-for="(data, index2) in detectObj" :key="index2">
                          <input
                              type="radio"
                              :name="`liveAiObject3 ${index-1}`"
                              v-model="selectedDetectList[index-1]"
                              @click="changeDetectObject(data, index-1)"
                              :value="data"
                          >
                          <label :for="'aiObject' + index2">{{ data.name }}</label>
                        </div>
                      </div>
                    </div>
                    <div v-else class="ai_view_off">
                        <div v-show="getLiveData(index-1) == undefined || getLiveData(index-1).isLoad == false" class="play-off">
                        <img src="@/assets/images/play-off-icon.png" alt="icon" style="width: 30px;">
                        <h2 style="font-size: 1.4rem;">{{$t("monitoring-view-no-signal")}}</h2>
                        </div>
                    </div>
                    <div class="ai-view-info">
                        <div class="info-value">
                            <h4>
                                <span>{{getLiveData(index-1) == undefined || getLiveData(index-1).isLoad == false ? ' - ' : getLiveData(index-1).assignedDeviceType != 'Mobile' ? getLiveData(index-1).assignedDeviceName : getLiveData(index-1).assignedDeviceType}}</span>
                            </h4>
                            <h4>
                                <span>{{getLiveData(index-1) == undefined || getLiveData(index-1).isLoad == false ? ' - ' : getLiveData(index-1).assignedDepartmentName}}</span>
                            </h4>
                            <h4>
                                <span>{{getLiveData(index-1) == undefined || getLiveData(index-1).isLoad == false ? ' - ' : getLiveData(index-1).assignedDeviceType != 'Mobile' ? getLiveData(index-1).assignedUserName : getLiveData(index-1).assignedUserName }}</span>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.title{display: none;}
.back-btn{
    margin-top: 50px;
    border: 1px solid #ddd;
    padding: 0 30px;
    background: #fff;
    color: #454545;
    border-radius: 5px;
}
.m-l-0{margin-left: 0 !important;}
.m-b-0{margin-bottom: 0 !important;}
.ai-live{width: 39%;}
.ai-live-info{margin-top: 15px;}
.ai-info-top{display: flex;justify-content: space-between;align-items: center;padding-bottom: 15px;border-bottom: 1px solid #ddd;}
.ai-drone-info{display: flex;align-items: center;}
.ai-emphysema-img{margin-right: 10px;}
.ai-emphysema-img > .drone-image{width: 100px;height: 70px;text-align: center;background: #eee;border-radius: 5px;padding: 5px;display: flex;justify-content: center;align-items: center;}
.ai-emphysema-img > .drone-image > img{width: 100%;}
.ai-emphysema-img > .phone-image{width: 100px;height: 70px;text-align: center;background: #eee;border-radius: 5px;padding: 5px;}
.ai-emphysema-img > .phone-image > img{height: 100%;}

.ai-drone-value > h2{font-size: 2rem;font-weight: bold;margin-bottom: 5px;}
.ai-drone-value > h4{font-size: 1.4rem;font-weight: 400;}

.ai-battery-info{display: flex;align-items: center;}
.ai-battery-info > h4{font-size: 1.4rem;font-weight: 400;}
.ai-battery-info > ul{display: flex;margin-bottom: 0px;margin-left: 10px;}
.ai-battery-info > ul > li{height: 25px;width: 8px;margin-right: 2px;border-radius: 2px;}
.ai-battery-info > ul > li.off{background: #c9c9c9;}
.ai-battery-info > ul > li.on{background: #20ECC9;}

.ai-value-info{padding-top: 15px;}
.ai-video-value{display: flex;justify-content: space-between;align-items: center; margin-bottom: 15px;}
.ai-value-box{width: 25%;display: flex;justify-content: space-between;align-items: center;margin-left: 10px;}
.ai-value-box > h4{background: #eee;color: #969696;padding: 3px 15px;border-radius: 5px;font-size: 1.2rem;font-weight: 400;}
.ai-value-box > h2{font-size: 1.4rem;margin: 0;font-weight: bold;}

.ai-video-coordinate{display: flex;justify-content: space-between;align-items: center;}
.ai-coordinate-row{width: 50%;display: flex;justify-content: space-between;align-items: center;margin-left: 10px;}
.ai-coordinate-row > h4{background: #eee;color: #969696;padding: 3px 15px;border-radius: 5px;font-size: 1.2rem;font-weight: 400;}
.ai-coordinate-row > h2{margin: 0;font-size: 1.4rem;font-weight: bold;}

.ai-detecting-list > .ai-detecting-list-title{display: flex;justify-content: space-between;align-items: center;margin-bottom: 15px;}
.ai-detecting-list > .ai-detecting-list-title > h2{font-size: 1.4rem;margin: 0;font-weight: bold;}
.detecting-input{display: flex;align-items: center;}
.detecting-input > h4{font-size: 1.4rem;color: #969696;font-weight: 500;margin-right: 20px;}
.detecting-input > .object-btn{display: flex;align-items: center; margin-right: 20px;}
.detecting-input > .object-btn:last-child{margin-right:0;}
.detecting-input > .object-btn > label{font-size: 1.4rem;}

.ai-detecting-content{display: flex;justify-content: space-between;align-items: flex-start;}
.detecting-box{width: 365px;height: 200px; background: #eee;}
.detecting-img{width: 365px;height: 200px;}
.detecting-img > img{width: 100%;height: 100%;}

.ai-detecting-table{width: calc(100% - 390px);height: 200px;max-height: 200px;overflow-y: auto;}

.ai_view_off{width: 100%; height: 100%;}
.ai_view_off > .play-off{position: relative; width: 100%; height: 100%; background: #000;display: flex;flex-direction: column;align-items: center;justify-content: center;}
.ai_view_off > .play-off > img{width: 50px;margin-bottom: 20px;}
.ai_view_off > .play-off > h2{color: #fff;font-size: 1.6rem;margin: 0;}

/* 화면 탭 */
.ai-view-tab{display: flex;align-items: center; margin-top: 50px;}
.ai-view-tab > button{width: 38px;height: 38px;border: 1px solid #ddd;background: #fff;border-radius: 5px;cursor: pointer; margin-left: 5px;}
.ai-view-tab > button > img{width: 18px;}
.ai-view-tab > button:hover{background: #f7f7f7;color: #fff;}
.ai-view-tab > button.active{background: #0080ff;color: #fff !important;}

.ai-monitoring-tab{width: 100%;}

.ai-multi-view{display: flex;justify-content: space-between;align-items: center;flex-wrap: wrap;height: calc(100vh - 120px);}
.ai-multi-view > .video-list{border: 1px solid #707070;}

.ai-view-info{position: absolute;bottom: -1px;width: 100%; background: #232323cc;padding: 5px 15px;}
.ai-view-info > .info-value{display: flex;align-items: center;}
.ai-view-info > .info-value > h4{margin-bottom: 0; margin-right: 20px;}
.ai-view-info > .info-value > h4 > span{font-size: 1.4rem;color: #fff;font-weight: 400;}

.multi-view-detecting{position: absolute;top: 10px;right: 10px;background: #232323cc;display: flex;align-items: center;padding: 5px 15px;border-radius: 3px;}
.multi-view-detecting > .object-btn{margin-right: 20px;display: flex;align-items: center;}
.multi-view-detecting > .object-btn:last-child{margin-right: 0px;}
.multi-view-detecting > .object-btn > input{margin-right: 5px;}
.multi-view-detecting > .object-btn > label{color: #fff;font-size: 1.4rem;}

.multi-view-detecting > .object-nine{margin-right: 10px;display: flex;align-items: center;}
.multi-view-detecting > .object-nine > label{color: #fff;font-size: 1.2rem;}

.multi-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* 투명도 추가 */
    z-index: 10; /* 스피너가 다른 요소 위에 나타나도록 */
}

.multi-spinner {
    width: 50px; /* 스피너 크기 */
    height: 50px;
    border: 5px solid #f7f7f7; /* 바깥쪽 색상 */
    border-top: 5px solid #0080ff; /* 스피너 색상 */
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>

<script>
import moment from "moment";
import Map from "@/components/map/Map";
import MapLineData from "@/components/map/data/MapLineData";
import MapDataType from "@/components/map/enum/MapDataType";
import MapMarkerData from "@/components/map/data/MapMarkerData";
import {getMobileImgDefault, getDeviceImgUrl, getDeviceImgDefault} from "@/api/device"

import Video from "@/components/video/videoPlayer"; Video
import {fetchLocation} from "@/api/location";
import {playerPopOpen2} from "@/util/popup";
import { fetchEvent } from "@/api/event";
import { fetchPointList, getPointTypeName } from "@/api/point";
import { mapState } from "vuex";

import { ko } from "vuejs-datepicker/dist/locale";
import { fetchLastResult,fetchResults,fetchLastResultImage } from "@/api/ai"; 

MapLineData
import { getLiveUrl } from "@/api/channel";
import WebRTC_WOWZA from "@/components/video/WebRTC_WOWZA";
import MapButtonComponent from "@/components/map/MapButtonComponent.vue";
import {updateDetectedObject} from "@/api/ai";
import {fetchChannelCheckStream, fetchChannelList} from "@/api/channel";
import {fetchDetectObject} from "../../../api/ai";
let deviceTypeName = "DEVICE";
let supportTypeName = "SUPPORT";
let pointTypeName = "POINT";
let noticeTypeName = "NOTICE";
Map,MapButtonComponent
export default {
    components: { Map,WebRTC_WOWZA, MapButtonComponent},
    name: "AiVodDetail",

    data() {
        return {
            moment: moment,
            selectedSoundId: null,
            loadMap: 'kakao',
            viewSrc: "",
            vodUrl: "",

            isdrModelShow: true,
            eventId: null,
            eventInfo: null,
            contentsId: null,
            vodObj: {},

            locationList : [],
            lat: null,
            lng: null,
            locationSize: null,
            locationPage : 0,
            isPlayStatus: false,

            ko: ko,
            liveData : null,
            windowWidth: null,
            isFirstMove : false,
            checkBoxType: [deviceTypeName, supportTypeName, pointTypeName],

          locationInterval : null,
          lastResultInterval: null,

            showFourthDiv:false,
            aiLastResult : null,
            aiResult : null,
            aiResultPage: 0,
            lastResultImgUrl : null,
            aiConfidence : 0.7,
            isFirst : true,
            isAiResultTaks : true,

            // AI 탐지 오브젝트
            detectObj: [
              {
                'name': this.$t('ai-channel-object'),
                'value': 'OBJECT'
              },
              {
                'name':this.$t('ai-channel-forest-fires'),
                'value': 'SMOKE'
              },
              {
                'name':this.$t('ai-channel-thermal'),
                'value': 'THERMAL'
              }
            ],
          selectedDetectList: [],

          channelStreamIds : [], //
          channelList : [], // 모든 채널 리스트
          viewChannelList: [], // 화면에 보여질 채널리스트
          aiScreenVisible: 1,

          size : 100,
          currentPage :0,
          keywordType : "all",
          keyword : "",
          deviceType : null,
          status : null,
          isRecord : false,
        };
    },
    computed: {
        ...mapState({
            currentLang: state => state.store.currentLang,
            userType: state => state.store.userType,
            accountId: state => state.store.accountId,
        }),
        deviceTypeName : function(){
            return deviceTypeName;
        },
        supportTypeName : function(){
            return supportTypeName;
        },
        pointTypeName : function(){
            return pointTypeName;
        },
        noticeTypeName : function(){
            return noticeTypeName;
        },

    },
    watch: {
        currentLang(value) {
            this.initMapData(value);
        },
    },
    mounted() {
        this.$refs.map.isShowLine = true
        this.getEvent();
        this.initMapData(this.$store.state.store.currentLang);
        this.initButtonMap()


        this.locationInterval = setInterval( ()=>{
          this.getAiChannelList()
          this.getMultiviewDetectObject()
        },2000)

        this.lastResultInterval = setInterval(()=> {
          if(this.viewChannelList.length > 0 && this.aiScreenVisible === 1){
            this.getLocation(this.viewChannelList[0])
            this.getLastResultImg()
            this.getAILastResult()
            if(this.isAiResultTaks){
              this.getAiResult()
            }
          }
        }, 2000)

    },
    created() {
        if (this.eventId == null && this.$route.params.eventId != undefined) {
            this.eventId = this.$route.params.eventId
        }
    },
    methods: {
      getLiveData(index) {
        if(this.viewChannelList[index] === undefined) { return;}
        return this.viewChannelList[index];
      },

      playerLoadData(id, isLoad) {
        let findIndex = this.viewChannelList.findIndex((data) => {
          return data.channelId === id;
        });

        if (findIndex !== -1) {
          this.viewChannelList[findIndex].isLoad = isLoad;
          this.$set(this.viewChannelList, findIndex, this.viewChannelList[findIndex]);
          this.$set(this.selectedDetectList, findIndex, this.selectedDetectList[findIndex]);
        }
      },

      getAiChannelList() {
        // TODO: 채널 10개 이상될때 어케할지 생각해야함. 현재는 페이지하나당 채널 100개까지 받아오도록 되어있음.
        let params = {
          pageNumber: this.currentPage,
          pageSize: this.size,
          keywordType: this.keywordType,
          keyword: this.keyword,
          findStatuses: null,
          recordStatus: null,
          deviceTypes: this.deviceType,
          assignedStatus: "Assigned",
          isAi: true,
          eventId: this.eventId,
        };

        switch (this.status) {
          default:
          case null :
            params.assignedStatus = null;
            params.findStatuses = null;
            break;
          case "Assigned":
            params.assignedStatus = "Assigned";
            params.findStatuses = null;
            break;
          case "Unassigned":
            params.assignedStatus = "Unassigned";
            params.findStatuses = null;
            break;
          case "Error":
            params.assignedStatus = null;
            params.findStatuses = "Error";
            break;
        }

        if (this.isRecord) params.recordStatus = "Record"; else params.recordStatus = "";

        // this.persist(params);
        fetchChannelList(params)
            .then((res) => {
              if (res.data.result !== 0) { return }

              this.channelList = res.data.data.content

              //채널 추가 및 삭제 로직 & 삭제 아이디 찾기 위한 데이터 넣기
              let delIds = []; // 기존에 있던 viewChannelList
              // 기존 viewChannelList 에 있는 애들 delIds에 넣어줌.
              this.viewChannelList.forEach(item => {
                delIds.push(item.channelId.toString());
              });

              //streamIds에서 삭제할 데이터.
              let delStreamIds = []

              //채널 기존 채널 있는지 확인 및 삭제 아이디 에서 삭제
              this.channelList.forEach((live) => {
                // 스트림id, type=모바일,드론를 담고있음
                delStreamIds.push(this.checkStreamId(live))

                // 기존 viewChannel에서 channelList에 있는 애를 찾음
                let findIndex = delIds.findIndex(item => item === live.channelId);
                // 찾을시 삭제하려고 했던 리스트에서 빼줘야함.
                if (findIndex !== -1) { // viewChannelList에 있던 애들은 삭제항목에서 지워야함. 안그러면 영상 다시연결하니까 끊김.
                  delIds.splice(findIndex, 1);
                }
              })

              //streamIds에 존재하는 것중 channelList에 없는거 찾아서 삭제하기.
              this.channelStreamIds.forEach(item => {
                let tempIndex = delStreamIds.findIndex(delItem => delItem.channelStreamId === item.channelStreamId)
                if (tempIndex === -1) {
                  let delId = this.channelStreamIds.findIndex(delItem => delItem.channelStreamId === item.channelStreamId)
                  this.channelStreamIds.splice(delId, 1)
                }
              })

              // 현재상태에서 delIds 는 channelList에는 없지만, viewChannelList에는 들어있는 애들임.
              // 즉 delIds에 있는 애들은 viewChannelList에서 찾아서 삭제해야하는애임.
              delIds.forEach(delId => {
                let findIndex = this.viewChannelList.findIndex(item => item.channelId === delId);
                if (findIndex !== -1) {
                  let channel = this.viewChannelList[findIndex];
                  this.$refs.map.onDeleteMarkerAt(MapDataType.ID_DRONE, channel.channelId);

                  this.viewChannelList.splice(findIndex, 1);
                  this.selectedDetectList.splice(findIndex, 1);
                }
              })

              // 새로 추가된 채널추가
              for (var i = 0; i < this.channelList.length; i++) {
                this.addStreamChanelToChannel(this.channelList[i])
              }

              //기본에 데이터 변경
              this.channelList.forEach((item) => {
                this.channelDataChange(item)
              })
              console.log(this.viewChannelList)
        });

      },

      persist(params){
        localStorage.setItem("channel_searchData", JSON.stringify(params)); // 로컬 스토리지에 저장
      },

      checkStreamId (data) {
        return {
          channelStreamId: data.assignedDeviceType === 'Drone' ? data.assignedDeviceLogId : data.assignedWorkerId,
          type: data.assignedDeviceType
        };
      },

      addStreamChanelToChannel(channel){
        // 채널 연결상태 확인.
        fetchChannelCheckStream(channel.channelId).then((res)=>{
          // 연결 안되어있을시, 추가안하고 리턴
          if(res.data.result !== 0 || res.data.data.bytesIn === 0) { return }

          // 기존에 있던 채널은 따로 추가안하고, 새로 연결된 채널을 추가.
          let findIndex = this.viewChannelList.findIndex(item => item.channelId === channel.channelId);
          if (findIndex === -1) { // 새로 추가해야 하는 채널일때
            channel.isLoad = false;
            if( this.addCheckViewChannel() ){
              let channelStreamId = channel.assignedDeviceType == "Drone" ? channel.assignedDeviceLogId : channel.assignedWorkerId
              this.channelStreamIds.push({type : channel.assignedDeviceType, channelStreamId:channelStreamId})
              this.viewChannelList.push(channel);
              this.selectedDetectList.push({
                'name':"산불",
                'value': 'SMOKE'
              })

            }
          }
        })
      },


      // 새로 받아온 채널리스트와, viewChannelList를 비교
      // 같은 인덱스를 찾으면(스트림중이면),
      channelDataChange(changeData){
        let findIndex = this.viewChannelList.findIndex(item => item.channelId === changeData.channelId);
        if(findIndex !== -1) {
          this.viewChannelList[findIndex].height = changeData.height
          this.viewChannelList[findIndex].hspeed = changeData.hspeed
          this.viewChannelList[findIndex].vspeed = changeData.vspeed
          this.viewChannelList[findIndex].battery = changeData.battery
          this.viewChannelList[findIndex].lng = changeData.lng
          this.viewChannelList[findIndex].lat = changeData.lat
          this.viewChannelList[findIndex].distance = changeData.distance
          this.viewChannelList[findIndex].heartRate = changeData.heartRate

          this.$set(this.viewChannelList, findIndex, this.viewChannelList[findIndex]);
          this.$set(this.selectedDetectList, findIndex, this.selectedDetectList[findIndex]);
        }
      },

      addCheckViewChannel(){
        let result = false;

        if(this.aiScreenVisible === 1 && this.viewChannelList.length < 3 ) {
          result = true;
        }

        if(this.aiScreenVisible === 2 && this.viewChannelList.length < 4 ) {
          result = true;
        }

        if(this.aiScreenVisible === 3 && this.viewChannelList.length < 9 ) {
          result = true;
        }

        return result;
      },

      async changeDetectObject(data, selectChannelIndex) {
        if(this.selectedDetectList[selectChannelIndex] === data) { return}

        this.selectedDetectList[selectChannelIndex] = data
        const params = {
          media_key : this.viewChannelList[selectChannelIndex].linkedAiId,
          model_type: data.value
        }
        const res = await updateDetectedObject(params)
        res
      },

      initButtonMap() {
            this.$refs.buttonMap.showMapTypeChange()
            this.$refs.buttonMap.showMapCenter()
        },

      initMapData(value) {
            if (value == 'en') {
                this.loadMap = 'google'
            } else {
                this.loadMap = 'kakao'
            }
            this.getEventPoint();

            this.handleShowType();

            if (this.contentsId != null) {
                this.getLocation(this.contentsId);
            }
        },

      getEvent() {
            if (this.eventId == undefined) {
                return;
            }
            fetchEvent(this.eventId).then((res) => {
                if (res.data.result == 0) {
                    this.eventInfo = res.data.data;
                    if (this.$refs.map == undefined) {
                        return;
                    }
                    if (this.eventInfo.lng != null && this.eventInfo.lng != "" && this.eventInfo.lat != null && this.eventInfo.lat != "") {
                        this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
                        this.$refs.map.onCreateMapMarker(this.eventId, this.eventInfo.lng, this.eventInfo.lat, MapDataType.ID_SITE);
                        this.$refs.map.onSetLngLat(this.eventInfo.lng, this.eventInfo.lat);
                    } else {
                        this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
                    }
                }
            });
        },
        getEventPoint() {
            let params = {
                eventId: this.eventId,
                serverTime: null,
            };
            fetchPointList(params).then((res) => {
                if (res.data.result == 0) {
                    this.pointServerTime = res.data.data.serverTime
                    this.pointCount = res.data.data.total
                    this.pointList = res.data.data.content
                    this.pointList.forEach((element) => {
                        element.selectId = element.index;
                        element.selectType = "Point";
                        element.isOwner = false;
                        let pointMapMarker = new MapMarkerData();
                        element.name = this.$t(getPointTypeName(element.type));
                        pointMapMarker.setPointData(element);
                        this.$refs.map.onCreateMarker(MapDataType.ID_POINT, pointMapMarker);
                    })
                }
            });
        },
        customFormatter(date) {
            return moment(date).format("YYYY년 MM월 DD일");
        },

        async getLocation(viewChannelData) {
          console.log("viewChannelData", JSON.parse(JSON.stringify(viewChannelData)))

          if (viewChannelData === null || viewChannelData === undefined) { return }

          // 드론이나, 스테이션 드론일 경우
          const isDroneType = viewChannelData.assignedDeviceType === 'Drone' || viewChannelData.assignedDeviceType === 'StationDrone';
          //logId, workId 가 뭐임?
          viewChannelData.selectId = isDroneType ? viewChannelData.assignedDeviceLogId : viewChannelData.assignedWorkerId;
          viewChannelData.name = isDroneType ? viewChannelData.assignedDeviceName : viewChannelData.assignedUserName;

          let isMain = viewChannelData.assignedDeviceType === 'Mobile' ? false : true
          let mapDataType = this.getSelectTypeToMapDataType(viewChannelData.assignedDeviceType);

          let params = {
            refId: viewChannelData.selectId,
            eventId: this.eventId,
            offsetIndex: this.$refs.map.onGetLineSize(mapDataType, viewChannelData.selectId),
          };

          const res = await fetchLocation(params)
          if (res.data.result !== 0) { return }
          if (res.data.data.content.length <= 0) { return }

          this.locationList = res.data.data.content.sort((a, b) => {
            return a.index - b.index
          })

          let location = res.data.data.content[res.data.data.content.length - 1]; // 마지막 위치점을 찾음
          console.log("locationlist, location", this.locationList, location)

          if(mapDataType == MapDataType.ID_DRONE) {
            viewChannelData.distance = location.distance;
            viewChannelData.gimbalDirection = location.gimbalDirection;
            viewChannelData.height = location.height;
            viewChannelData.rotate = location.azimuth;
            viewChannelData.battery = location.battery
            viewChannelData.hspeed = location.hspeed
            viewChannelData.vspeed = location.vspeed

            if(this.isFirstMove === false) { //드론 처음 위치 정보 가져오면 그때 이동 (한번만 이동)
              this.isFirstMove = true;
              this.$refs.map.onLngLatToMoveCenter(location.lng, location.lat);
            }
          }

          viewChannelData.lat = location.lat;
          viewChannelData.lng = location.lng;
          console.log("after viewChannelData", JSON.parse(JSON.stringify(viewChannelData)))

          if(viewChannelData.lat !== undefined && viewChannelData.lng !== undefined){
            this.changeMapLatLng(location.lat, location.lng);
          }else{
            this.changeMapLatLng(this.eventInfo.lat, this.eventInfo.lng);
          }

          this.createMapMarker(mapDataType, viewChannelData, isMain);
          this.createMapLine(mapDataType, viewChannelData, res.data.data.content);
          this.$forceUpdate();
        },
        playerRemove(id){
          const findIndex = this.viewChannelList.findIndex((channel)=> {
            return channel.channelId === id
          })
          if(findIndex !== -1){
            this.viewChannelList.splice(findIndex,1);
          }
        },
        toggleSound(channelId){
            this.selectedSoundId = channelId
         },
        playerOpen(type,id,index) {
          playerPopOpen2(type, id, index);
        },
        getSelectTypeToMapDataType(selectType){
            let mapDataType = null;
            if(selectType == null || selectType == undefined) { return mapDataType}

            switch (selectType){
                case "Drone" :
                mapDataType = MapDataType.ID_DRONE;
                break;
                case "StationDrone" :
                mapDataType = MapDataType.ID_DRONE;
                break;
                case "Worker" :
                mapDataType = MapDataType.ID_USER;
                break;
                case "Mobile" :
                mapDataType = MapDataType.ID_STREAMER;
                break;
                case "Controller" :
                mapDataType = MapDataType.ID_CONTROLLER;
                break;
                case "Point" :
                mapDataType = MapDataType.ID_POINT;
                break;
                case "Station" :
                mapDataType = MapDataType.ID_STATION;
                break;
            }
            return mapDataType;
        },
        changeMapLatLng(lat, lng){
            if(lat == null || lat == undefined || lng == null || lng == undefined) {
                return;
            }

            this.$refs.map.onSetLngLat(lng, lat);
        },
        createMapMarker(mapDataType, data, isMain) {
            if(this.$refs.map == undefined) {
                return;
            }
            let mapMarkerData = new MapMarkerData();
            mapMarkerData.id = data.selectId;
            mapMarkerData.isMain = isMain;
            switch (mapDataType) {
                case MapDataType.ID_SITE :
                    mapMarkerData.lat = data.lat;
                    mapMarkerData.lng = data.lng;
                    break;

                case MapDataType.ID_DRONE :
                    mapMarkerData.setDroneData(data);
                    break;

                case MapDataType.ID_USER:
                case MapDataType.ID_STREAMER:
                case MapDataType.ID_CONTROLLER:
                    mapMarkerData.setUserData(data);
                    break;

                case MapDataType.ID_STATION:
                mapMarkerData.setStationData(data);
                    break;

                case MapDataType.ID_MAP_AI_MARKER :
                    mapMarkerData.lat = data.lat;
                    mapMarkerData.lng = data.lng;
                    mapMarkerData.name = data.confidence //가장큰 정확도
                    break;
            }

            if (mapDataType == MapDataType.ID_USER) {
                var filtr = this.channelList.filter(item => {
                return item.assignedWorkerId == data.selectId;
                })
                if (filtr.length > 0) {
                mapMarkerData.isUserLive = true;
                }
            }

            if (this.$refs.map.onIsMarker(mapDataType, data.selectId)) {
                this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
            } else {
                this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
            }
        },
        createMapLine(mapDataType, data, locationGroup) {
            let mapLineData = new MapLineData();
            mapLineData.id = data.selectId; //
            mapLineData.locationGroup = locationGroup; // 이동경로들

            if (this.$refs.map.onIsLine(mapDataType, data.selectId)) {
                this.$refs.map.onModifyLineAddAt(mapDataType, mapLineData);
            } else {
                this.$refs.map.onCreateLine(mapDataType, mapLineData);
            }
        },
        handleShowType() {
            if(this.$refs.map == undefined) {
                return;
            }

            if (this.checkBoxType.includes(this.deviceTypeName)) {
                this.$refs.map.onSetIsShowDataType(MapDataType.ID_DRONE, true);
            } else {
                this.$refs.map.onSetIsShowDataType(MapDataType.ID_DRONE, false);
            }
            if (this.checkBoxType.includes(this.supportTypeName)) {
                this.$refs.map.onSetIsShowDataType(MapDataType.ID_CONTROLLER, true);
            } else {
                this.$refs.map.onSetIsShowDataType(MapDataType.ID_CONTROLLER, false);
            }
            if (this.checkBoxType.includes(this.supportTypeName)) {
                this.$refs.map.onSetIsShowDataType(MapDataType.ID_USER, true);
            } else {
                this.$refs.map.onSetIsShowDataType(MapDataType.ID_USER, false);
            }
            if (this.checkBoxType.includes(this.pointTypeName)) {
                this.$refs.map.onSetIsShowDataType(MapDataType.ID_POINT, true);
            } else {
                this.$refs.map.onSetIsShowDataType(MapDataType.ID_POINT, false);
            }
            if (this.checkBoxType.includes(this.pointTypeName)) {
                this.$refs.map.onSetIsShowDataType(MapDataType.ID_STREAMER, true);
            } else {
                this.$refs.map.onSetIsShowDataType(MapDataType.ID_STREAMER, false);
            }
        },
        getLiveUrl(channelData) {
            var url = getLiveUrl(channelData);
            if (url == '-') {
                return ''
            }
            return url
        },

      /**
       * 탐지하고있는 객체를 받아서 프론트화면에서 변경하는 함수
       * viewchannelList의 개수만큼 getDetectObject()를 리스트에 담는다
       * Promissa.all로  요청을 보낸후, 설정된 selectedDetectList[index] 와 응답으로온 detect model타입이 다를경우
       * 프론트에서 ai탐지 대상을 서버에서 온 model_type 으로 바꿔줌
       * @returns {Promise<void>}
       */
      async getMultiviewDetectObject() {
        let fetchList = []
        this.viewChannelList.forEach((_, index)=> {
          fetchList.push(this.getDetectObject(index))
        })

        const res = await Promise.all(fetchList)

        res.forEach((object, index) => {
          console.log("modelType ",this.selectedDetectList[index].value, object.model_type)
          if(this.selectedDetectList[index].value !== object.model_type)  {
            const matchedObj = this.detectObj.find(obj => obj.value === object.model_type);
            if (matchedObj) {
              this.selectedDetectList[index] = matchedObj
            }
          }
        })
      },

      /**
       * 탐지하고 있는 객체를 서버로부터 가져옴.
       * @param index viewChannelList의 index값
       * @returns {Promise<any>}
       */
      async getDetectObject(index) {
        const params = {
          media_key : this.viewChannelList[index].linkedAiId,
        }
        const res = await fetchDetectObject(params)
        return res.data
      },

      async getAILastResult(){
        const params = {
          media_key : this.viewChannelList[0].linkedAiId,
          conf :  0.1
        }
        try {
          const res = await fetchLastResult(params)

          if(res.request.status !== 200){ return null}
          if(res.data.length === 0) { return null}
          this.aiLastResult = res.data[0].bboxes

        } catch (error) {
          console.error(error)
        }
      },

        getAiResult(){
            //let now = new Date();
            //console.log("getAiResult Start" ,now.getHours() + ':' + now.getMinutes() + ':' + now.getSeconds());
            this.isAiResultTaks = false
            var params = {
                media_key : this.viewChannelList[0].linkedAiId,
                conf :  this.aiConfidence
            }
            fetchResults(params).then((res)=>{
                if(res.request.status == 200){
                    if(res.data.length != 0){
                        this.aiResult = res.data
                    }
                    this.findAiMaxConfidence();
                }
            }).catch(() => {
               // console.error("디텍팅 전체 결과를 불러오는데 실패했습니다.", error);
            }).finally(()=>{
                this.isAiResultTaks = true
                //console.log("getAiResult End" ,now.getHours() + ':' + now.getMinutes() + ':' + now.getSeconds());
            });
        },
        getDeviceImg(data){
        if(data.assignedDeviceType == "Mobile"){
            return getMobileImgDefault(data.assignedDeviceType);
        }
        var img = data.assignedDeviceDefaultIsImage ? getDeviceImgDefault(data.assignedDeviceManufacturerId, data.assignedDeviceModelId) : getDeviceImgUrl(data.accountId, data.assignedDeviceId) + '?date='+(new Date)
        return img
        },
        getLastResultImg(){
            let params = {
                media_key : this.viewChannelList[0].linkedAiId,
            }
            fetchLastResultImage(params).then(res=>{
                var blob = new Blob([res.data])
                this.lastResultImgUrl = URL.createObjectURL(blob);
            })
            .catch((err) => {
              console.error("이미지를 불러오는데 실패했습니다.", err);
            });
        },
        percentage(value) {
            return `${(value * 100).toFixed(1)}%`;
        },

      aiChangeTab(tabNumber) {
        this.aiScreenVisible = tabNumber;
      },
      findAiMaxConfidence(){

            // var t1 = moment(this.aiResult[this.aiResult.length-1].event_time).add(9, 'hours').format("YYYY:MM:DD HH:mm:ss");
            // var t2 = moment(this.locationList[this.locationList.length-1].time).format("YYYY:MM:DD HH:mm:ss");
            // console.log("시간" , t1, t2)
            var locationSize = this.locationList.length
            var aiResultSize = this.aiResult.length;
            for (var k = this.locationPage; k < locationSize; k++) {
                var item = this.locationList[k]
                var locationTime = moment(item.time).format("YYYY:MM:DD HH:mm:ss");
                var aiTempList = [];

                for (var i = this.aiResultPage; i < aiResultSize; i++) {
                    var ai = this.aiResult[i];
                    var aiTime = moment(ai.event_time).add(9, 'hours').format("YYYY:MM:DD HH:mm:ss");

                    if (locationTime == aiTime) {
                        // bboxes를 confidence 순서대로 정렬
                        ai.bboxes.sort((a, b) => b.confidence - a.confidence);

                        // confidence 값을 변환
                        ai.confidence = this.percentage(ai.bboxes[0].confidence);
                        ai.lat = item.lat;
                        ai.lng = item.lng;
                        ai.selectId = locationTime;

                        // 조건을 만족하는 ai 객체를 aiTempList에 추가
                        aiTempList.push(ai);
                    }
                }
                this.aiResultPage = aiResultSize -1
                if(aiTempList.length != 0){
                    aiTempList.sort((a,b) => {
                        return b.confidence - a.confidence
                    })
                    this.createMapMarker(MapDataType.ID_MAP_AI_MARKER ,aiTempList[0],true )
                    if(this.isFirst){
                        this.$refs.map.onLngLatToMoveCenter(aiTempList[0].lng, aiTempList[0].lat)
                        this.isFirst = false
                    }

                }
            }
            this.locationPage = locationSize - 1
        },
},

  beforeDestroy() {
    clearInterval(this.locationInterval);
    clearInterval(this.lastResultInterval);
  },
}
</script>

<style>

</style>