// import Cookies from "js-cookie";
import VueCookies from "vue-cookies";
import i18n from "@/lang";

const state = {
  secretKey : "18b00b2fc5f0e0ee40447bba4dabc952",
  iv : "14378110db6392f9",
  accountId: "",
  departmentName: "",
  status: "",
  isAdmin: "",
  userType: "",
  ownerWork: "",
  phone: "",
  name: "",
  userId: "",
  loginId: "",
  device: "",
  registDate: "",
  currentLang: VueCookies.get("language") || "ko",
  autoLogin: true,
  isLogin: false,
  navVisible: true,
  gnbVisible: true,
  siteVisible:0,
  footerVisible: false,
  navbarState: 1,
  subMenuState: 0, //탑 메뉴 - selectBox Menu
  options: [
    //메뉴 순서
    [
      //대쉬보드
      {
        value: `/${localStorage.getItem("accountId")}/dashboard/overallManager`,
        label: i18n.t("top-select-total-view-dashboard"),
        title: i18n.t("top-select-total-drone-status-map"),
      },
      {
        value: `/${localStorage.getItem("accountId")}/dashboard/overallAdmin`,
        label: i18n.t("overall-site-control-Admin"),
      },
      {
        value: `/${localStorage.getItem("accountId")}/dashboard/dashChannelList`,
        label: i18n.t("top-select-total-view-live"),
      },
      {
        value: `/${localStorage.getItem("accountId")}/dashboard/DroneStatusMap`,
        label: i18n.t("top-select-total-drone-map-monitoring"),
      },
      {
        value: `/${localStorage.getItem("accountId")}/dashboard/monitoring/DroneLiveMap`,
        label: i18n.t("top-select-total-drone-map-monitoring"),
      },
      {
        value: `/${localStorage.getItem("accountId")}/dashboard/monitoring/DroneLiveMultiView`,
        label: i18n.t("top-select-total-drone-map-monitoring"),
      },
    ],
    //실시간 현장
    [
      {
        value: `/${localStorage.getItem("accountId")}/eventControl/liveListControl`,
        label: i18n.t("overall-site-control"),
        title: i18n.t("overall-site-control"),
      },
      {
        value: `/${localStorage.getItem("accountId")}/eventControl/liveListControlUser`,
        label: i18n.t("overall-site-control"),
      },
      {
        value: `/${localStorage.getItem("accountId")}/dashboard/dashDeviceList`,
        label: i18n.t("overall-drone-title"),
       /* title: '디바이스',*/
      },
      {
        value: `/${localStorage.getItem("accountId")}/dashboard/dashContentsList`,
        label: i18n.t("top-select-total-view-vod"),
        /*title: '콘텐츠',*/
      },
    ],
    //통계
    [
      {
        value: `/${localStorage.getItem("accountId")}/statistics/eventStatistics`,
        label: i18n.t("top-select-site-statistics-site"),
        title: '통계',
      },
      {
        value: `/${localStorage.getItem("accountId")}/statistics/eventUserStatistics`,
        label: i18n.t("top-select-site-statistics-member"),
      },
      {
        value: `/${localStorage.getItem("accountId")}/statistics/addressEventStatistics`,
        label: i18n.t("top-select-site-statistics-direction"),
      },
    ],
    //현장
    [
      {
        value: `/${localStorage.getItem("accountId")}/event/list`,
        label: i18n.t("top-select-site-manager"),
        title: '현장관리',
      },
    ],
    //디바이스
    [
      {
        value: `/${localStorage.getItem("accountId")}/device/deviceList`,
        label: i18n.t("top-select-device-manager-drone"),
        title: i18n.t("top-select-device-manager"),
      },
      {
        value: `/${localStorage.getItem("accountId")}/device/deviceLogList`,
        label: i18n.t("top-select-device-manager-details"),
      },
    ],
    //사용자
    [
      {
        value: `/${localStorage.getItem("accountId")}/user/userList`,
        label: i18n.t("top-select-user-manager"),
        title: i18n.t("top-select-user-dept-manager"),
      },
      {
        value: `/${localStorage.getItem("accountId")}/user/teamList`,
        label: i18n.t("top-select-user-manager-dept"),
      },
      {
        value: `/${localStorage.getItem("accountId")}/user/accessLogList`,
        label: i18n.t("top-select-user-manager-member-log"),
      },
    ],
    //컨텐츠
    [
      {
        value: `/${localStorage.getItem("accountId")}/contents/contentsList`,
        label: i18n.t("top-select-vod-manager-contents"),
        title: '영상 데이터 관리',
      },
    ],
    //채널
    [
      {
        value: `/${localStorage.getItem("accountId")}/channel/channelList`,
        label: i18n.t("top-select-live-data-ch-list"),
        title: '라이브 채널 관리',
      },
      {
        value: `/${localStorage.getItem("accountId")}/channel/channelLogList`,
        label: i18n.t("top-select-live-data-allocation"),
      },
    ],
    //site control 탭 이름
    [
      {
        value: `/${localStorage.getItem("accountId")}/eventControl/${localStorage.getItem("eventId")}/SkyView`,
        label: i18n.t("top-select-total-drone-map-monitoring"),
        title: '현장 대시보드',
      },
      {
        value: `/${localStorage.getItem("accountId")}/eventControl/${localStorage.getItem("eventId")}/Information`,
        label: i18n.t("현장 정보"),
        title: '현장 대시보드',
      },
      {
        value: `/${localStorage.getItem("accountId")}/eventControl/${localStorage.getItem("eventId")}/VOD`,
        label: localStorage.getItem("accountId")=='komipo'? "사용자 촬영 영상": i18n.t("site-control-contents-sub-vod"),
        title: '콘텐츠 현황',
      },
      {
        value: `/${localStorage.getItem("accountId")}/eventControl/${localStorage.getItem("eventId")}/liveStream`,
        label: i18n.t("site-control-contents-sub-live"),
        title: '현장 대시보드',
      },
      {
        value: `/${localStorage.getItem("accountId")}/eventControl/${localStorage.getItem("eventId")}/MissionVOD`,
        label: i18n.t("site-control-contents-sub-mission-vod"),
        title: '콘텐츠 현황',
      },
      {
        value: `/${localStorage.getItem("accountId")}/eventControl/${localStorage.getItem("eventId")}/LiveMap`,
        label: i18n.t("site-control-contents-sub-live-map"),
        title: '콘텐츠 현황',
      },
      {
        value: `/${localStorage.getItem("accountId")}/eventControl/${localStorage.getItem("eventId")}/Drone`,
        label: i18n.t("site-control-device-sub-drone-title"),
        title: '디바이스 현황',
      },
      {
        value: `/${localStorage.getItem("accountId")}/eventControl/${localStorage.getItem("eventId")}/Point`,
        label: i18n.t("site-control-menu-point"),
        title: '주요지점 현황',
      },
      {
        value: `/${localStorage.getItem("accountId")}/eventControl/${localStorage.getItem("eventId")}/Notice`,
        label: i18n.t("site-control-menu-notice"),
        title: '업무공지 현황',
      },
      {
        value: `/${localStorage.getItem("accountId")}/eventControl/${localStorage.getItem("eventId")}/Mission`,
        label: i18n.t("site-control-mission-sub-list"),
        title: '자동경로비행 현황',
      },
      {
        value: `/${localStorage.getItem("accountId")}/eventControl/${localStorage.getItem("eventId")}/Create`,
        label: i18n.t("site-control-mission-sub-create"),
        title: '자동경로비행 현황',
      },
      {
        value: `/${localStorage.getItem("accountId")}/eventControl/${localStorage.getItem("eventId")}/Upload`,
        label: i18n.t("site-control-mission-sub-upload"),
        title: '자동경로비행 현황',
      },
      {
        value: `/${localStorage.getItem("accountId")}/eventControl/${localStorage.getItem("eventId")}/Member`,
        label: i18n.t("site-control-menu-member"),
        title: '현장인력 현황',
      },
      {
        value: `/${localStorage.getItem("accountId")}/eventControl/${localStorage.getItem("eventId")}/userEdit`,
        label: i18n.t("user-info-edit"),
        title: '개인정보 수정',
      },
      {
        value: `/${localStorage.getItem("accountId")}/eventControl/${localStorage.getItem("eventId")}/DroneStation`,
        label: i18n.t("site-control-device-sub-drone-station"),
        title: '디바이스 현황',
      },
      {
        value: `/${localStorage.getItem("accountId")}/eventControl/${localStorage.getItem("eventId")}/MissionDetail`,
        label: i18n.t("site-control-mission-sub-detail"),
        title: '자동경로비행 현황',
      },
      {
        value: `/${localStorage.getItem("accountId")}/eventControl/${localStorage.getItem("eventId")}/Flight`,
        label: i18n.t("site-control-device-sub-flight-title"),
        title: '비행기록 현황',
      },
    ],
    [
      {
        value: `/${localStorage.getItem("accountId")}/user/userConfirmList`,
        label: i18n.t("top-select-user-confirm-manager"),
        title: i18n.t("top-select-user-confirm-manager"),
      },
    ],

    [
      {
        value: `/${localStorage.getItem("accountId")}/dashboard/dashDeviceList`,
        label: i18n.t("overall-drone-title"),
        title: '디바이스',
      },
    ],

    [
      {
        value: `/${localStorage.getItem("accountId")}/dashboard/dashContentsList`,
        label: i18n.t("top-select-total-view-vod"),
        title: '콘텐츠',
      },
    ],
  ],

  /**
   * 241018 - 신현호
   * 뭔가 할때마다 getEvent로 현장 받아오는게 싫어서 만듬
   */
  selectedEvent: {}

};

export default state;
