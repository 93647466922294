<template>
    <div class="contents allVod">
        <h1 style="height: 50px;line-height: 50px;margin-top:50px;">{{$t("site-control-contents-sub-panorama")}}</h1>
        <div class="content-box">
            <section class="panorama-main">
                <div class="panorama-img">
                    <View360 ref="panoramaView" :projection="projection" :autoplay="false" autoInit: true
                        style="width: 100%; height: 600px; margin-bottom:30px;" />
                    <Map v-show="isGPS" style="position:absolute; width: 300px; height: 200px;top: 15px;right: 15px;"
                        ref="map" :loadMapName="loadMap" :isRightLngLat="true" />
                </div>
                <div class="panorama-cover" v-if="!isShowPanorama">
                    <img src="@/assets/images/panoramic-img.png">
                    <div class="cover-comment">
                        <h3>{{$t("panorama-load-comment")}}</h3>
                        <span>{{$t("panorama-caution-comment")}}</span>
                    </div>
                </div>
                <div class="panorama-loading" v-if="isLoading">
                    <img src="@/assets/images/panoramic-img.png" loading="lazy">
                    <span>{{$t("panorama-loading")}}</span>
                </div>
                <div class="panorama-noneGps" v-else-if="!isGPS && isShowPanorama">
                    <img src="@/assets/images/map_none.png" loading="lazy">
                    <span>{{$t("panorama-none-gps")}}</span>
                </div>
                <div class="panorama-info" v-if="showPanoramaInfo">
                    <div class="panorama-arrow">
                        <div class="left-arrow">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div class="right-arrow">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    <h3>{{$t("panorama-use-comment")}}</h3>
                </div>
            </section>
            <div class="panorama-input">
                <input type="file" id="upload-image" accept="image/*" @change="changeImage" ref="fileInput" style="display: none;" />
                <label for="upload-image" class="custom-upload">
                {{ $t('file.choose') }}
                </label>
                <span v-if="selectedFileName">{{ selectedFileName }}</span>
                <span v-else>{{ $t('panorama-input-file') }}</span>
            </div>
        </div>
    </div>
</template>


<script>
import { View360, EquirectProjection, EVENTS } from "@egjs/vue-view360";
import "@egjs/vue-view360/css/view360.min.css";
import ExifReader from 'exifreader';
import Map from "@/components/map/Map.vue";
import MapMarkerData from "@/components/map/data/MapMarkerData";
import MapDataType from "@/components/map/enum/MapDataType";
export default {
    components: {
        View360, Map
    },
    data() {
        return {
            key: 0,
            projection: null,
            originImage: null,
            loadMap: 'kakao',
            droneData: {
                selectId: 1,
                lng: 0,
                lat: 0,
                deviceName: "",
                azimuth: null,
                gimbalDirection: null,
                distance: 0,
                height: 0,
                userName: "",
                userDepartmentName: ""
            },
            isGPS: true,
            isShowPanorama: false,
            isLoading: false,
            showPanoramaInfo: false,
            selectedFileName: null,
        }
    },
    created() {
        // 초기화 안하면 에러나서 해야함.
        this.projection = new EquirectProjection({
            src: require("../../../assets/panorama_loading_back.jpg")
        })
    },
    mounted() {
        this.initPanorama()
    },

    methods: {
        initPanorama() {
            // 파노라마 화면돌릴때, 줌인아웃 등의 이벤트
            this.$refs.panoramaView.on(EVENTS.VIEW_CHANGE, this.onChangePanoramaViewer);
        },

        async changeImage(e) {
            try {
                const imageData = e.target.files[0]
                const file = event.target.files[0];

                //이미지 없으면 리턴
                if (imageData === undefined || imageData === null) {
                    return
                }

                this.projection = new EquirectProjection({
                    src: require("../../../assets/panorama_loading_back.jpg")
                });

                // 커버이미지 제거, 로딩중표시
                this.isShowPanorama = true
                this.isLoading = true

                // 이미지인코딩
                const imageSrc = await this.base64(imageData)
                this.projection = new EquirectProjection({
                    src: imageSrc
                });

                // 이미지XMP 데이터
                const imageInfo = await this.getImageInfo(imageSrc)

                //드론데이터 설정
                this.changeDroneInfo(imageInfo)
                this.drawMap()
                this.isLoading = false

                // 파노라마 정보 표시 후 3초 뒤에 숨기기
                this.showPanoramaInfo = true;
                setTimeout(() => {
                    this.showPanoramaInfo = false;
                }, 6000); // 3초 후에 숨김
                this.selectedFileName = file ? file.name : null;
                
            } catch (error) {
                alert("이미지를 처리하는 중에 오류가 발생했습니다. 다시 시도해 주세요.")
                this.isLoading = false
            }

        },

        onChangePanoramaViewer(e) {
            this.droneData.gimbalDirection = e.yaw
            this.drawMap()
        },

        async getImageInfo(image) {
            try {
                const imageInfo = await ExifReader.load(image);
                return imageInfo
            } catch (err) {
                console.log(err)
                return err
            }
        },

        changeDroneInfo(imageInfo) {
            if (imageInfo.GimbalYawDegree === undefined || imageInfo.GimbalYawDegree === null) {
                this.isGPS = false
            } else {
                this.droneData.gimbalDirection = Number(imageInfo.GimbalYawDegree.value)
                this.droneData.lng = imageInfo.GPSLongitude.description
                this.droneData.lat = imageInfo.GPSLatitude.description
                this.droneData.deviceName = `${imageInfo.Make.description} ${imageInfo.Model.description}`
            }

        },

        drawMap() {
            // 이미지의 GPS값이 설정이 안되어있는경우
            if (this.droneData.lng === 0 || this.droneData.lng === 0) {
                this.isGPS = false
                return
            }

            this.isGPS = true

            const mapDataType = MapDataType.ID_DRONE
            const mapMarkerData = new MapMarkerData()
            mapMarkerData.setDroneData(this.droneData)
            this.$refs.map.onLngLatToMoveCenter(this.droneData.lng, this.droneData.lat);

            // 차후에 droneData의 id값이랑, onIsMarker(, index) 값 수정해야함
            if (this.$refs.map.onIsMarker(mapDataType, 1)) {
                this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
            } else {
                this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
            }


            this.$nextTick(() => {
                if (this.$refs.map) {
                    this.$refs.map.setLayout()
                }
            });
        },

        base64(file) {
            return new Promise((resolve, reject) => {
                if (!(file instanceof File)) {
                    return reject(new Error("Invalid input: Expected a File object."));
                }

                const reader = new FileReader();

                reader.onload = (e) => {
                    resolve(e.target.result);
                };

                reader.onerror = () => {
                    reject(new Error("Failed to read the file."));
                };

                reader.onabort = () => {
                    reject(new Error("File reading was aborted."));
                };

                try {
                    reader.readAsDataURL(file);
                } catch (error) {
                    reject(new Error("An unexpected error occurred: " + error.message));
                }
            });
        },
    },
}
</script>

<style lang="scss">
.title {
    display: none;
}

.is-16by9 {
    width: 1000px;

}

.content-box {
    background: #fff;
    padding: 15px;
    border-radius: 5px;
}

.panorama-content {
    width: 100%;
    height: 100%;
}

.panorama-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    line-height: 50px;
    margin-top: 50px;
}

.custom-upload {
  display: inline-block;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-size: 1.2rem;
}

.custom-upload:hover {
  background-color: #0056b3;
}

.panorama-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .panorama-img {
        position: relative;
        width: 100%;
    }

    .panorama-cover {
        position: absolute;
        width: 100%;
        height: 600px;
        top: 0;
        background: #ffffff;
        border: 2px dotted #ddd;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 130px;
        }

        .cover-comment {
            position: absolute;
            top: 70%;
            left: 50%;
            text-align: center;
            transform: translate(-50%, -50%);

            h3 {
                font-size: 1.6rem;
                margin-bottom: 10px;
            }

            span {
                font-size: 1.4rem;
                color: #969696;
            }
        }
    }

    .panorama-loading {
        position: absolute;
        width: 100%;
        height: 600px;
        top: 0;
        background: #ffffff;
        border: 2px dotted #ddd;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 130px;
        }

        span {
            position: absolute;
            top: 68%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 1.6rem;
        }
    }

    .panorama-noneGps {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 300px;
        height: 200px;
        background: #f7f7f7;

        span {
            position: absolute;
            top: 70%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 1.5rem;
            color: #969696;
            text-align: center;
        }

        img {
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 80px;
            opacity: 0.3;
        }
    }

    .original {
        width: 300px;
        height: 100px;
        object-fit: contain;
        position: absolute;
        bottom: 50px;
        right: -10px;
    }

    .panorama-info {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .panorama-arrow {
            display: flex;
            justify-content: space-around;
            margin-bottom: 20px;

            .left-arrow {
                transform: rotate(90deg);
                margin-right: 10px;
                cursor: pointer;

                span {
                    display: block;
                    width: 1.5vw;
                    height: 1.5vw;
                    border-bottom: 5px solid white;
                    border-right: 5px solid white;
                    transform: rotate(45deg);
                    margin: -10px;
                    animation: animate 2s infinite;
                }

                span:nth-child(2) {
                    animation-delay: -0.2s;
                }

                span:nth-child(3) {
                    animation-delay: -0.4s;
                }
            }

            .right-arrow {
                transform: rotate(270deg);
                margin-left: 10px;
                cursor: pointer;

                span {
                    display: block;
                    width: 1.5vw;
                    height: 1.5vw;
                    border-bottom: 5px solid white;
                    border-right: 5px solid white;
                    transform: rotate(45deg);
                    margin: -10px;
                    animation: animate 2s infinite;
                }

                span:nth-child(2) {
                    animation-delay: -0.2s;
                }

                span:nth-child(3) {
                    animation-delay: -0.4s;
                }
            }
        }

        h3 {
            font-size: 1.6rem;
            color: #fff;
        }
    }
}
.panorama-input{
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    display: flex;
    align-items: center;
    span{
        margin-left: 15px;
    }
}
@keyframes animate {
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-20px, -20px);
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: rotate(45deg) translate(20px, 20px);
    }
}
</style>