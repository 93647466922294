<template>
    <div class="contents allVod">
        <div class="calender-title">
            <h1>{{ $t("site-control-menu-schedule") }}</h1>
            <select @change="changeSchduleType">
                <option value="month" selected>{{ $t("schedule-select-month") }}</option>
                <option value="week" >{{ $t("schedule-select-week") }}</option>
                <option value="day">{{ $t("schedule-select-day") }}</option>
            </select>
        </div>

        <div class="content-row m-b-20">
            <div class="cal-lf">
                <div class="sp-add-btn">
                    <button @click="moveToCreatePage(0)">{{ $t("schedule-btn-create") }}</button>
                </div>
                <v-calendar  ref="leftcalendar" class="sp-cal" :attributes="filteredAttributes" title-position="left"   @dayclick="clickLeftCalendar" @transition-end="changeMonth">
                    <template #day-popover="{ day, format, masks }">
                        <div class="text-xs text-gray-300 font-semibold text-center">{{ format(day.date,
                            masks.dayPopover) }}</div>
                    </template>
                </v-calendar>

                <div class="sp-allCheck">
                    <h4><img src="@/assets/images/top_menu_icon_c4.png">{{ $t("schedule-btn-all") }}<span>({{ schedules.length }})</span>
                    </h4>
                    <input type="checkbox" v-model="isAllCheck"  >
                </div>
                <div class="sp-check-list">
                    <h2>{{ $t("schedule-list-title") }}</h2>
                    <div class="sp-list-title">
                        <span>{{ $t("schedule-list-station-name") }}</span>
                        <span>{{ $t("schedule-list-station-count") }}</span>
                    </div>
                    <ul>
                        <li v-for="(station, index) in stations" :key="index">
                            <h4><input type="checkbox" v-model="station.checked">{{ station.deviceName }}</h4>
                            <span>{{ stationList[`${station.deviceName}`] }}</span>
                        </li>
                    </ul>
                </div>
            </div>

            <Calendar class="cal-sheet" ref="calendar" :height="800" :key="calendarKey" :defaultView="calendarType"
                :timezone="timezone" :events="filteredEvents" :task="false" :week="weekSettings" @selectDateTime="clickRightCalendar"
                @clickEvent="clickScheduleEvent" />
        </div>

        <div v-if="isShowModal" class="info-modal-mask">
            <div class="info-modal-wrapper">
                <div class="info-modal-container">
                    <div class="modal-title" style="border: 0; margin:0; padding:0;">
                        <h3 style="margin-bottom: 0;">{{ selectedSchedule.scheduleName }}</h3>
                        <div class="modal-top-btn">
                            <button @click="cancleSchedule" style="margin-right: 10px;"><img src="@/assets/images/ic_day_delete.png" alt="icon" style="width: 14px;" /></button>
                            <button @click="isShowModal = false"><img src="@/assets/images/icon_svg/close-icon.png" alt="icon" style="width: 12px;" /></button>
                        </div>

                    </div>
                    <div class="modal-situation-mark">
                        <span>{{ scheduleTypeFormmat[selectedSchedule.scheduleType]}}</span>
                        <span :class="selectedSchedule.scheduleType ? 'flight-on' : 'flight-off'">{{ selectedSchedule.isFly ?
                            $t('schedule-flying') : $t('schedule-standby') }}</span>
                    </div>
                    <div class="info-modal-content">
                        <ul>
                            <li>
                                <h4><img src="@/assets/images/ic_station.png" alt="icon" />{{ $t("schedule-info-modal-station") }}</h4>
                                <h3>{{ selectedSchedule.stationDeviceName }}</h3>
                            </li>
                            <li>
                                <h4><img src="@/assets/images/ic_mission_b.png" alt="icon" />{{ $t("schedule-info-modal-way") }}</h4>
                                <h3>{{ selectedSchedule.missionTitle }}</h3>
                            </li>
                            <li>
                                <h4><img src="@/assets/images/ic_scheduleTime.png" alt="icon" />{{ $t("schedule-info-modal-time") }}</h4>
                                <h3>
                                    <span style="margin-right: 10px;">{{ moment(selectedSchedule.localTime,'hh:mm:ss').format('A h시 mm분')}}</span>
                                    <span v-if="selectedSchedule.flightType === 'Immediately'">&#40;1분후 출발 &#41;</span>
                                </h3>
                            </li>
                            <li>
                                <h4><img src="@/assets/images/ic_calendar_m.png" alt="icon" />{{ $t("schedule-info-modal-day") }}</h4>
                                <div v-if="selectedSchedule.isRepeat" class="calendar-info">
                                    <h3>
                                        <span v-for="(day,index) in repeatDays" :key="index">{{ days[day-1] }},</span>
                                    </h3>
                                    <h4><span>{{ selectedSchedule.beginLocalDate }}</span> ~
                                        <span>{{ selectedSchedule.endLocalDate }}</span>
                                    </h4>
                                </div>
                                <h3 v-else>{{ selectedSchedule.localDate }}</h3>
                            </li>
                            <li>
                                <h4><img src="@/assets/images/ic_member_b.png" alt="icon" />{{ $t("schedule-info-modal-author") }}</h4>
                                <h3><span>{{ selectedSchedule.registerUserName }}</span>│<span>{{ selectedSchedule.registerUserDepartmentName }}</span>
                                </h3>
                            </li>
                        </ul>
                    </div>
                    <div class="info-modal-btn">
                        <button class="addData-btn" @click="moveToEditPage">{{ $t("btn-learn-more") }}</button>
                        <button class="close-btn" @click="isShowModal = false">{{ $t("btn-confirm") }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import moment from "moment";
import { ko } from "vuejs-datepicker/dist/locale";
import Calendar from "@toast-ui/vue-calendar";
import "@toast-ui/calendar/dist/toastui-calendar.min.css";
import { fetchStationDeviceDockList } from "@/api/station/stationDevice";
import {  fetchStationScheduleTimeline, fetchStationScheduleCount, cancelStationSchedule } from "@/api/station/stationSchedule";

export default {
    components: {
        Calendar
    },
    name: "SchedulePlan",
    data() {
        return {
            moment: moment,
            ko: ko,
            eventId: null,
            scheduleTypeFormmat: {
                "Period": this.$t("schedule-fly-period"),
                "Reserve" : this.$t("schedule-fly-Reserve"),
                "Immediately" : this.$t("schedule-fly-Immediately"),
            },

            days: [
                this.$t("days-monday"), 
                this.$t("days-tuesday"), 
                this.$t("days-wednesday"), 
                this.$t("days-thursday"), 
                this.$t("days-friday"), 
                this.$t("days-saturday"), 
                this.$t("days-sunday")
            ],
            calendarType: 'month',
            timezone: {
                zones: [
                    {
                        timezoneName: "Asia/Seoul",
                        displayLabel: "Seoul"
                    }
                ]
            },

            schedules: [],
            stations: [],
            repeatDays: [],
            selectedSchedule: null,
            stationList: {},
            isShowModal : false,

            leftCalendarSchedule: [],
            selectDays: new Date(), // 왼쪽캘린더에서 선택한 날짜
            newStartDayOfWeek: null
        };
    },
    created() {
        this.eventId = this.$route.params.eventId
        this.newStartDayOfWeek = new Date().getDay()

    },
     mounted() {
        this.getStations()
        this.getMonthsSchedule()

        // 캘린더 defaultView 가 안바뀌어서 추가해둠
        this.$refs.calendar.getInstance().changeView('month')
    },
    methods: {
        changeMonth() {
            const calendar = this.$refs.leftcalendar
            const month = moment(calendar.pages[0].key).format('YYYY-MM-DD')
            this.getMonthsSchedule(month)
        },

        /**
         * 우측캘린더에 보이는 스케줄개수를 스테이션에 필더링하여 저장
         */
        getSchedulesCountFiltteredStation(){
            this.stationList = {}

            this.stations.forEach((station) => {
                this.stationList[station.deviceName] = 0;
            });

            for(let i=0; i<this.schedules.length; i++){
                const stationName = this.schedules[i].stationDeviceName;
                if (this.stationList[stationName] !== undefined) {
                    this.stationList[stationName] += 1;
                }
            }
        },

        /**
         * 스테이션 GET
         */
        getStations() {
            const params = {
                eventId: this.eventId
            }
            fetchStationDeviceDockList(params)
                .then((res)=> {
                  if(res.status !== 200) { return }

                  this.stations = res.data.data.content.map((stationAndDrone)=> {
                  return {
                      ...stationAndDrone.device,
                      checked: true // checked 속성 추가
                      };
                  })
                  this.getSchedules()
                })
        },

        /**
         * 스케줄 GET
         */
        async getSchedules() {
            let beginLocalDate = moment(this.selectDays)
            let endLocalDate = null

            if(this.calendarType === 'week') {
                endLocalDate = moment(this.selectDays).add(6,'days')
            } else if(this.calendarType === 'month') {
                beginLocalDate = moment(this.selectDays).startOf('month')
                endLocalDate = moment(this.selectDays).endOf('month')
            } else {
                endLocalDate = beginLocalDate
            }

            const params = {
                beginLocalDate : beginLocalDate.format('YYYY-MM-DD'),
                endLocalDate : endLocalDate.format('YYYY-MM-DD'),
                eventId: this.eventId
            }
            const res = await fetchStationScheduleTimeline(params)

            this.schedules = res.data.data.flatMap((item) => item.items)

            this.getSchedulesCountFiltteredStation()

        },


        /**
         * 특정 월의 일별 스케줄개수 GET
         * @param date : 왼쪽달력에서 선택한 날짜
         * 선택한 날짜의 시작일과, 끝일을 서버로 보내줌
         */
        async getMonthsSchedule(date) {
            const params = {
                'eventId': this.eventId,
                'beginLocalDate':  moment(date).startOf('month').format('YYYY-MM-DD'),
                'endLocalDate' : moment(date).endOf('month').format('YYYY-MM-DD')
            }

            const res = await fetchStationScheduleCount(params)

            this.leftCalendarSchedule = res.data.data.filter((item)=> {
                return item.count > 0
            })
        },

        /**
         * 선택한 일정이 취소가능여부 검사
         * 5분 이상 남았다면 취소가능
         */
        isCancleSchedule(){
          const currentTime = moment(); // 현재 시간
          const selectedTime = moment(`${this.selectedSchedule.localDate} ${this.selectedSchedule.localTime}`, "YYYY-MM-DD HH:mm:ss");

          const differenceInMinutes = selectedTime.diff(currentTime, 'minutes'); // 분 단위 차이

          // 차이가 5분 초과인지 확인
          return differenceInMinutes > 5;
        },

        /**
         * 일정 취소
         */
        cancleSchedule() {
            if(!this.isCancleSchedule()) {
                alert('출발 5분전 일정은 삭제할수없습니다.')
                return
            }
            const params = {
                cancelLocalDate : this.selectedSchedule.localDate
            }

            cancelStationSchedule(this.selectedSchedule.scheduleId, params)
            .then((res)=> {
                if(res.data.data === null) {
                    alert('삭제요청을 실패했습니다.')
                    return
                } else {
                    alert('삭제를 완료했습니다.')
                    this.getSchedules()
                }
            })

            this.isShowModal = false
        },


        /**
         * 왼쪽달력 클릭
         * @param e 왼쪽달력의 클릭시 들어오는 이벤트객체
         *
         */
        clickLeftCalendar(e) {
            this.selectDays = moment(e.date).format('YYYY-MM-DD');
            this.getMonthsSchedule(e.date)

            this.newStartDayOfWeek = e.weekday-1
            this.$refs.calendar.getInstance().setDate(`${e.id}T00:00:00`);

            this.getSchedules()
        },

        /**
         * 오른쪽달력 클릭
         * @param param0 선택한 날짜시간
         */
        clickRightCalendar({ start }) {
            this.moveToCreatePage(1, start)
        },

        /**
         * 스케쥴 클릭
         * @param param0 선택한 스케줄의 Id
         */
        clickScheduleEvent({ event }) {
          const findIndex =  this.schedules.findIndex((schedule)=> {
            return `${schedule.localDate}_${schedule.localTime}_${schedule.scheduleItemId}` ===event.id
          })

          this.selectedSchedule = this.schedules[findIndex]
          this.repeatDays = this.selectedSchedule.weekValues !== null ? this.selectedSchedule.weekValues.split(',').map(Number) : []
          this.isShowModal = true;
        },

        /**
         * 달력 형식 변경 (월, 주, 일)
         * @param e
         */
        changeSchduleType(e) {
            this.calendarType = e.target.value
            this.$refs.calendar.getInstance().changeView(this.calendarType)
            this.getSchedules()
        },

        /**
         * 스케줄 등록페이지 이동
         * 등록방식은 두가지. 스케줄등록 & 우측캘린더 날짜 클릭.
         * 스케줄등록으로 접근: 즉시비행
         * 우측캘린더 날짜클릭 접근: 예약비행
         * @param activeTab 0: 즉시비행예약, 1:예약비행
         * @param dateTime 선택한 날짜, default=현재날짜
         */
        moveToCreatePage(activeTab, dateTime = new Date()) {
            this.$router.push({
                name: "SchedulePlanCreate",
                params: {
                    propsTime: dateTime,
                    activeTab: activeTab
                }
            })
        },

        /**
         * 스케줄 수정페이지 이동
         * 선택한 scheduleId 값을 파라미터로 전달
         */
        moveToEditPage() {
            this.$router.push({
                name: "SchedulePlanUpdate",
                params: {
                    scheduleId: this.selectedSchedule.scheduleId
                },
            })
        },

    },
    computed: {
        isAllCheck :  {
            get() {
                return this.stations.length > 0 && this.stations.every(station => station.checked);
            },
            set(value) {
                this.stations.forEach(station => {
                    station.checked = value;
                });
            }
        },

        filteredSchedules() {
            const selectedStations = this.stations
                .filter(station => station.checked)
                .map(station => station.deviceName);

            return this.schedules.filter(schedule =>
                selectedStations.includes(schedule.stationDeviceName)
            );
        },

        filteredEvents() {

            return this.filteredSchedules.map((eventItem,index) => {
              index
                const newTime = moment(eventItem.localTime, "HH:mm:ss").add(1, 'hours').format("HH:mm:ss");
                return {

                    id: `${eventItem.localDate}_${eventItem.localTime}_${eventItem.scheduleItemId}`,
                    title: eventItem.scheduleName,
                    start: `${eventItem.localDate}T${eventItem.localTime}`,
                    end: `${eventItem.localDate}T${newTime}`,
                    calendarId: "cal1" ,
                    customStyle: {
                        fontSize: '1px',
                    },

                };
            });
        },

        filteredAttributes() {
            const generatedDates = this.leftCalendarSchedule.map((item) => new Date(item.localDate));
            return [
                {
                    key: 'today',
                    highlight: 'red',
                    dates: new Date(),
                },
                {
                    dot: {
                        color: 'blue'
                    },
                    dates: generatedDates,
                }
            ];
        },

        weekSettings() {
            return {
                startDayOfWeek: this.newStartDayOfWeek,
                hourStart: 0,
                hourEnd: 24,
                dayNames: [this.$t("days-monday"), 
                this.$t("days-tuesday"), 
                this.$t("days-wednesday"), 
                this.$t("days-thursday"), 
                this.$t("days-friday"), 
                this.$t("days-saturday"), 
                this.$t("days-sunday")],
                taskView: false,
                collapseDuplicateEvents: false
            };
        }
    },

};
</script>


<style>

/* 캘린더 드래그모양 막기위해 추가 */
.toastui-calendar-time .toastui-calendar-grid-selection {
    display: none;
}
.title {
    display: none;
}

.m-b-20 {
    margin-bottom: 20px !important;
}

.m-b-30 {
    margin-bottom: 30px !important;
}

.m-b-5 {
    margin-bottom: 5px;
}

.m-b-0 {
    margin-bottom: 0px;
}

.content-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.content-box {
    background: #fff;
    padding: 15px;
    border-radius: 5px;
}

.calender-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    line-height: 50px;
    margin-top: 50px;
}

.calender-title>select {
    border: 1px solid #ddd;
    border-radius: 5px;
}

.cal-lf {
    width: 300px;
    max-width: 300px;
}

.sp-add-btn {
    width: 300px;
    max-width: 300px;
    margin-bottom: 20px;
}

.sp-add-btn>button {
    border: 0;
    background: #0080ff;
    border-radius: 5px;
    color: #fff;
    font-size: 1.4rem;
    width: 100%;
    height: 40px;
}

.sp-cal {
    width: 100%;
    height: 300px;
    background: #fff;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 20px;
}

.sp-allCheck {
    width: 100%;
    background: #fff;
    padding: 15px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.sp-allCheck>h4 {
    display: flex;
    font-size: 1.4rem;
}

.sp-allCheck>h4>img {
    width: 18px;
    margin-right: 8px;
}

.sp-check-list {
    width: 100%;
    padding: 15px;
    background: #fff;
    border-radius: 5px;
}

.sp-check-list>h2 {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.sp-check-list>ul {
    max-height: 200px;
    height: 200px;
    overflow-y: auto;
}

.sp-check-list>ul>li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.sp-check-list>ul>li>h4 {
    font-size: 1.2rem;
    font-weight: 400;
}

.sp-check-list>ul>li>span {
    padding-right: 10px;
}

.cal-sheet {
    width: calc(100% - 320px);
    height: 800px;
    background: #fff;
    border-radius: 5px;
    padding: 15px;
}

/* 모달 스타일 */
.modal-title>.modal-top-btn {
    display: flex;
}

.modal-title>.modal-top-btn>button {
    border: 0;
}

.info-modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.info-modal-wrapper {
    width: 500px;
}

.info-modal-container {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
}

.info-modal-container>.modal-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.info-modal-container>.modal-title>h3 {
    font-size: 1.6rem;
    margin-bottom: 10px;
}

.info-modal-container>.modal-title>button {
    border: 0;
}

.info-modal-container>.modal-situation-mark {
    margin-top: 10px;
}

.info-modal-container>.modal-situation-mark>span {
    background: #dce9ff;
    color: #0080ff;
    padding: 5px 10px;
    border-radius: 3px;
    margin-right: 10px;
    font-size: 1.2rem;
    font-weight: 400;
}

.info-modal-container>.modal-situation-mark>span.flight-on {
    background: #ff3b3b;
    color: #fff;
}

.info-modal-container>.modal-situation-mark>span.flight-off {
    background: #c9c9c9;
    color: #fff;
}

.info-modal-content {
    margin: 30px 0;
}

.info-modal-content>ul {
    margin: 30px 0;
}

.info-modal-content>ul>li {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.info-modal-content>ul>li>h4 {
    font-size: 1.4rem;
    font-weight: 400;
    width: 120px;
    color: #969696;
}

.info-modal-content>ul>li>h4>img {
    width: 18px;
    margin-right: 10px;
}

.info-modal-content>ul>li>h3 {
    font-size: 1.4rem;
    font-weight: 400;
}

.info-modal-content>ul>li>.calendar-info>h3 {
    margin-bottom: 5px;
    font-size: 1.4rem;
    width: 330px;
    display: flex;
    flex-wrap: wrap;
}

.info-modal-content>ul>li>.calendar-info>h3>span {
    font-size: 1.4rem;
    font-weight: 400;
}

.info-modal-content>ul>li>.calendar-info>h4 {
    font-size: 1.2rem;
    font-weight: 400;
    color: #969696;
}

.info-modal-content>ul>li>.calendar-info>h4>span {
    font-size: 1.2rem;
    font-weight: 400;
    color: #969696;
}

.info-modal-content>h4 {
    font-size: 1.4rem;
}

.info-modal-content>h4>img {
    width: 18px;
    margin-right: 10px;
}

.info-modal-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.info-modal-btn>.addData-btn {
    border: 0;
    font-size: 1.4rem;
    margin-right: 10px;
}

.info-modal-btn>.close-btn {
    font-size: 1.4rem;
    background: #0080ff;
    color: #fff;
    border-radius: 5px;
    border: 0;
    width: 70px;
}

.time-info>h4 {
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 5px;
}

.time-info>h5>span {
    font-weight: 400;
    font-size: 1.2rem;
}
.vc-highlight{
    background-color: rgb(0, 128, 255) !important;
}
.toastui-calendar-event-time{
    background-color: rgb(255, 255, 255) !important;
    box-shadow: 2px 2px 3px #a5a5a5 !important;
    border-left: 3px solid rgb(0, 128, 255) !important;
    color: rgb(0, 0, 0) !important;
    border-top: 1px solid #ddd;
    padding-top: 8px;
}
.toastui-calendar-weekday-event-dot{
    background-color: rgb(0 128 255) !important;
}
.toastui-calendar-template-time > strong{font-size: 1.2rem;}
.toastui-calendar-event-time .toastui-calendar-event-time-content{margin-right: 5px;}

.sp-list-title{display: flex;justify-content: space-between;align-items: center;margin-bottom: 10px;}
.sp-list-title > span{font-size: 1.2rem;color: #969696;}
</style>
