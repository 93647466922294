/**
 * 라이브는 송출방식에 따른 url 경로가 달라짐
 * HLS, WebRTC 등등...
 * 따로 함수만 뻄.
 * 스테이션 드론의 경우, tc돌린걸로 리턴해줘야함. 안그러면 끊김현상 발생
 */

/**
 * HLS 스트림 url 리턴
 * @param viewChannelData
 * @returns {string}
 */
export function getHlsUrl(viewChannelData) {
    // stationDrone일 경우 tc돌린걸로 리턴,
    // if(viewChannelData.assignedDeviceType === 'StationDrone') {
    //     return getTcHlsUrl(viewChannelData)
    // }

    let application = ""
    if(viewChannelData.assignedDeviceType === 'Drone'|| viewChannelData.assignedDeviceType === 'StationDrone'){
        application = viewChannelData.streamServer.properties.droneApplication
    } else {
        application = viewChannelData.streamServer.properties.application
    }
    const url = `${viewChannelData.streamServer.properties.liveUrl}/${application}/${viewChannelData.streamKey}/${viewChannelData.streamServer.properties.livePostfix}`
    return url
}

/**
 * 스테이션 드론일 경우, tc를 적용한 hls url을 리턴
 * @param viewChannelData
 * @returns {string}
 */
// function getTcHlsUrl(viewChannelData) {
//     return `${viewChannelData.streamServer.properties.liveUrl}/${viewChannelData.streamServer.properties.droneApplication}/webrtc_${viewChannelData.streamKey}/${viewChannelData.streamServer.properties.livePostfix}`
// }

