<template>
  <div>
    <div class="new_title" style="padding-left: 30px;">
      <h1>{{ $t("site-control-menu-notice") }}</h1>
    </div>
    <div class="notice-box" v-if="isUserType=='Admin'">   
      <h2>{{ $t("site-view-notice-title") }}</h2>
      <div class="notice-from">
        <div class="notice-from-row">
          <label>{{ $t("site-notice-dep-comment") }}</label>
          <textarea
              @keyup="lengthLimit"
              maxlength="201"
              v-model="notice"
              :placeholder="[$t('notice-register-textarea-placeholder-text')]"
          ></textarea>
        </div>
        <div class="notice-from-row">
          <label>{{ $t("site-notice-dep-file") }}</label>
          <div class="notice-imgFile">
            <div class="imgBox">
              <img :src="img" alt="" />
              <input type="file" id="imgUp" ref="images" @change="sendFile" />
              <label for="imgUp" v-show="!img">
                <button class="tableLine">
                  <span class="btn-custom">+</span>
                  <span class="btn-custom-text">{{ $t("site-view-notice-img") }}</span>
                </button>
              </label>
              <div class="cancel" v-if="img">
                <button @click="deleteImage">{{ $t("site-notice-dep-file-del") }}</button>
              </div>
            </div>
            <h4>{{ $t("site-notice-dep-caution") }}</h4>
          </div>
        </div>
        <div class="notice-btn">
          <button @click="submit">{{$t("btn-notice-send")}}</button>
          <button @click="cancle">{{$t("btn-cancel")}}</button>
        </div>
      </div>
    </div> 
  </div> 
</template>

<style>
.notice-box{margin: 0 30px;background: #fff;border-radius: 5px;padding: 15px;}
.notice-box > h2{font-size: 1.6rem;font-weight: bold;margin-bottom: 20px;}
.notice-from{border-top: 1px solid #ddd;padding: 20px 0;}
.notice-from-row{display: flex;align-items: flex-start;margin-bottom: 20px;justify-content: space-between;}
.notice-from-row > label{font-size: 1.4rem;width: 100px;text-align: center;padding: 3px;background: #eee;border-radius: 10px;color: #969696;}
.notice-from-row > textarea{width: calc(100% - 150px);height: 280px;border: 1px solid #ddd;padding: 10px;border-radius: 5px;font-size: 1.4rem;}

.notice-from-row > .notice-imgFile{width: calc(100% - 150px);}
.notice-from-row > .notice-imgFile > .imgBox{min-height: 230px;height: 230px;overflow-y: scroll; position: relative;border: 1px solid #ddd;border-radius: 5px; padding: 10px;text-align: center;}
.notice-from-row > .notice-imgFile > .imgBox > img{width: auto;height: auto;}
.notice-from-row > .notice-imgFile > .imgBox > input{display: block;width: 100%;height: 100%;opacity: 0;overflow: hidden;position: absolute;top: 0;left: 0;z-index: 1;cursor: pointer;}
.notice-from-row > .notice-imgFile > .imgBox > label{position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);}
.notice-from-row > .notice-imgFile > .imgBox > label > button{background: #fff;white-space: nowrap;border: 0;display: flex;flex-direction: column;align-items: center;height: 100%;}
.notice-from-row > .notice-imgFile > .imgBox:hover > label > button > .btn-custom{background: #edf2ff;}
.notice-from-row > .notice-imgFile > .imgBox > label > button > .btn-custom{color: #0080FF;font-size: 6rem;border: 2px dotted #c9c9c9;width: 100px;height: 100px;display: block;font-weight: 300;padding-top: 10px;}
.notice-from-row > .notice-imgFile > .imgBox > label > button > .btn-custom-text{font-weight: 300;margin-top: 10px;color: #969696;}
.notice-from-row > .notice-imgFile > h4{font-size: 1.2rem;color: #ff7474;font-weight: 300;margin-top: 10px;}

.notice-btn{text-align: center;border-top: 1px solid #ddd;padding-top: 20px;}
.notice-btn > button{border: 1px solid #ddd;border-radius: 5px;color: #969696;padding: 0 20px;margin: 0 5px;height: 40px;font-weight: 400;width: 150px;}
.notice-btn > button:nth-child(1){border: 1px solid #0080FF;color: #0080FF;}
.notice-btn > button:nth-child(1):hover{border: 1px solid #0080FF;color: #fff;background: #0080FF;}

.cancel{margin-top: 10px;}
.cancel > button{border: 1px solid #ddd;border-radius: 5px;color: #969696;padding: 0 20px;font-weight: 300;}
.cancel > button:hover{border: 1px solid #ff5656;color: #ff5656;}
.noticeActive{background: #0080FF;}
</style>

<script>
// import SendList from "@/components/pop/SendList";
// import SendResult from "@/components/pop/SendResult";
import moment from "moment";
import { baseUrl, baseApiVersion } from "@/config/env";
import {createNotice, deleteNotice } from "@/api/event";
import { ko } from "vuejs-datepicker/dist/locale";
deleteNotice

export default {
  // components: { SendList, SendResult },
  name: "Home",
  data() {
    return {
      baseUrl: baseUrl,
      baseApiVersion : baseApiVersion,
      moment: moment,
      total: 0,
      size: 10,
      currentPage: 0,
      notice: "",
      id: "",
      noticeList: [],
      file: "",
      img: "",

      ko: ko,

      //검색 조건
      startYmd: null,
      endYmd: null,
      keywordType: "all",
      statuses: null,
      keyword: null,

      isUserType:null,
      isNoticeDetail:false,
      noticeNum:null,
    };
  },
  created() {
    this.id = this.$route.params.eventId;
    this.isUserType = localStorage.getItem("userType");
    this.$store.dispatch("store/SET_NAVBAR", { nav: 9, sub: 8 });
  },
  mounted() {
  },
  methods: {
    //api 변경 됨 2021.11.04
    sendFile() {
      this.file = this.$refs.images.files[0];

      let reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = (e) => {
        this.img = e.target.result;
      };
    },
    deleteImage() {
      this.img = "";
      this.file = "";
      this.$refs.images.value = "";
    },
    handleCurrentChange(val) {
      this.currentPage = val - 1;
    },
    lengthLimit() {
      if (this.notice.split("").length >= 201) {
        this.notice = this.notice.slice(0, -1);
        alert(this.$t("alert-message-characters-over"));
      }
    },
    handleTab(id) {
      this.visible = id;
    },
    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },
    reset() {
      this.endYmd = null;
      this.startYmd = null;
      this.keyword = null;
      this.keywordType = "all";
    },

    logSearch(){
      this.currentPage = 0;
    },
    submit() {
      if (!this.notice) {
        return alert(this.$t("alert-message-enter"));
      }
      // let formdata = {
      //   noticeContent: this.notice,
      //   noticeImg: this.file,
      // };
      let formdata = new FormData();
      formdata.append("noticeContent", this.notice);
      formdata.append("uploadImage", this.file);

      createNotice(this.id, formdata).then((res) => {
        if (res.data.result == 0) {
          this.notice = "";
          this.img = "";
          this.file = "";
          this.$refs.images.value = "";
          alert("공지 등록 성공했습니다.");
          this.$router.push('Notice')
        }else {
          alert("공지 등록 실패하였습니다.")
        }

      });
    },
    cancle(){
        this.$router.push('Notice')
    },
    async handleDelete(index){
      let result = confirm(this.$t("notice-delete-confirm-text"));
      if (result == false) {
        return;
      }index

    
    },
  },
};
</script>
