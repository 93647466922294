<template>
  <div class="home">
    <!-- 서비스 종료 popup -->
    <div ref="popupDiv" class="service-model-main">
        <div class="custom-model-inner">
            <div class="model-header">
                <div class="close-btn" @click="popClose">×</div>
            </div>
            <div class="custom-model-wrap">
                <h3>서비스 중지 안내</h3>
                <p>현재 사용중이신 드론워크 서비스는<br><span> 2023년 12월 11일(월) 오전 10시</span> 부로<br>서비스를 중지합니다.</p>
            </div>
        </div>
        <div class="bg-overlay"></div>
    </div>
    <div class="login_bg">
      <img src="@/assets/images/login_bg.jpg">
    </div>
    <div class="login">
      <div class="flexL" style="flex-direction: column; margin: auto;">
        <div class="flex" style="padding-bottom: 5rem;">


          <div class="loginBox" :style="[accountId == 'forest'? {'padding' : '30px 30px 30px'}:{}]">
            <template v-if="accountId == 'forest'">
              <div class="custom-model-inner" >
                <div class="custom-model-wrap">
                  <h3>서비스 중지 안내</h3>
                  <p>현재 사용중이신 드론워크 서비스는<br><span> 2023년 12월 11일(월) 오전 10시</span> 부로<br>서비스를 중지합니다.</p>
                </div>
              </div>
              <img
                  class="forestLogo"
                  :style="[accountId == 'forest'? {'display' : 'block'}:{'display' : 'none'}]"
                  src="@/assets/images/logo_forest_login.png"
                  alt="로고"
                  style="width: 125px; height: auto; position: absolute; bottom: 0px; right: 50%; left: 50%; transform: translate(-50%, -50%); margin: 0;"
              />
            </template>

            <!-- login from -->
            <template v-if="accountId != 'forest'">
              <div style="width: 300px; margin:auto;">
                 <img
                    :style="[accountId != 'forest'? {'display' : 'block'}:{'display' : 'none'}]"
                    :src="require(`/src/assets${getImageSrc(accountId , 'login')}` )"
                    alt="로고"
                    @click="loginClick = false"
                    style="width: 100%;"
                />
              </div>
              <div class="login-title">
                <h1>{{getContent(accountId , 'login-title')}}</h1>
<!--                <h1  v-if="getContentString(accountId , 'title') !== null" >-->
<!--                  {{getContentString(accountId , 'title')}}-->
<!--                </h1>-->
<!--                <h1 v-else>{{ $t("login-system-title-text") }}</h1>-->
              </div>

              <div class="form login-form">
                <input type="text" v-model="loginId" @keyup.enter="submit" :placeholder="$t('login-data-id')" style="width: 450px;"/>
                <div style="position: relative" class="pw-from">
                  <input type="password" v-model="password" @keyup.enter="submit" id="password" :placeholder="$t('login-data-pw')" style="width: 450px;"/>
                  <span class="showBtn" v-show="isPwShow" @click="switchVisibility()">show</span>
                </div>
                <label v-if="false">
                  <input
                      type="checkBox"
                      :checked="autoLogin"
                      @change="handleChecked"
                  />
                  {{ $t("login-checkbox-id-password-save-text") }}
                </label>
                <div class="save-checkbox" style="width: 450px; margin: 0px auto 15px auto;">
                  <input type="checkbox" v-model="isIdSave">
                  <label>{{$t("btn-id-save")}}</label>
                </div>
                <button class="point large login_btn" @click="submit" style="width: 450px;">{{ $t("btn-login") }}</button>

                <div v-if="error" class="error">
                  {{ errorText }}
                </div>
              </div>

              <aside class="login-find">
                <div>
                  <router-link :to="`/${accountId}/findEmail`">{{
                      $t("find-id-title")
                    }}</router-link>
                  <span></span>
                  <router-link :to="`/${accountId}/findPassword`">{{
                      $t("find-pw-title")
                    }}</router-link>
                  <!--              <span></span>-->
                  <!--              <router-link :to="`/${accountId}/join`">{{
                                    $t("btn-sign-in")
                                  }}</router-link>-->
                </div>
                <div class="sign_in_btn">
                  <p class="sign_in_comment">{{$t("login-warning-comment")}}</p>
                  <router-link :to="`/${accountId}/joinTermsAgree`">
                    {{$t("btn-sign-in")}}
                  </router-link>
                </div>
              </aside>
              <div class="login-logo-bottom">
                <img :src="require(`/src/assets${getImageSrc(accountId , 'loginBottom')}` )"
                     alt="로고"/>
              </div>
              <img
                  class="forestLogo"
                  :style="[accountId == 'forest'? {'display' : 'block'}:{'display' : 'none'}]"
                  src="@/assets/images/logo_forest_login.png"
                  alt="로고"
                  style="width: 125px; height: auto; position: absolute; bottom: 0px; right: 50%; left: 50%; transform: translate(-50%, -50%); margin: 0;"
              />
            </template>

          </div>


        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>
<style>
  .showBtn{
    position: absolute;
    right: 20px;
    top: 16px;
    color: #b3b3b3;
    cursor: pointer;
  }
  .login-title{
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 25px !important;
  }
  .service-model-main {
    text-align: center;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; /* z-index: 1050; */
    outline: 0;
    opacity: 0;
    transition: opacity 0.15s linear, z-index 0.15;
    z-index: -1;
    overflow-x: hidden;
    overflow-y: auto;
    }
    .login-logo-bottom{
      height: 20px;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 25px;
    }
    .login-logo-bottom > img{
      height: 100% !important;
      width: auto !important;
      display: block !important;
      margin: auto !important;
    }
    .model-open {
    z-index: 99999;
    opacity: 1;
    overflow: hidden;
    }
    .service-model-main > .custom-model-inner {
    transform: translate(0, -25%);
    transition: transform 0.3s ease-out;
    display: inline-block;
    vertical-align: middle;
    width: 800px;
    margin: 30px auto;
    max-width: 97%;
    }
    .model-header{display: flex;padding-top: 10px;padding-bottom: 0;margin: 0 15px;}
    .model-header > h3{font-size: 20px;font-weight: bold;margin-bottom: 15px;}
    .pop-up-content-wrap > p{color: #969696;}
    .custom-model-wrap {
    display: block;
    width: 100%;
    position: relative;
    border-radius: 6px;
    background-clip: padding-box;
    outline: 0;
    text-align: left;
    padding: 20px;
    box-sizing: border-box;
    max-height: calc(100vh - 70px);
    overflow-y: auto;
    }
    .model-open .custom-model-inner {
    transform: translate(0, 0);
    position: relative;
    z-index: 999;
    background: #ffffff;
    border-radius: 10px;
    }
    .model-open .bg-overlay {
    background: rgba(0, 0, 0, 0.6);
    z-index: 99;
    }
    .bg-overlay {
    background: rgba(0, 0, 0, 0);
    height: 100vh;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    transition: background 0.15s linear;
    }
    .close-btn {
    position: absolute;
    right: 15px;
    top: -40px;
    cursor: pointer;
    z-index: 99;
    font-size: 30px;
    color: #fff;
    }
    .custom-model-wrap > h3{text-align: center;
    background: #dbecf5;
    padding: 15px 0;
    font-size: 28px;
    font-weight: bold;
    color: #0b337e;}
    .custom-model-wrap > p{text-align: center;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 50px;}
    .custom-model-wrap > p > span{color: #ff3d3d; font-size: 22px;font-weight: bold;}
    @media screen and (min-width:800px){
        .service-model-main:before {
        content: "";
        display: inline-block;
        height: auto;
        vertical-align: middle;
        margin-right: -0px;
        height: 100%;
        }
    }
    .login-form > input{height: 50px;margin-bottom: 20px;background: #ffffff1a;border: 0;color: #101010;font-size: 1.4rem;border: 1px solid #ddd;}
    .login-form > input:focus-visible{border: 1px solid #6992F9;background: #ffffff27;outline: 0px;}
    .pw-from > input{height: 50px;margin-bottom: 20px;background: #ffffff1a;border: 1px solid #ddd;color: #101010;font-size: 1.4rem;}
    .pw-from > input:focus-visible{border: 1px solid #6992F9;background: #ffffff27;outline: 0px;}
    .login_btn{background: #0080FF !important;border: 1px solid #0080FF !important;color: #fff;width: 100%;height: 55px !important;}
    .login_btn:hover{background: #1c45c2 !important;border: 1px solid #1c45c2 !important;color: #fff;width: 100%;height: 55px !important;}
    .save-checkbox{display: flex;margin-bottom: 15px;align-items: center;}
    .save-checkbox > input{border: 0;width: 16px !important;}
    .save-checkbox > label{font-size: 1.4rem; color:#969696;}
    .login-find{text-align: center;}
    .sign_in_btn{display: flex;justify-content: center;margin-top: 15px !important;}
    .sign_in_btn .sign_in_comment{color: #969696;font-size: 1.4rem;margin-right: 10px;}
    .sign_in_btn > a{color: #638aff !important;}

</style>
<script>
import { format } from "@/mixins/format";
import Footer from "@/components/Footer";
import { login } from "@/api/login";
import { postNice } from "@/api/nice";
import lang from "@/mixins/lang";
import VueCookies from "vue-cookies";
import {mapState} from "vuex";
import Vue from "vue";
import VueCryptojs from 'vue-cryptojs'

import {getImageSrc ,getContent} from "@/util/common";

Vue.use(VueCryptojs)

export default {
  components: { Footer },
  mixins: [format, lang],
  data() {
    return {
      loginId: "",
      accountId: "",
      password: "",
      error: false,
      errorText: "",
      loginClick: false,
      workerType: "",
      device: "",
      autoLogin: false,
      isIdSave : false,
      isPwShow : false,
      userInfo : "",
      isCheckNotice : false,
      imageUrl : '/images/login_logo_b.png'
    };
  },
  computed: {
    ...mapState({
      secretKey : state => state.store.secretKey,
      iv: state => state.store.iv,
    }),
    currentLang: {
      get() {
        return this.$store.state.store.currentLang;
      },
      set(value) {
        this.$store.commit("store/langChange", value);
      },
    },
  },
  watch : {
    password() {
      if(this.password.length != 0){
        this.isPwShow = true;
      } else {
        this.isPwShow = false;
      }
    },
  },
  mounted() {
    this.getCookiesEmail();
    this.getCookiesId();
    if(this.$route.query.currentLang != null) {
      this.currentLang = this.$route.query.currentLang;
    }
    this.workerType = this.$route.query.workerType;
    this.device = this.$route.query.device;
    this.accountId = this.$route.params.accountId;

    if(this.accountId == 'forest') {
      this.$refs.popupDiv.classList.add('model-open');
    }
  },
  methods: {
    getImageSrc,
    getContent,

    popClose(){
      this.$refs.popupDiv.classList.remove('model-open');
    },
    switchVisibility(){
      const passwordField = document.querySelector('#password')

      if (passwordField.getAttribute('type') === 'password') passwordField.setAttribute('type', 'text')
      else passwordField.setAttribute('type', 'password')
    },
    getCookiesEmail() {
      if (VueCookies.isKey("autoLogin")) {
        let info = VueCookies.get("autoLogin");
        this.loginId = info.id;
        this.password = info.pw;
        this.autoLogin = true;
      }
    },
    getCookiesId(){
      if(VueCookies.isKey("idSave")){
        let info = VueCookies.get("idSave");
        this.loginId = info.id;
        this.isIdSave = true
      }
    },
    handleChecked(e) {
      this.autoLogin = e.target.checked;
    },
    submit() {
      if (this.autoLogin) {
        VueCookies.set("autoLogin", { id: this.loginId, pw: this.password }, -1);
      } else {
        VueCookies.remove("autoLogin");
      }
      if(this.isIdSave){ //아이디 저장
         VueCookies.set("idSave", { id: this.loginId }, 0);
      } else {
        VueCookies.remove("idSave");
      }

      if (this.loginId == "") {
        this.error = true;
        this.errorText = this.$t("alert-message-check-id");
        return;
      } else if (this.password == "") {
        this.error = true;
        this.errorText = this.$t("alert-message-check-pw");
        return;
      }
      let decryptPassword = this.$CryptoJS.AES.encrypt(this.password, this.$CryptoJS.enc.Utf8.parse(this.secretKey), {
        iv: this.$CryptoJS.enc.Utf8.parse(this.iv),
        padding: this.$CryptoJS.pad.Pkcs7,
        mode: this.$CryptoJS.mode.CBC,
      });
      let data = {
        accountId: this.accountId,
        loginId: this.loginId,
        password: decryptPassword.toString(),
        workerType: this.workerType,
        device: this.device,
      };
      if (
          this.workerType != "Controller" &&
          this.workerType != "Worker" &&
          this.workerType != "Manager" &&
          this.workerType != "Guest"
      ) {
        delete data.workerType;
      }
      login(data).then((res) => {
        if (res.data.result == 0) {
          this.userInfo = res.data.data
          this.checkNotice(this.isCheckNotice);//true면 정보인증을 사용한다.
        } else if (res.data.result == -6 || res.data.result == -7) {
          return alert(this.$t("login-error-fail-login"));
        }else {
          this.error = true;
          this.errorText = this.$t("login-error-have-not-signed");
        }
      }).catch((error)=> {
        if (error.response) {
          // 요청이 전송되었고, 서버는 2xx 외의 상태 코드로 응답했습니다.
          var data = error.response.data //errorResponseBody
          var result =  data.result
          console.log(result)
          if(result == -3013){ //휴면
           localStorage.setItem("token", error.response.data.data);
          document.location.href = `/${this.accountId}/release`;
          }else if(result == -3012){ //이용정지
            this.$router.push(`/${this.accountId}/suspended`);
          }
          else if(result == -3003){  //비밀번호 또는 아이디 틀림
            return alert(this.$t("login-error-fail-login"));
          }else if(result == -3015){  //회원대기
            return alert(this.$t("sign-in-info-done-wait"));
          }
          else{
            alert(this.$t("login-error-have-not-signed"));
          }
        }else {
          console.log(3)
          // 오류가 발생한 요청을 설정하는 동안 문제가 발생했습니다.
          alert(this.$t("login-error-have-not-signed"));
          console.log('Error', error.message);
        }
      });
    },
    checkNotice(isUse){ //isUse가 true면 인증절차 false면 안함.
      if(isUse){
        var url = `${window.location.protocol}//${window.location.host}/${this.accountId}`
        postNice({url : url}).then((res)=> {
          if(res.data.result == 0 ){
            localStorage.setItem("encData", res.data.data);
            this.nicePopUp(res.data.data);
           // this.$router.push({name : "niceCheck"})
          }else{
            alert(`${res.data.message}`)
          }
        });
      }else{
        this.setUserInfo(this.userInfo)
      }
    },
    nicePopUp(encData){
      window.name = "popupParent"
      window.result = () => {
        this.checkUserInfo()
      }
      window.open('', 'popupChk', 'width=500,height=550,top=100,left=100,fullscreen=no,menubar=no,status=no,toolbar=no,titlebar=yes,location=no,scrollbar=no');

      var form = document.createElement('form');
      form.method = 'POST';
      form.action = 'https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb';
      form.target = 'popupChk';

      const input1 = document.createElement('input');
      input1.type = 'hidden';
      input1.name = 'm';
      input1.value = 'checkplusService';
      form.appendChild(input1);

      const input2 = document.createElement('input');
      input2.type = 'hidden';
      input2.name = 'EncodeData';
      input2.value = encData;
      form.appendChild(input2);

      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
    },
    setUserInfo(){
      var data = this.userInfo
      localStorage.setItem('timeout', new Date().getTime());
          if (this.device == "ControllerApp" || this.device == "ViewApp") {
            let params = JSON.stringify(data);
            window.Android.loginSuccess(params);
          } else {
            this.$store.dispatch("store/LOGIN", data);
            if(data.userType == "User"){
              //document.location.href = `/${this.accountId}/eventControl/liveListControlUser`;
              document.location.href = `/${this.accountId}/eventControl/liveListControlUser`;
            }
            // else if(res.data.data.userType == "Manager"){
            //   document.location.href = `/${this.accountId}/dashboard/DroneStatusMap`;
            // }
            else {
              //document.location.href = `/${this.accountId}/dashboard/overallAdmin`;
              document.location.href = `/${this.accountId}/eventControl/liveListControlUser`;
            }
      }
    },
    checkUserInfo(){
      var niceRes = JSON.parse(localStorage.getItem("niceInfo"))
      if(niceRes == null && niceRes == undefined) return

      if(niceRes.sname == this.userInfo.name && niceRes.smobileNo == this.userInfo.mobileNumber){
        this.setUserInfo()
      }else {
        alert("회원정보와 인증정보가 일치하지 않습니다. \n 다시 시도해주세요.")
      }
      localStorage.removeItem("niceInfo") //삭제하기.
      },
  },
};

</script>
