import i18n from "@/lang";

const windDirection = [
  i18n.t("north-wind"),
  i18n.t("north-northeast-wind"),
  i18n.t("northeast-wind"),
  i18n.t("east-northeast-wind"),
  i18n.t("east-wind"),
  i18n.t("east-southeast-wind"),
  i18n.t("southeast-wind"),
  i18n.t("south-southeast-wind"),
  i18n.t("south-wind"),
  i18n.t("south-southwest-wind"),
  i18n.t("southwest-wind"),
  i18n.t("west-southwest-wind"),
  i18n.t("west-wind"),
  i18n.t("west-northwest-wind"),
  i18n.t("northwest-wind"),
  i18n.t("north-northwest-wind"),
];

export default windDirection;
