<template>
  <div class="lnb" ref="lnbNav"> 

    <el-row class="mainMenu"> 
      <div class="data-content-menu">
        <el-col :span="12">
          <el-menu
              :router="false"
              :default-active="selectIndex"
              :unique-opened="true"
              class="el-menu-vertical-demo"
          >

            <!-- 드론경로 계획 -->
            <el-submenu index="1">
              <template slot="title">
                <img src="@/assets/images/LNB_mapping.png" alt="icon" />
                <span>{{$t("site-control-menu-plan")}}</span>
              </template>

              <el-menu-item route="/" index="Mission" v-if="accountId!='komipo'">
                <router-link :to="{ name: 'Mission'}">  {{$t("site-control-mission-sub-list")}} </router-link>
              </el-menu-item>
              <!-- 맵핑 작업 완료 시 해제 -->
              <el-menu-item  index="Mapping">
                <router-link :to="{ name: 'Mapping' }"> {{ $t("site-control-mapping-sub-list") }} </router-link>
              </el-menu-item>
            </el-submenu>

            <!-- 스테이션 계획 -->
            <el-submenu index="2" v-if="isStationUse">
              <template slot="title">
                <img src="@/assets/images/LNB_schedule.png" alt="icon" />
                <span>{{$t("site-control-menu-station-plan")}}</span>
              </template>

              <el-menu-item route="/" index="SchedulePlan" v-if="accountId!='komipo'">
                <router-link :to="{ name: 'SchedulePlan'}">
                  {{$t("site-control-menu-schedule")}}
                </router-link>
              </el-menu-item>

              <el-menu-item index="ScheduleHistory" v-if="accountId!='komipo'">
                <router-link
                :to="{
                  name: 'ScheduleHistory',
                  query: { page: 1 }
                  }">
                  {{$t("site-control-menu-schedule-history")}}
                </router-link>
              </el-menu-item>

              
            </el-submenu>
          </el-menu>
        </el-col>
      </div>

      <div class="btBox">
        <a  v-if="accountId == 'forest'"  href="https://www.forest.go.kr/" target="_blank">
          <img
              src="../assets/images/LNB_logo_korea_forest.png"
              alt="logo"
              class="bt_logo"
              style="border-radius: 4px;"
          />
        </a>
        <a  class="teixonLogo" v-if="accountId == 'forest'"  href="https://www.teixon.com/" target="_blank" style="text-align: right; padding: 0 10px; margin-bottom: 10px;">
          <span style="font-size: 12px;">Software by</span>
          <img
              src="../assets/images/teixon_new_logo_wh.svg"
              alt="logo"
              style="width: 80px;margin: 0 6px; padding-bottom: 4px;"
          />
        </a>
        
      </div>
    </el-row>
  </div>
</template>
<script>
import { mapState } from "vuex";
import lang from "@/mixins/lang";
import {stationLicenceCheck} from "@/util/Licence";

export default {
  mixins: [lang],
  data() {
    return {
      isCollapse: false,
      eventId: null,
      lastEventId: null,
      selectIndex : null,
      elapsedDay : 0,
    };
  },
  computed: {
    ...mapState({
      navbarState: state => state.store.navbarState,
      subMenuState: state => state.store.subMenuState,
      isAdmin: state => state.store.isAdmin,
      userType: state => state.store.userType,
      accountId: state => state.store.accountId,
      name : state => state.store.name,
      loginId : state => state.store.loginId,
      departmentName : state => state.store.departmentName,
      isLogin : state => state.store.isLogin,
      ownerWork : state => state.store.ownerWork,
      options : state => state.store.options,
      currentLang : state => state.store.currentLang,
    }),
    isStationUse(){
      return stationLicenceCheck(this.accountId)
    },
    currentLang: {
      get() {
        return this.$store.state.store.currentLang;
      },
      set(value) {
        this.$store.commit("store/langChange", value);
        //TODO 언어 변경시 이동 제거
        //this.$router.go();
      },
    },
  },
  created() {
    this.eventId = this.$route.params.eventId;
    this.selectIndex = this.$route.name;
  },
  watch:{
    selectIndex(value){
      switch (value) {
        case "MissionUpdate" :
          this.selectIndex = "Mission"
          break;
        case "MissionCreate" :
          this.selectIndex = "Mission"
          break;
        case "MappingCreate" :
          this.selectIndex = "Mapping"
          break;
        case "MappingUpdate" :
          this.selectIndex = "Mapping"
          break;
        case "SchedulePlanCreate" :
          this.selectIndex = "SchedulePlan"
          break;
        case "SchedulePlanUpdate" :
          this.selectIndex = "SchedulePlan"
          break;
        case "ScheduleHistoryDetail" :
          this.selectIndex = "ScheduleHistory"
          break;
      }
    },
    $route (to){
      this.selectIndex = to.name;
    }
  },
  mounted(){
    this.selectIndex = this.$route.name;
  },
  methods: {
    stationLicenceCheck,
  },
};
</script>
<style lang="scss">
.sub-menu-pointer{
  width: 0px;
  height: 0;
  position: absolute;
  right: 0;
  top: 455px;
  border-bottom: 5px solid rgb(255, 255, 255);
  border-top: 5px solid transparent;
  border-left: 5px solid rgb(255, 255, 255);
  border-right: 5px solid transparent;
  transform: rotate(270deg);
}
.sub-menu-pointer2{
  width: 0px;
  height: 0;
  position: absolute;
  right: 0;
  top: 410px;
  border-bottom: 5px solid rgb(255, 255, 255);
  border-top: 5px solid transparent;
  border-left: 5px solid rgb(255, 255, 255);
  border-right: 5px solid transparent;
  transform: rotate(270deg);
}
.userLnb{
  a{
    width: 100%;
    display: inline-block;
    height: 38px;
    line-height: 38px;
    >img{
      margin: 0 14px 0 24px;
      width: 2rem;
      height: 2rem;
    }
    .userLnbNm{
      color: #b9b9b9;
      font-size: 1.2rem;
    }
  }
  .router-link-exact-active,
  .router-link-active{
    .userEdIcon{
      filter: invert(42%) sepia(94%) saturate(2814%) hue-rotate(189deg) brightness(102%) contrast(101%);
    }
    .userLnbNm{
      color: #0790ff;
    }
  }
}
.mission-sub,
.mapping-sub {
  display: none;
}
.new-menu.active{background: #0080FF;}
.new-menu.active .mission-sub,
.new-menu.active .mapping-sub {
  display: block;
}

/*.userProfile > img{width: 30px;height: 30px;}
.drop-menu-btn{position: relative;display: flex;align-items: center;}
.drop-menu{position: absolute;left: 50px ;background: #fff;border-radius: 5px;box-shadow: 1px 1px 5px #939393;top: -85px;width: 230px;}
.drop-menu > .drop-menu-profile{display: flex;justify-content: space-between;align-items: center;border-bottom: 1px solid #ddd;padding: 15px;}
.drop-menu > .drop-menu-profile > img{width: 50px;}
.drop-menu > .drop-menu-profile > .profile-info{text-align: right;}
.drop-menu > .drop-menu-profile > .profile-info > p{font-size: 14px;color: #454545;margin-bottom: 5px;}
.drop-menu > .drop-menu-profile > .profile-info > p > span{background: #0080FF;color: #fff;border-radius: 10px;padding: 2px 10px;font-size: 12px;}
.drop-menu > .drop-menu-profile > .profile-info > .gnb_email{font-size: 12px;color: #969696;margin: 0;}
.drop-menu > ul > li{border-bottom: 1px solid #ddd;text-align: left;}
.drop-menu > ul > li:hover{background: #eee;}
.drop-menu > ul > li:nth-child(2){border: 0;}
.drop-menu > ul > li > a{font-size: 14px !important;color: #858585;padding: 15px 20px;width: 100%;}
.drop-menu > ul > li > a > img{margin-right: 10px;vertical-align: sub;}
.drop-menu > ul > li > button{border: 0;padding: 15px 20px;font-size: 14px !important;font-weight: 300;color: #858585;height: auto;text-align: left;width: 100%;}
.drop-menu > ul > li > button > img{margin-right: 10px;}*/
.el-menu-item{position: relative;}
.el-menu-item-title{position: absolute;background: #1b1d2cd9;padding: 2px 20px;top: 10px;left: 60px;display: none;height: 40px;border-radius: 5px;line-height: 35px;width: 140px;text-align: center;z-index: 100;}
.el-menu-item-title::before{content: '';position: absolute;top: 10px;left: -15px;  width: 0;height: 0;border-bottom: 10px solid transparent;border-top: 10px solid transparent;border-left: 15px solid #1b1d2cd9;border-right: 0px solid transparent;transform: rotate(180deg);}
.el-menu-item-title > span{font-size: 1.4rem;color: #fff;}
.el-menu-item:hover > .el-menu-item-title{display: block;}

.new-menu > a{position: relative;text-align: center;}
.new-menu > a:hover  > .el-menu-item-title{display: block;}
.step-line{width: 70%;height: 1px;background: #858585;margin: 5px auto;}
.notice-link{position: absolute;bottom: 50px;width: 100%;height: 60px;display: flex;align-items: center;justify-content: center;border-top: 1px solid #475775;}
.notice-link:hover{background: #0080FF;}
.notice-link:hover > .el-menu-item-title{display: block;}

.el-submenu.is-opened > .el-submenu__title{background: #0080FF;}
.el-submenu > .el-menu{border-bottom: 1px solid #707070 !important;}

</style>
