<template>
    <div class="modal-wrapper">
        <div class="modal-container">
            <div class="modal-title">
                <h3>{{missionType}} {{ $t("user-data-dept-select") }}</h3>
                <button @click="closeWaySelectModal"><img src="@/assets/images/icon_svg/close-icon.png" alt="icon"
                        style="width: 12px;" /></button>
            </div>
            <div class="modal-input">
                <div class="modal-search">
                    <span>{{ $t("search-keyword-title") }}</span>
                    <select v-model="keywordType">
                        <option value="all">{{ $t("search-keyword-all") }}</option>
                        <option value="title">{{ $t("mission-data-way-title") }}</option>
                        <option value="departmentName">{{ $t("mission-data-way-dept") }}</option>
                        <option value="userName">{{ $t("mission-data-way-registrant") }}</option>
                    </select>
                    <input type="text" v-model="keyword" @keydown.enter="getMissionList" style="width: 375px;" />
                </div>
                <div class="buttonWrap">
                    <button @click="getMissionList">
                        {{ $t("btn-searching") }}
                    </button>
                    <button @click="searchReset">
                        {{ $t("btn-reset") }}
                    </button>
                </div>
            </div>
            <div class="modal-content">
                <div class="map">
                    <Map ref="map" style="width: 100%; height: 100%; border-radius:5px;" :loadMapName="loadMap"
                        :isLeftButtonPosition="false" />
                </div>
                <div class="modal-list">
                    <div class="modal-list-title">
                        <h4>{{ $t("schedule-modal-station-list") }}</h4>
                        <h5>{{ $t("data-total") }}<span>{{ missionList.length }}</span>{{ $t("overall-data-event-ea") }}</h5>
                    </div>
                    <ul>
                        <li @click="selectMission(waypoint, index)" :class="{ active: activeIndex === index }"
                            v-for="(waypoint, index) in missionList" :key="index">
                            <h4>{{ waypoint.title }}</h4>
                            <p>
                                <span>{{ waypoint.userDepartmentName }}</span>│
                                <span>{{ waypoint.userName }}</span>│
                                <span>{{ customFormatter(waypoint.registerDate) }}</span>
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="modal-btn">
                <button @click="saveMission" class="btn-save">{{ $t("btn-confirm") }}</button>
                <button @click="closeWaySelectModal">{{ $t("btn-confirm-close") }}</button>
            </div>
        </div>
    </div>
</template>

<style scoped>
.modal-wrapper {
    width: auto;
}

.modal-container {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
}

.modal-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
    padding-bottom: 10px;
}

.modal-title>h3 {
    font-size: 1.6rem;
}

.modal-title>button {
    border: 0;
}

.modal-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    margin-bottom: 15px;
}

.modal-write {
    display: flex;
}

.modal-write>.info-box {
    margin-right: 20px;
}

.modal-write>.info-box>span:nth-child(1) {
    color: #969696;
    background: #eee;
    padding: 8px 15px;
    border-radius: 5px;
    margin-right: 10px;
    font-size: 1.2rem;
}

.modal-write>.info-box>span:nth-child(2) {
    font-size: 1.4rem;
}

.modal-info>h3 {
    font-size: 1.4rem;
    font-weight: 300;
}

.modal-info>h3>span {
    color: #0080FF;
    margin-left: 5px;
}

.modal-timeLine {
    position: relative;
    height: 450px;
    max-height: 450px;
    overflow-y: auto;
    border-top: 1px solid #ddd;
    padding-top: 15px;
}

.modal-timeLine::before {
    content: '';
    position: absolute;
    width: 2px;
    height: 100%;
    background: #62b1ff;
    left: 50px;
}

.modal-timeLine>li {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.modal-timeLine>li>.line-mark {
    position: relative;
    width: 100px;
}

.modal-timeLine>li>.line-mark::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    display: block;
    background: #0080ff;
    left: 46px;
    border-radius: 10px;
    top: 5px;
    z-index: 100;
}

.modal-timeLine>li>.line-mark::after {
    content: '';
    position: absolute;
    display: block;
    width: 16px;
    height: 16px;
    background: #fff;
    border: 2px solid #0080ff;
    border-radius: 10px;
    left: 41px;
}

.modal-timeLine>li>.timeLine-box {
    width: 500px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px;
    background: #f1f2f6;
    border-radius: 5px;
}

.modal-timeLine>li>.timeLine-box>span {
    font-size: 1.2rem;
}

.modal-timeLine>li>.timeLine-box>.timeLine-info>.timeLine-list {
    margin-bottom: 15px;
}

.modal-timeLine>li>.timeLine-box>.timeLine-info>.timeLine-list>h3 {
    font-size: 1.4rem;
    margin-bottom: 5px;
}

.modal-timeLine>li>.timeLine-box>.timeLine-info>.timeLine-list>h5 {
    font-size: 1.2rem;
    color: #969696;
    font-weight: 400;
}

.modal-timeLine>li>.timeLine-box>.timeLine-info>.timeLine-list>h5>span {
    margin-left: 10px;
    font-size: 1.2rem;
    background: #0080ff;
    padding: 2px 10px;
    border-radius: 10px;
    color: #fff;
}

.modal-input {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.modal-search {
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.modal-search>span {
    color: #969696;
    background: #eee;
    padding: 8px 15px;
    border-radius: 5px;
    margin-right: 10px;
}

.modal-search>select {
    background: #ffffff url(/img/arrow_down.2fb6f130.svg) no-repeat calc(100% - 5px) center/20px auto;
    width: 110px;
    border: 1px solid #ddd;
    height: 32px;
    padding: 5px 25px 5px 12px;
    border-radius: 5px;
    font-size: 1.2rem;
    /* 폰트컬러 */
    color: black;
}

.modal-search>select:focus-visible {
    outline: 1px solid #2B79EE;
}

.modal-search>select>option {
    background: #ffffff;
    color: #969696;
}

.modal-search>input {
    margin: 0;
    background: #ffffff;
    border: 0;
    margin-left: 5px;
    color: #000;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.modal-search>input:hover {
    border: 1px solid #ddd;
}

.modal-search>input:focus-visible {
    outline: 1px solid #2B79EE;
}

.modal-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 1px solid #ddd;
}

.modal-content>.map {
    width: 450px;
    height: 400px;
    margin-right: 20px;
}

.modal-list {
    width: 300px;
}

.modal-list>ul {
    height: 363px;
    max-height: 363px;
    overflow-y: auto;
}

.modal-list>ul>li {
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
}

.modal-list>ul>li>h4 {
    font-size: 1.4rem;
    margin-bottom: 10px;
}

.modal-list>ul>li>p {
    font-size: 1.2rem;
    color: #969696;
}

.modal-list>ul>li>p>img {
    margin-right: 5px;
}

.modal-list>ul>li.active {
    border: 1px solid #0080FF;
    background: #f7fbff;
}

.modal-list>ul>li.active>h4 {
    color: #0080FF;
}

.modal-list-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.modal-list-title>h4 {
    font-size: 1.2rem;
    color: #969696;
    font-weight: 400;
}

.modal-list-title>h5 {
    font-size: 1.2rem;
    font-weight: 400;
}

.modal-list-title>h5>span {
    margin-left: 5px;
    color: #0080ff;
}

.modal-btn {
    margin-top: 15px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
}

.modal-btn>button {
    margin: 0 5px;
    width: 120px;
    height: 35px;
    border: 1px solid #ddd;
    border-radius: 5px;
    color: #969696;
    font-size: 1.4rem;
}

.modal-btn>button.btn-save {
    background: #0080ff;
    color: #fff;
    border: 1px solid #0080ff;
}
</style>

<script>
import Map from "@/components/map/Map";
import { fetchMissionList } from "@/api/mission";
import { fetchMissionLocationList } from "@/api/missionLocation";
import MapDataType from "@/components/map/enum/MapDataType";
import MapMarkerData from "@/components/map/data/MapMarkerData";
import MapLineData from "@/components/map/data/MapLineData";

import MapMappingData from "@/components/map/data/MapMappingData";
import MapPolygonData from "@/components/map/data/MapPolygonData";
import { getMissionCameraList } from "@/api/mission";
import MapUtils from "@/components/common/MapUtils"
import moment from "moment/moment";

export default {
    name: "MissionModal",
    props: {
        eventId: {
            type: String,
            required: true
        },
        missionType: {
            type: String,
            required: true
        },
        missionId: { // 업데이트를 할경우, 이전에 선택된 missionId를 넘겨줌.
            type: String,
            required: false
        },
        refId : {
            type : String,
            required: true
        }
    },
    components: {
        Map
    },
    data() {
        return {
            MapUtils: new MapUtils(),
            activeIndex: null,
            loadMap: 'kakao',
            showWaySelectModal: false,
            missionList: [],
            missionLocationList: [],
            cameraTypeList: [],
            mappingData: new MapMappingData(),
            mapDataType: {},
            keyword: '',
            keywordType: 'all',
            selectEl: null,
            polygonType : "polygon",
            mappingType : "Orthophoto",
        }
    },

    mounted() {
      this.$refs.map.isShowLine = true
      this.getMissionList()
      this.cameraInit()
      this.setMapDateType()
    },
    beforeUpdate() {

    },
    updated() {

    },
    methods: {
        closeWaySelectModal() {
            this.searchReset()
            this.$emit('isMissionModal')
        },
        saveMission() {
            this.$emit('saveMission', this.missionList[this.activeIndex])
        },
        setMapDateType(){
            if(this.missionType === "WayPoint"){
                this.mapDataType.marker = MapDataType.ID_WAYPOINT 
                this.mapDataType.line =MapDataType.ID_WAYPOINT 
            }else{
                this.mapDataType.marker = MapDataType.ID_MAPPING_MARKER 
                this.mapDataType.line =MapDataType.ID_MAPPING_POLYGON_LINE
                this.mapDataType.inLine = MapDataType.ID_MAPPING_POLYLINE
                this.mapDataType.polygone = MapDataType.ID_MAPPING_HIDDEN_POLYGON
            }
        },

        // 업데이트페이지에서 호출시, 기존 좌표 표시와, 리스트에서 표시를 위해.
        findSelectedMission() {
            const index = this.missionList.findIndex((mission) => {
                return mission.missionId === this.missionId
            })
            return index
        },

        getMissionList() {
            let params = {
                eventId : this.eventId,
                refId: this.refId,
                keywordType: this.keywordType,
                keyword: this.keyword,
                refType: "Station",
                type: this.missionType
            };
            fetchMissionList(params)
                .then((res) => {
                    this.missionList = res.data.data.content
                    this.activeIndex = this.findSelectedMission()
                  this.selectEl = this.missionList[this.activeIndex]
                    
                })
                .then(() => {
                  // 미션을 수정할경우 missionList를 받고, 이전 missionId를 이용해서 location을 가져옴.
                  if(this.missionId !== null) {
                    this.getLocationList(this.missionList[this.activeIndex].missionId)
                  }
                })
        },

        selectMission(data, index) {
            this.activeIndex = index;
            this.$refs.map.onClearMapData();
            
            this.selectEl = data
            this.getLocationList(data.missionId);
        },
        searchReset() {
            this.keywordType = 'all'
            this.keyword = ''
            this.missionList = []
            this.activeIndex = null
            this.$refs.map.onClearMapData();
        },

        // mapping, way 통합후, mapping 수정
        getLocationList(missionId) {
            const params = {
                eventId: this.eventId,
                missionId: missionId
            };
            fetchMissionLocationList(params)
                .then((res) => {
                    if (res.data.result == 0) {
                        this.missionLocationList = res.data.data.content;
                        const marker = this.missionLocationList[0]
                        this.$refs.map.onLngLatToMoveCenter(marker.lng, marker.lat);
                        
                        this.missionLocationList.forEach(marker =>{
                            this.createMarker(marker,this.mapDataType.marker)
                        })
                        if(this.mapDataType.marker === MapDataType.ID_MAPPING_MARKER){
                          this.settingMappingData()
                          this.createLastLine(this.missionLocationList.length)
                          this.createMappingLine()
                        }
                    }
                })
        },

        // 좌표개수대로 반복해서 마커 찍음.
        createMarker(markerData,markerType) {
            // 웨이마커인지, 매핑마커인지 확인
            const index = markerData.index + 1
            const mapMarkerData = new MapMarkerData();
            mapMarkerData.id = index;
            mapMarkerData.lng = markerData.lng;
            mapMarkerData.lat = markerData.lat;
            mapMarkerData.rotate = markerData.azimuth;
            mapMarkerData.name = index;

            if (this.$refs.map.onIsMarker(markerType, index)) {
                this.$refs.map.onModifyMarkerAt(markerType, mapMarkerData);
            } else {
                this.$refs.map.onCreateMarker(markerType, mapMarkerData);
            }
            if (index > 1) {
               let slice = this.missionLocationList.slice(index - 2, index);
               this.createMapLine(markerData.index , slice);
            }
        },



        /**
         * @params 정렬된 좌표목록들 가져와서 라인 그리게 함.
         */
        createMapLine(id, locationGroup) {
            let mapLineData = new MapLineData();
            mapLineData.id = id;
            mapLineData.locationGroup = locationGroup;
            mapLineData.isMain = true;
            mapLineData.isShow = true;
            if (this.$refs.map.onIsLine(this.mapDataType.line, id)) {
                this.$refs.map.onModifyLineAddAt(this.mapDataType.line, mapLineData);
            } else {
                this.$refs.map.onCreateLine(this.mapDataType.line, mapLineData);
            }
        },

        // 매핑일때 마지막 라인
        createLastLine(index) {
            if (index > 2) {
                let slice = [
                    this.missionLocationList[0], // 첫 번째 요소
                    this.missionLocationList[index - 1] // 마지막 요소
                ];
                this.createMapLine(index, slice);
                
            }
        },

        createMappingLine(dataType) {
            /**
             *  1. 각도 , 간격, 비행높이 정보를 보낸다.
             *  2. 매핑 뒤에 둘 히든 폴리곤을 생성한다.
             *  3. 히든폴리곤과 다각형에 라인을 생성한다.
             */
            this.sendMappingData(dataType);

            /** 매핑을 감싸고있는 네모 보이게할거냐, 말거냐.*/
            this.createPolygon(MapDataType.ID_MAPPING_HIDDEN_POLYGON);
            this.$refs.map.onCreateMappingLine(this.mappingType, this.polygonType)
        },

        /**폴리곤 생성.*/
        createPolygon(mapDataType) {
            var mapPolygonDate = new MapPolygonData();
            if (mapDataType == MapDataType.ID_MAPPING_HIDDEN_POLYGON) {
                var centerInfo = this.createCenterInfo()
                mapPolygonDate.setPolygon(MapDataType.ID_MAPPING_HIDDEN_POLYGON)
                mapPolygonDate.setLatLng(centerInfo)
                mapPolygonDate.posList = this.createSqurePolygon(centerInfo, centerInfo.distance, Number(this.mappingData.angle))
                mapPolygonDate.line = mapPolygonDate.posList
            }
            this.$refs.map.onCreateMappingPolygon(mapPolygonDate, mapDataType);
        },
        /**다각형 도형의 가운데 정보를 생성한다.*/
        createCenterInfo() {
            var maxDistance = 0
            var center = this.MapUtils.calculateCentroid(this.missionLocationList) //다각형 중점 구하기

            this.missionLocationList.forEach(item => { //다각형 중점에서부터의 각포인트 최대 거리 구하기.
                var distance = this.MapUtils.calculateDistance(item, center)
                if (maxDistance < distance) maxDistance = distance
            })
            var data = { lat: center.lat, lng: center.lng, distance: maxDistance }
           
            return data
        },
        /**매핑 영역 밑에 깔리는 폴릭곤 */
        createSqurePolygon(point, distance, angle) {
            var polygonPosList = []

            var rightPoint = this.MapUtils.calculateNewPosition(point, 90 + angle, distance);
            polygonPosList.push(this.MapUtils.calculateNewPosition(rightPoint, 0 + angle, distance, polygonPosList.length));
            polygonPosList.push(this.MapUtils.calculateNewPosition(rightPoint, 180 + angle, distance, polygonPosList.length));

            var leftPoint = this.MapUtils.calculateNewPosition(point, 270 + angle, distance);
            polygonPosList.push(this.MapUtils.calculateNewPosition(leftPoint, 180 + angle, distance, polygonPosList.length));
            polygonPosList.push(this.MapUtils.calculateNewPosition(leftPoint, 0 + angle, distance, polygonPosList.length));

            return polygonPosList
        },

        settingMappingData() {
            if (this.selectEl != null) {
                const findIndex = this.cameraTypeList.findIndex((item) => { 
                    return item.id === this.selectEl.cameraType
                })

                if (findIndex == -1) { return }
                
                let camera = JSON.parse(JSON.stringify(this.cameraTypeList[findIndex]));
                this.mappingData.setApiData(this.selectEl)
                this.mappingData.cameraType = camera
                this.sendMappingData();
            }
        },

        sendMappingData(dataType) {
            this.$refs.map.sendMappingData(this.mappingData, dataType)
        },
        cameraInit() {
            getMissionCameraList().then(res => {
                const data = JSON.parse(res.data.data)
                this.cameraTypeList = data.DJI
            })
        },
        customFormatter(date) {
          return moment(date).format("YYYY년 MM월 DD일");
        },
    }

}
</script>