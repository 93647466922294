<template>
  <div class="boxCol2-2 flex" style="height: 100%;">
    <div class="box liveWrap flex"
      style="width: 10%; min-width:280px;  border-radius:0; overflow-y: auto; border: none; background: #232323; padding: 0px 0px 0 0px; margin-right: 0; margin-bottom: 0px">
      <!--운용중인 드론 현황 테이블-->
      <DroneFlyAlarmAudioPlayer ref="audioPlayer" />
      <div
        style="width: 100%; bottom: 0px; margin-top: 0px; height: inherit; overflow: hidden; display: flex; flex-direction: column;">
        <div class="flexL" style="width: 100%;flex-direction: column;">
          <div class="flexB"
            style="margin-top:15px; margin-bottom: 15px;padding-bottom: 15px; border-bottom:1px solid #707070;">
            <span style="font-size: 1.6rem; color: white;font-weight:600; padding-left:10px;">{{
              $t("top-select-site-view-list") }}</span>
            <!-- <router-link :to="`/${accountId}/eventControl/liveListControl`" >
              <span class="more right" style="color: white; line-height: 2.7rem;" >
                {{$t("btn-txt-more")}}
                <img src="@/assets/images/icon_svg/arrow_right_wh.svg" alt="icon"/>
              </span>
            </router-link> -->
          </div>
          <div class="list-set">
            <h4>{{ $t("top-select-site-view-list-set") }}</h4>
            <div class="set-btn">
              <div class="radioBtnSort">
                <label><input type="radio" name="sortBtn" checked v-model="sort" value="viewId"
                    @change="radioChange" /><span class="radio_sort">
                    {{ $t("top-select-site-view-list-sort-view-id") }}
                  </span></label>
                <label style="text-align: right;"><input type="radio" name="sortBtn" v-model="sort" value="title"
                    @change="radioChange" /><span class="radio_sort">
                    {{ $t("top-select-site-view-list-sort-site-name") }}
                  </span></label>
              </div>
              <div class="radioBtnSort">
                <label><input type="radio" name="sortBtn" v-model="sort" value="managerDepartmentTitle"
                    @change="radioChange" /><span class="radio_sort">
                    {{ $t("top-select-site-view-list-sort-manager-department-title") }}
                  </span></label>
                <label style="text-align: right;"><input type="radio" name="sortBtn" v-model="sort" value=""
                    @change="radioChange" /><span class="radio_sort" style="text-align: right;">
                    {{ $t("top-select-site-view-list-sort-reg-date") }}
                  </span></label>
              </div>
            </div>
          </div>
        </div>

        <!--현장목록-->
        <div class="tableWrap box one mostly-customized-scrollbar"
          style="width: 100%; height: 100%; min-height: 434px; cursor: pointer; overflow-y: auto; border: none; border-radius: 0; margin-bottom: 0; padding: 0; background: #232323;"
          :style="{ 'height': typeChangeUpShowHeight - 127 + 'px' }">
          <div class="flexL all-site-box" v-for="(data, i) in eventList" :key="i" :id="'eventItem' + (i)"
            style="padding: 15px 0; margin-bottom: 0px; border-bottom:1px solid #707070;" @click="handleEventItem(data)"
            :style="[selectData != null && selectData.eventId == data.eventId ? { 'background': '#0080ff', 'color': '#fff' } : { 'color': '#fff' }]">
            <template>
              <div style="width: 75%;">
                <div class="ch_info flexL" style="flex-direction: column; line-height: 2rem; padding-left:10px;">
                  <div class="liveInfoCont">No. {{ data.viewId == undefined ? "-" : data.viewId }}</div>
                  <p class="live-box"
                    style="font-size: 1.4rem; border-radius: 0px; font-family: 'Noto'; width: 100%; margin-bottom: 10px;">
                    {{ data.title }}</p>
                  <div class="liveInfoCont" style="color:#c9c9c9">{{ data.managerDepartmentTitle == undefined ? "-" :
                    data.managerDepartmentTitle }}│{{ dateFormat(data.registerDate) }}</div>
                  <!--<div class="liveInfoCont">{{ data.deviceName == undefined ? "-" : data.deviceName }}</div>-->
                  <!--<div class="liveInfoCont">{{ data.modelName == undefined? "-" : data.modelName}}</div>-->
                </div>
              </div>
              <!--   현장 썸네일  -->
              <div style="margin: auto;"><!-- @click="$event.cancelBubble = true" >-->
                <img v-if="data.viewId != null" :src="getMissingImg(data.viewId)" alt="icon"
                  style="width: 3.6rem; height: 3.6rem;" />
                <!-- <button class="bk_line small" @click="handleEvent(data)" style="width: 3.6rem; height: 3.6rem; padding: 0;">
                  선택
                </button>-->
              </div>
            </template>
          </div>

        </div>
      </div>
    </div>

    <div ref="map_div" class="map" style="width: 100%; height: 100%;">
      <Map ref="map" style="width: 100%; height: 100%;" :loadMapName="loadMap" :isRightLngLat="true"
        :isLeftButtonPosition="false" @item-click="onItemClick" />
      <MapButtonComponent class="status-button" ref="buttonMap" />

      <!-- 드론정보(간략) -->
      <div style="display:none; position: absolute; top: 500px; left: 42px; z-index: 2000; text-align: center;">
        <div class="tooltipBack flex"
          style="flex-direction: column; text-align: start; background-size:155px 55px; width: 155px; height: 55px; padding: 6px 15px; margin-bottom: 3px;">
          <p class="bold">Mavic2-2호기</p>
          <div class="flexB">
            <span class="bold textLimit" style="font-size: 1.2rem; color: #0790ff">홍길동</span>
            <span class="textLimit" style="font-size: 1.2rem; ">중부지방산림청</span>
          </div>
        </div>
        <!-- 드론 아이콘 -->
        <img src="@/assets/images/map/ic_map_drone.svg" alt="icon" />
      </div>
      <!--  현장정보  -->
      <div class="box eventBox"
        style="display: none; position: absolute; top: 77px; left: 42px; z-index: 2000; width: 280px; height: 110px; padding: 10px; border-radius: 4px; box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);">
        <div class="boxCol2-1">
          <div class="contents" style="padding: 0; line-height: 1.8rem; max-width: 210px;">
            <p>No. 12503</p>
            <p><span class="bold">마진도 촬영현장</span></p>
            <p>전남 영암군 도포면 입비동길 1</p>
          </div>
          <div class="buttonWrap" style="margin-top: 0">
            <button class="pointLine" style=" width: 3.6rem; height: 3.6rem; border-radius: 5px; font-size: 1.2rem;">
              선택
            </button>
          </div>
        </div>
        <div class="flexB" style="margin-top: 15px; ">
          <p>영암국유림관리소</p>
          <p>홍길동</p>
          <p>2022.05.24</p>
        </div>
      </div>
    </div>

    <div class="flex tabContent" ref="tab_div" style="width: auto; max-width: 340px; justify-content: flex-end;">
      <transition name="screen">
        <div class="flex" style="z-index:1; overflow-y: auto; overflow-x: hidden; background: #333333;">
          <div class="box liveWrap flexL mostly-customized-scrollbar"
            style="width: 100%; max-width: 340px; overflow-y: auto; overflow-x: hidden; background: #333333; border-radius:0; border: none; margin-bottom: 0; padding: 0;display: flex;align-items: flex-start;flex-direction: column;"
            :style="[isAutoPlay ? 'max-width: 340px' : 'max-width: 320px;']">
            <!-- 라이브 영상 -->
            <div class="live flexL" v-for="(index) in 4" :key="index"
              style="width: 350px; border: none; position:relative;">
              <span class="channel"
                style="z-index:101; width:50px; height:20px; text-align:center; line-height: 20px; position: absolute; top: 10px; right: 10px; background: #3c3c3ccc; color:white; font-weight: 400; font-size:1.2rem; border-radius: 5px; ">CH.{{
                  index }}</span>
              <div style="width: 100%;">
                <div v-if="getLiveData(index - 1) == undefined || getLiveData(index - 1).isLoad == false"
                  class="novideo">
                  <span>{{ $t("ready-live") }}</span>
                </div>

                <Video
                    :ref="`video_${getLiveData(index - 1).channelId}`"
                    v-if="getLiveData(index - 1) != undefined"
                    v-show="getLiveData(index - 1).isLoad == true"
                    class="play-on play-3-view"
                    :src="getHlsUrl((getLiveData(index - 1)))"
                    :isVod="false"
                    :isAuto="true"
                    :isControls="false"
                    :playerId="getLiveData(index - 1).channelId"
                    :selectStreamId ="selectedSoundId"
                    :channelIndex="index"
                    :deviceType="getLiveData(index - 1).assignedDeviceType"
                    @toggleSound ="toggleSound"
                    @isLoadedmetadata="playerLoadData"
                    @playerRemoveId="playerRemove"
                />





                <div class="flexL"
                  style=" width: 100%; padding: 0 5px; opacity: 1; background-color: #232323; color: white; position: relative;">
                  <div class="ch_info flexB" style="line-height: 23px;">
                    <div class="flex" style="width: 100%; max-width: 284px;">
                      <span class="liveInfoTitle textLimit" style="color: #b3c9ff;">{{ getLiveData(index - 1) ==
                        undefined ||
                        getLiveData(index - 1).isLoad == false ? 'N/A' : getLiveData(index -
                          1).assignedDepartmentName }}</span>
                      <span class="liveInfoTitle textLimit">{{ getLiveData(index - 1) == undefined || getLiveData(index
                        -
                        1).isLoad == false ? 'N/A' : getLiveData(index - 1).assignedDeviceName }}</span>
                      <span class="liveInfoTitle textLimit">{{ getLiveData(index - 1) == undefined || getLiveData(index
                        -
                        1).isLoad == false ? 'N/A' : getLiveData(index - 1).assignedUserName }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<style scoped>
.status-button {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
}


.status-button>.infoAlert {
  position: absolute;
  left: 20px;
  bottom: 30px;
}
.status-button > .al-list{
  position: absolute;
  bottom: 30px;
  right: 15px;
}
input[type="checkbox"] {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  border: none;
  appearance: none;
  cursor: pointer;
  transition: 0.2s;
}

.active {
  color: red !important;
}

.screen-enter {
  transition: opacity 0.2s;
  opacity: 0.2;
  min-width: 0px;
  width: 0%;
}

.screen-enter-active {
  transition: opacity 0.2s;
  min-width: 645px;
  width: 40%;
}

.small-map {
  width: calc(100% - 697px) !important;
}

/*상세보기 버튼*/
.detailBtn {
  width: 64px !important;
  height: 26px !important;
  padding: 0 !important;
  text-align: center !important;
  color: #6ebeff !important;
  border: 1px solid #6ebeff !important;
  border-radius: 13px !important;

}

.detailBtn:hover {
  background: #0790ff !important;
  color: white !important;
}

.droneVal span {
  font-size: 1.1rem;
  font-weight: normal;
  margin-left: 3px;
}

.dataValue {
  font-size: 1.4rem;
  color: #6ebeff;
  margin-left: 3px;
}

.dataVal {
  font-size: 1.4rem;
  color: #fff;
  margin-left: 3px;
  font-weight: bold;
}

.btn_station {
  margin: auto;
  justify-content: center;
}

button.ptDel {
  padding: 0px 10px;
  background: #b9b9b9;
}

.visibleHang:hover {
  background: #333;
}

.ch_info {
  width: 100%;
}

.liveInfoTitle {
  width: 33%;
  max-width: 80px;
  margin-right: 8px;
  color: white;
  font-size: 1.2rem;
}

:nth-child(3) {
  margin-right: 0;
}

.liveInfoCont {
  color: white;
  font-size: 1.2rem;
}

.radio_sort {
  color: white;
  font-size: 1.2rem;
}

.radioBtnSort {
  display: flex;
  justify-content: space-between;
  height: 25px;
  padding: 0px;
  margin-bottom: 5px;
}

.radioBtnSort>label {
  width: calc(50% - 5px);
  display: flex;
  align-items: center;
  padding: 5px 5px;
  border-radius: 3px;
}

/* .novideo{
  height: calc(100% - 25px);
} */
.all-site-box {
  background: #232323;
  border-radius: 0px;
}

.video-player {
  width: 100%;
  height: 100%;
  position: relative;
}

/* 지도 현장 정보 CSS*/
.eventBox>.boxCol2-1>.contents>p {
  width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-line-clamp: 1;
}

.list-set {
  padding-bottom: 15px;
  border-bottom: 1px solid #707070;
}

.list-set>h4 {
  font-size: 1.4rem;
  color: #fff;
  padding-left: 10px;
  margin-bottom: 15px;
}

.set-btn {
  padding: 0 10px;
}
</style>
<script>
import moment from "moment";
import { mapState } from "vuex";

import DroneFlyAlarmAudioPlayer from "@/components/audio/droneFlyAlarmAudioPlayer";
import Map from "@/components/map/Map";
import MapDataType from "@/components/map/enum/MapDataType";
import MapMarkerData from "@/components/map/data/MapMarkerData";
import MapLineData from "@/components/map/data/MapLineData";
import { fetchEventLiveList } from "@/api/dashboard";
import { fetchEventList, fetchEventLogList, fetchEventNoticeList, changeEventLogData } from "@/api/event";

import { format } from "@/mixins/format";

import { fetchChannelList, getLiveUrl, fetchChannelCheckStream } from "@/api/channel";

import { fetchVodList } from "@/api/contents";
import MapType from "@/components/map/enum/MapType";

// import Video from "@/components/video/videoPlayer";
// import WebRTC_WOWZA from "@/components/video/WebRTC_WOWZA";
import Video from "@/components/video/videoPlayer";
//현장 썸네일
import thum1 from "@/assets/images/thum_s1.png";
import thum2 from "@/assets/images/thum_s2.png";
import thum3 from "@/assets/images/thum_s3.png";
import thum4 from "@/assets/images/thum_s4.png";
import thum5 from "@/assets/images/thum_s5.png";
import thum6 from "@/assets/images/thum_s6.png";
import MapButtonComponent from "@/components/map/MapButtonComponent.vue";
import {getHlsUrl} from "@/components/common/LiveUtils";

import MapMappingData from "@/components/map/data/MapMappingData";

/*import MapStationLive from "./skyView/MapStationLive";*/
export default {
  name: "Home",
  components: {
    Map,
    // WebRTC_WOWZA,
    DroneFlyAlarmAudioPlayer,
    MapButtonComponent,
    Video
  },

  mixins: [format],
  props: {
    eventId: {
      type: String,
      default: null,
    },
    address: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      moment: moment,
      visible: null,
      isAutoPlay: true,
      monitorVisible: 1,
      id: null,
      accountId: localStorage.getItem("accountId"),
      eventInfo: null,
      deviceList: [],
      deviceCount: [],
      selectedSoundId: null,

      interval: null,
      eventIdInterval: null,
      channelInterval: null,
      workerInterval: null,
      vodInterval: null,
      noticeInterval: null,
      eventLogInterval: null,
      selectEl: null,
      pointServerTime: null,

      liveSize: 4,
      channelStreamIds: [],
      currentChannel: null,
      viewChannelList: [],
      liveList: [],
      sort: "viewId",

      windowHeight: window.innerHeight,
      typeChangeMapShowHeight: window.innerHeight - 90,
      loadMap: 'kakao',
      isActive: false,
      eventList: [],
      isShowDateBox: false,
      statuses: "Open",
      selectData: null,
      mapType: MapType.ID_SATELLITE,

      eventLogList: null,
      typeChangeUpShowHeight: 0,
      images: [thum1, thum2, thum3, thum4, thum5, thum6],

      mappingData: new MapMappingData(),
      isShowNoFly : true,
      markerList: [],
      index: 0
    };
  },
  watch: {
    channelStreamIds(value) {
      if (value.length > 4) {
        this.channelStreamIds.splice(this.liveSize, 1);
      }
    },
    currentLang(value) {
      this.initMapData(value);
    },
  },
  computed: {
    ...mapState({
      currentLang: state => state.store.currentLang,
      isAdmin: state => state.store.isAdmin,
    }),
  },
  async mounted() {
    this.$refs.map.onSetZoomLevel(13);
    this.initMapData(this.currentLang);
    this.getEventList();
    this.initButtonMap()


    await this.getEventDevice();//전체 현장에 대한 device 관련 정보를 구한다.
    await this.getEventWorker();
    await this.getLiveList();
    await this.getVodCount();
    await this.getNoticeList();
    await this.getEventLogList();

    this.interval = setInterval(() => {
      this.getEventDevice();
    }, 2000);

    this.workerInterval = setInterval(() => {
      this.getEventWorker();
    }, 2000);

    this.channelInterval = setInterval(() => {
      this.getLiveList();
    }, 2000);

    this.vodInterval = setInterval(() => {
      this.getVodCount();
    }, 2000);

    this.noticeInterval = setInterval(() => {
      this.getNoticeList();
    }, 2000);

    this.eventLogInterval = setInterval(() => {
      this.getEventLogList();
    }, 2000);


  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 1, sub: 3 });

    if (this.eventId == null && this.$route.params.eventId != undefined) {
      this.eventId = this.$route.params.eventId
    }
    this.typeChangeUpShowHeight = this.windowHeight - 140;


  },
  methods: {
    getHlsUrl,

    settingMappingData() {
      if (this.selectEl != null) {
        const findIndex = this.cameraTypeList.findIndex((item) => {
          return item.id === this.selectEl.cameraType
        })
        if (findIndex == -1) {

          return
        }

        let camera = JSON.parse(JSON.stringify(this.cameraTypeList[findIndex]));
        this.selectEl.cameraType = camera

        this.mappingData.setApiData(this.selectEl)
        this.sendMappingData();
      }
    },
    sendMappingData(dataType) {
      this.$refs.map.sendMappingData(this.mappingData, dataType)
    },
    initButtonMap() {
      this.$refs.buttonMap.showMapTypeChange()
      this.$refs.buttonMap.showMapFlightAlert()
      this.$refs.buttonMap.showMapNoFlightArea()
    },
    toggleSound(channelId) {
      this.selectedSoundId = channelId
    },
    //이미지 불러오기
    getMissingImg(id) {
      return this.images[id.slice(-1) % 6];
    },
    handleDateBox() {
      if (this.isShowDateBox) {
        this.showDateBox = true;
      } else if (!this.isShowDateBox) {
        this.viewSelectDate = false;
        this.showDateBox = false;
      }
    },
    radioChange() {
      this.getEventList();
    },
    dateFormat(date) {
      return moment(date).format("YYYY.MM.DD");
    },
    handleEventItem(data) {
      this.onItemClick(data.eventId, MapDataType.ID_SITE_CIRCLE, this.selectData != null && this.selectData.eventId == data.eventId);
    },
    handleEvent(data) {
      if (!this.$store.state.store.isAdmin && data.managerDepartmentId != this.$store.state.store.departmentId) {
        if (data.isAuth == true && data.authCount == 0) {
          alert(this.$t("user-not-permission"));
          return;
        }
      }
      /* window.open(
           `/${this.accountId}/eventControl/${data.eventId}/SkyView`,
           "",
           "left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no,status=no"
       );*/
      document.location.href = `/${this.accountId}/eventControl/${data.eventId}/SkyView`;
      //this.$router.push({ path: "liveInfoControl", query: { eventId: data.eventId, no: num } });

      //Site Control 새 탭
      //this.routeUrl = this.$router.resolve({ path: `/${this.accountId}/eventControl/${data.eventId}/SkyView`});
      //newTab(this.routeUrl.href, `SkyView_${data.eventId}`);
    },
    async getEventList() {
      var params = {
        pageNumber: this.currentPage,
        pageSize: null,
        keywordType: this.keywordType,
        keyword: this.keyword,
        statuses: this.statuses,
        sort: this.sort,
      };

      if (this.startYmd != null && this.endYmd != null) {
        params["dateRange.from"] = moment(this.startYmd).format("YYYY-MM-DD");
        params["dateRange.to"] = moment(this.endYmd).format("YYYY-MM-DD");
      }

      fetchEventList(params).then((res) => {

        this.total = res.data.data.total;
        this.eventList = res.data.data.content;
        this.$refs.map.eventOpenCount = res.data.data.total;
        this.eventList.forEach((item) => {
          item.deviceCount = 0;
          let location = {
            lng: item.lng,
            lat: item.lat
          }
          let isMain = this.selectData != null && item.eventId == this.selectData.eventId;
          this.createMapSiteMarker(MapDataType.ID_SITE_CIRCLE, item.eventId, null, location, isMain);
        })
      });
    },
    getZoomLevel() {
      return this.currentLang == "en" ? 6 : 12
    },
    handleRefresh() {
      this.$router.go();
    },
    preset() {
      let id = localStorage.getItem("LiveEventInfoSub");
      if (id != undefined) {
        this.screenVisible = id;
      } else {
        if (this.screenVisible == null) {
          this.screenVisible = 1;
        }
        localStorage.setItem("LiveEventInfoSub", this.screenVisible);
      }
    },
    initMapData(value) {
      if (value == 'en') {
        this.loadMap = 'google'
      } else {
        this.loadMap = 'kakao'
      }


    },

    //날씨 관련 함수 endregion

    //페이지 리사이즈
    handleResize() {
      this.windowHeight = window.innerHeight;
      this.typeChangeMapShowHeight = this.windowHeight - 90;
      this.typeChangeUpShowHeight = this.windowHeight - 140;
    },
    playerRemove(id) {
      let findIndex = this.viewChannelList.findIndex(item => item.channelId == id);
      if (findIndex != -1) {
        let channel = this.viewChannelList[findIndex];
        if (this.currentLive != null && this.currentLive.channelId == channel.channelId) {
          this.clickLive(channel);
        }
        this.viewChannelList.splice(findIndex, 1);
      }
    },
    playerLoadData(id, isLoad) {
      var isFindIndex = (data) => {
        return data.channelId == id;
      };

      var findIndex = this.viewChannelList.findIndex(isFindIndex)
      if (findIndex != -1) {
        this.viewChannelList[findIndex].isLoad = isLoad;
        this.$set(this.viewChannelList, findIndex, this.viewChannelList[findIndex]);
      }
    },
    getLiveData(index) {
      if (this.viewChannelList[index] == undefined) {
        return;
      }
      return this.viewChannelList[index];
    },
    getLiveUrl(channelData) {
      var url = getLiveUrl(channelData);
      if (url == '-') {
        return ''
      }
      return url
    },
    changeMapLatLng(lat, lng) {
      if (lat == null || lat == undefined || lng == null || lng == undefined) {
        return;
      }

      this.$refs.map.onSetLngLat(lat, lng);
    },

    async getLiveList() {
      let params = {
        pageNumber: 0,
        pageSize: this.liveSize,
        assignedStatus: "Assigned",
        isDepartment: this.isAdmin ? false : true
      };
      await fetchChannelList(params).then((res) => {
        this.total = res.data.data.total;
        this.liveList = res.data.data.content;

        //채널 추가 및 삭제 로직
        //삭제 아이디 찾기 위한 데이터 넣기
        let delIds = [];
        this.viewChannelList.forEach(item => {
          delIds.push(item.channelId.toString());
        });

        //채널 기존 채널 있는지 확인 및 삭제 아이디 에서 삭제
        this.liveList.forEach(live => {
          let findIndex = delIds.findIndex(item => item == live.channelId);
          if (findIndex != -1) {
            delIds.splice(findIndex, 1);
          }
        });

        //삭제 아이디 있는 경우 채널에서 삭제
        delIds.forEach(delId => {
          let findIndex = this.viewChannelList.findIndex(item => item.channelId == delId);
          if (findIndex != -1) {

            let channel = this.viewChannelList[findIndex];
            if (this.currentLive != null && this.currentLive.channelId == channel.channelId) {
              this.clickLive(channel)
            }
            // this.$refs.map.onDeleteMarkerAt(MapDataType.ID_DRONE, channel.channelId);
            this.viewChannelList.splice(findIndex, 1);
          }
        })

        //채널 추가
        for (var i = 0; i < this.liveList.length; i++) {
          this.addStreamChanelToChannel(this.liveList[i])
        }
      });
    },
    addStreamChanelToChannel(channel) {
      fetchChannelCheckStream(channel.channelId).then((res) => {
        if (res.data.result == 0 || res.data.data.bytesIn != 0) {
          let findIndex = this.viewChannelList.findIndex(item => item.channelId == channel.channelId);
          if (findIndex == -1) {
            channel.isLoad = false;
            this.viewChannelList.push(channel)
          }
        }
      })
    },
    channelChange() {
      if (this.channelStreamIds.length > 4) {
        this.channelStreamIds.pop()
      }
    },

    handleSelectAction(data, isSelected) {
      var mapDataType = null;
      var refId = null;

      if (data.deviceLogId == undefined && data.workerId == undefined) {
        mapDataType = MapDataType.ID_POINT;
      } else if (data.deviceLogId != undefined) {
        refId = data.deviceLogId;
        mapDataType = MapDataType.ID_DRONE;
      } else {
        refId = data.workerId;
        mapDataType = data.type != 'Controller' ? mapDataType = MapDataType.ID_USER : mapDataType = MapDataType.ID_CONTROLLER;
      }
      var mapMarkerData = new MapMarkerData();
      switch (mapDataType) {
        case MapDataType.ID_POINT:
          var sendData = isSelected == true ? data : null;
          this.$refs.map.onPointModify(sendData);
          mapMarkerData.id = data.index;
          mapMarkerData.pointType = data.type;
          break;
        case MapDataType.ID_DRONE:
        case MapDataType.ID_USER:
        case MapDataType.ID_CONTROLLER:
          mapMarkerData.id = refId;
          var mapLineData = new MapLineData();
          mapLineData.id = refId;
          this.$refs.map.onLineIsSelected(mapDataType, mapLineData, isSelected);
          break;
      }

      this.$refs.map.onMarkerIsSelected(mapDataType, mapMarkerData, isSelected);
      if (isSelected == true) {
        this.changeMapLatLng(data.lat, data.lng);
      }
    },
    handleUnSelectEl() {
      var sendSelect = this.selectEl;
      this.selectEl = null;
      this.currentChannel = null;
      this.$refs.map.onSetLngLat(null, null);
      this.handleSelectAction(sendSelect, false);
    },
    handleGetSelectId(data) {
      var resultId = null;
      if (data == null) {
        return resultId;
      }

      if (data.index != undefined) {
        resultId = data.index;
      } else if (data.deviceLogId != undefined) {
        resultId = data.deviceLogId;
      } else if (data.workerId != undefined) {
        resultId = data.workerId;
      }
      return resultId;
    },
    mapItemClickListener(id, data, isSend) {
      if (isSend) {
        let findIndex = this.eventList.findIndex((item) => item.eventId == id);
        if (findIndex != -1) {
          this.handleEvent(this.eventList[findIndex])
        }
      } else {
        this.handleEventItem(data);
      }
    },
    onItemClick(id, type, isMain) {
      if (this.selectData != null) {
        let findIndex = this.eventList.findIndex((item) => item.eventId == this.selectData.eventId);
        if (findIndex != -1) {
          let data = this.eventList[findIndex];
          let location = {
            lng: data.lng,
            lat: data.lat,
          }
          this.createMapSiteMarker(type, data.eventId, "", location, false, true);
          this.selectData = null;
        }
      }
      let findIndex = this.eventList.findIndex((item) => item.eventId == id);
      if (findIndex != -1) {
        let data = this.eventList[findIndex];
        if (isMain) {
          this.$refs.map.onInfoMarkerRemove(type, "event_info");
          this.$refs.map.moveToDefaultCoordinates();//지도의 기본 좌표로 지도 중심을 이동
          return;
        } else {
          this.$refs.map.onLngLatToMoveCenter(data.lng, data.lat);//현장의 좌표로 지도 중심을 이동
        }
        this.selectData = data;
        let location = {
          lng: data.lng,
          lat: data.lat,
        }
        this.createMapSiteMarker(type, data.eventId, "", location, true);
        let mapMarkerData = new MapMarkerData();
        mapMarkerData.id = "event_info";
        mapMarkerData.lng = data.lng;
        mapMarkerData.lat = data.lat;
        this.$refs.map.onInfoMarkerShow(type, mapMarkerData, this.eventList[findIndex], this.mapItemClickListener);
        document.getElementById("eventItem" + findIndex).scrollIntoView();
      }
    },
    createMapSiteMarker(mapDataType, id, name, location, isMain) {
      let mapMarkerData = new MapMarkerData();
      mapMarkerData.id = id;
      mapMarkerData.lng = location.lng;
      mapMarkerData.lat = location.lat;
      mapMarkerData.isMain = isMain;
      mapMarkerData.name = name;
      if (mapDataType == MapDataType.ID_DRONE) {
        mapMarkerData.rotate = location.azimuth;
      }
      if (mapDataType == MapDataType.ID_USER) {
        var filtr = this.liveList.filter(item => {
          return item.assignedWorkerId == id;
        })
        if (filtr.length > 0) {
          mapMarkerData.isUserLive = true;
        }
      }
      if (this.$refs.map.onIsMarker(mapDataType, id)) {
        this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
      } else {
        this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
      }
    },

    createMapDeviceMarker(mapDataType, data, isMain) {
      if (this.$refs.map == undefined) {
        return;
      }
      let mapMarkerData = new MapMarkerData();
      mapMarkerData.id = data.selectId;
      mapMarkerData.isMain = isMain;
      switch (mapDataType) {
        case MapDataType.ID_SITE:
          mapMarkerData.lat = data.lat;
          mapMarkerData.lng = data.lng;
          break;
        case MapDataType.ID_DRONE:
          mapMarkerData.setDroneData(data);
          break;
        case MapDataType.ID_USER:
        case MapDataType.ID_STREAMER:
        case MapDataType.ID_CONTROLLER:
          mapMarkerData.setUserData(data);
          break;
        case MapDataType.ID_STATION:
          mapMarkerData.setStationData(data);
          break;
      }

      if (this.$refs.map.onIsMarker(mapDataType, data.selectId)) {
        this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
      } else {
        this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
      }
    },


    async getEventDevice() {
      let params = {
        eventId: null,
        isDepartment: this.isAdmin ? false : true
      };
      await fetchEventLiveList('device', params).then((res) => {
        if (res.data.result == 0) {
          this.deviceCount = res.data.data.total
          this.deviceList = res.data.data.content

          var deviceIds = [];
          this.deviceList.forEach((item) => {
            var isMain = false;
            if (this.selectEl != null && this.selectEl.deviceLogId == item.deviceLogId) {
              this.changeMapLatLng(item.lat, item.lng);
              isMain = true;
            }
            item.selectId = item.deviceLogId;
            this.createMapDeviceMarker(MapDataType.ID_DRONE, item, isMain);
            deviceIds.push(item.deviceLogId);
          });

          this.$refs.map.onLeaveIds(MapDataType.ID_DRONE, deviceIds);
          this.$refs.map.deviceLiveCount = this.deviceCount

          //현장별 다바이스 카운트 넣기
          if (this.eventList.length != 0) {
            this.eventList.forEach((item, index) => {
              let filter = this.deviceList.filter((filter) => filter.eventId == item.eventId);
              item.deviceCount = filter.length;
              this.$set(this.eventList, index, this.eventList[index]);
            });
          }
          if (this.selectData != null) {
            this.$refs.map.onInfoMarkerRemove(MapDataType.ID_SITE_CIRCLE, "event_info");
            let mapMarkerData = new MapMarkerData();
            mapMarkerData.id = "event_info";
            mapMarkerData.lng = this.selectData.lng;
            mapMarkerData.lat = this.selectData.lat;
            this.$refs.map.onInfoMarkerShow(MapDataType.ID_SITE_CIRCLE, mapMarkerData, this.selectData, this.mapItemClickListener);
          }
        }
      });
    },
    async getEventWorker() {
      let params = {
        workerTypes: 'Streamer',
        eventId: null,
        isDepartment: this.isAdmin ? false : true
      };
      await fetchEventLiveList('worker', params).then((res) => {
        if (res.data.result == 0) {
          this.$refs.map.workerLiveCount = res.data.data.total;
          this.workerList = res.data.data.content;
          var workerIds = [];
          this.workerList.forEach((item) => {
            var isMain = false;
            if (this.selectEl != null && this.selectEl.workerId == item.workerId) {
              this.changeMapLatLng(item.lat, item.lng);
              isMain = true;
            }
            item.selectId = item.workerId;
            this.createMapDeviceMarker(MapDataType.ID_STREAMER, item, isMain);
            workerIds.push(item.workerId);
          });

          this.$refs.map.onLeaveIds(MapDataType.ID_STREAMER, workerIds);
        }
      });

    },
    async getVodCount() {
      let params = {
        eventStatus: 'Open'
      };
      fetchVodList(params).then((res) => {
        this.$refs.map.vodLiveCount = res.data.data.total;
      });
    },
    async getNoticeList(eventId) {
      let params = {
        statuses: 'Used',
      };
      fetchEventNoticeList(params, eventId).then((res) => {
        this.$refs.map.noticeList = res.data.data.content;
      });
    },
    async getEventLogList() {
      let params = {
        page_size: 30,
        isDepartment: this.isAdmin ? false : true
      };
      fetchEventLogList(params).then((res) => {
        if (this.$refs.map == undefined) {
          return;
        }

        if (this.eventLogList != null) {
          this.eventLogList.sort((item) => item.id.index);
        }
        let list = changeEventLogData(res.data.data.content, this.eventLogList);
        if (list != null) {
          //기존 데이터 index 값 이후 드론 이륙 데이터 있는지 확인
          //기존 데이터 없으면 현 시간으로 1분 데이터 가져와서 알려줌(PC 시간);
          var oldLogTime = null;
          if (this.eventLogList != null && this.eventLogList.length > 0) {
            oldLogTime = new Date(this.eventLogList[0].logDate).getTime();
          }
          if (list.length > 0) {
            let searchList;
            if (oldLogTime != null) {
              searchList = list.filter((item) => new Date(item.logDate).getTime() > oldLogTime);
            } else {
              let currentDate = new Date().getTime();
              searchList = list.filter((item) => (currentDate - new Date(item.logDate).getTime()) <= 240 * 1000);
            }

            if (searchList.length > 0) {
              searchList.forEach((item) => {
                if (item.tag == "Drone" && item.action == "DeviceStart") {
                  this.$refs.audioPlayer.onPlay();
                }
              });
            }
          }

          this.eventLogList = list;

        }
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.eventIdInterval);
    clearInterval(this.channelInterval);
    clearInterval(this.workerInterval);
    clearInterval(this.vodInterval);
    clearInterval(this.noticeInterval);
    clearInterval(this.eventLogInterval);
  },
};
</script>
