<template>
  <div class="home">
    <div class="login_bg">
      <img src="@/assets/images/login_bg.jpg">
    </div>
    <div class="login portal">
      <div class="flexL" style="flex-direction: column; margin: auto;width: 700px;">
        <div style="padding-top: 5rem;">
          <div class="profile-box">
            <h2>{{ $t("user-profile-update-title") }}</h2>
            <div class="flexL profile-from">
              <p class="from-title">{{ $t("user-data-num") }}</p>
              <p class="from-info">{{ userId }}</p>
            </div>
            <div class="flexL profile-from">
              <p class="from-title">{{ $t("user-data-regdate") }}</p>
              <p class="from-info">{{ moment(registerDate).format("YYYY.MM.DD HH:mm:ss") }}</p>
            </div>
            <div class="flexL profile-from">
              <p class="from-title">
                {{ $t("user-data-email-id") }}
                <span>*</span>
              </p>
              <p class="from-info">{{ loginId }}</p>
            </div>
            <div class="flexL profile-from">
              <p class="from-title">{{ $t("user-data-last-login-date") }}</p>
              <p class="from-info">{{ moment(loginDate).format("YYYY.MM.DD HH:mm:ss") }}</p>
            </div>
            <div class="flexL profile-from">
              <p class="from-title">
                {{ $t("user-data-name") }}
                <span>*</span>
              </p>
              <p class="from-info">{{ name }}</p>
            </div>
            <div class="flexL profile-from">
              <p class="from-title">
                {{ $t("user-data-phone-num") }}
                <span>*</span>
              </p>
              <input
                  type="text"
                  :value="phone"
                  @input="bindNumber"
              />
            </div>
            <div class="flexL profile-from" style="position: relative;">
              <p class="from-title">{{ $t("user-data-dept-name") }}</p>
              <select v-model="departmentId">
                <option disabled value>{{ $t("user-data-dept-select") }}</option>
                <option
                    v-for="(data, i) in departmentList"
                    :key="i"
                    :value="data.departmentId"
                >
                  {{ data.name }}
                </option>
              </select>
              <img src="../../assets/images/arrow_down.svg">
            </div>
            <div class="flexL profile-from">
              <p class="from-title">{{ $t("user-data-work") }}</p>
              <input type="text" v-model="ownerWork" />
            </div>
            <div class="flexL profile-from">
              <p class="from-title">
                {{ $t("user-data-authority") }}
                <span>*</span>
              </p>
              <p class="from-info">
                {{
                  userType == "Admin"
                      ? $t("user-data-type-admin")
                        : userType == "User"
                            ? $t("user-data-type-user")
                            : "-"
                }}
              </p>
            </div>
            <div class="flexL password profile-from">
              <p class="from-title">
                {{ $t("user-data-pw-change") }}
                <span>*</span>
              </p>
              <div class="password-from">
                <input type="password" v-model="password" />
                <span>
                  {{ $t("user-data-pw-input-instruction") }}
                </span>
              </div>
            </div>
            <div class="flexL password profile-from">
              <p class="from-title">
                {{ $t("user-data-pw-confirm") }}
                <span>*</span>
              </p>
              <div class="password-from">
                <input type="password" v-model="passwordConfirm" />

                <span
                    v-if="passwordConfirm && password != passwordConfirm"
                    class="errror"
                >{{ $t("user-data-pw-input-correct-msg") }}</span
                >
              </div>
            </div>
            <div class="buttonWrap profile-btn">
              <button class="submit-btn point large" @click="submit">{{ $t("btn-data-change") }}</button>
              <button class="cancel-btn large" @click="back">{{ $t("btn-cancel") }}</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>
<style>

.profile-box{background: #ffffff;display: flex;padding: 60px 80px;justify-content: space-between;border-radius: 5px;margin-bottom: 20px;flex-direction: column;}
.profile-box > h2{font-size: 1.4rem;color: #101010;font-weight: bold;border-bottom: 1px solid #ddd;}
.profile-from{display: flex;align-items: center;margin-bottom: 10px;}
.profile-from > .from-title{color: #969696;padding: 4px 10px;border-radius: 10px;width: 25%;}
.profile-from > .from-title > span{color: #ff8e8e;}
.profile-from > .from-info{font-size: 1.4rem;color: #101010;}
.profile-from > input{color: #101010;background: #ffffff;border-radius: 5px;width: 75%;height: 40px;}
.profile-from > select{width: 75%;height: 40px;border-radius: 5px;background: #ffffff;color: #101010;}
.profile-from > select > option{background: #fff;color: #969696;}
.profile-from > img{position: absolute; top: 8px;right: 10px;}
.profile-from > .password-from{width: 75%;display: flex;flex-direction: column;}
.profile-from > .password-from > input{color: #101010;background: #ffffff;border-radius: 5px;width: 100%;height: 40px;}
.profile-from > .password-from > span{font-size: 1.1rem;color: #ff8585;}

.profile-btn{padding-bottom: 10px;}
.profile-btn > .submit-btn{margin-right: 10px;border-radius: 5px;background: #2f6fff;border: 0;}
.profile-btn > .cancel-btn{margin-right: 10px;border-radius: 5px;background: #fff;border: 0;color: #969696;border: 1px solid #ddd;}
</style>
<script>
import { format } from "@/mixins/format";
import moment from "moment";
import {
  fetchDepartmentList,
} from "@/api/department";
import {
  fetchUserDetails,
  updateUser,
} from "@/api/user";
export default {
  mixins: [format],
  data() {
    return {
      moment: moment,
      status: "",
      userType: "",
      registerDate: "",
      ownerWork: "",
      phone: "",
      name: "",
      accountId: "",
      loginId: "",
      password: "",
      passwordConfirm: "",
      departmentList: [],
      error: false,
      departmentId: "",
      loginDate: "",
      userId: "",
      isTitle:true,
    };
  },
  created() {
    if(this.$route.name == "userEdit2"){
      this.isTitle = false;
      this.$store.dispatch("store/SET_NAVBAR", { nav: 9, sub: 13 });
    }
  },
  mounted() {
    this.getDepartmentList();
    this.getUserDetails();
  },
  watch : {
    phone(val){
      let check = /^[0-9]+$/;
      if (!check.test(val)) {
        this.phone = val.replace(/[^0-9]/g,'');
      }
    },
  },
  methods: {
    bindNumber(event){
      this.phone = event.target.value;
    },
    getDepartmentList() {
      var params = {
        statuses : "Used"
      }
      fetchDepartmentList(params).then((res) => {
        this.departmentList = res.data.data.content;
      });
    },
    getUserDetails() {
      let userId = localStorage.getItem("userId");
      // let token = localStorage.getItem("token");
      fetchUserDetails(userId).then((res) => {
        if (res.data.result == 0) {
          this.departmentId = res.data.data.departmentId;
          this.userId = res.data.data.userId;
          this.loginId = res.data.data.loginId;
          this.name = res.data.data.name;
          this.phone = res.data.data.phone;
          this.ownerWork = res.data.data.ownerWork;
          this.registerDate = res.data.data.registerDate;
          this.userType = res.data.data.userType;
          this.status = res.data.data.status;
          this.loginDate = res.data.data.loginDate;
        } else {
          alert(res.message);
        }
      });
    },
    submit() {
      if (this.name == "") {
        return alert(this.$t("alert-message-check-name"));
      } else if (this.phone == "") {
        return alert(this.$t("alert-message-check-phone-num"));
      }

      let data = {
        loginId: this.loginId,
        name: this.name,
        phone: this.phone,
        departmentId : this.departmentId,
        ownerWork: this.ownerWork,
        status: this.status,
        userType: this.userType,
      };

      if(this.password.trim() != ""){
        if (this.password != this.passwordConfirm) {
          return alert(this.$t("alert-message-check-pw-not-correct"));
        }

        if (!this.checkPasswordFormat(this.password)) {
          return alert(this.$t("alert-message-check-pw-format"));
        }

        data.password = this.password
      }

      let userId = localStorage.getItem("userId");
      updateUser(userId, data).then((res) => {
        if (res.data.result == 0) {
          alert(this.$t("top-mypage-pop-confirm-success"));
          //this.$router.push("portal");
          this.$router.go(-1)
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    handleRefresh() {
      this.$router.go();
    },
    back(){
      this.$router.go(-1);
    }
  },
};
</script>
