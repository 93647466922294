import axios from "axios";
import { baseUrl, baseApiVersion, headers } from "../config/env";


export function fetchMissionCreate(data) {
    return  axios.post(baseUrl + baseApiVersion + `/mission`, data, { headers });
  }

  export function fetchMissionList(params) {
    return axios.get(baseUrl + baseApiVersion + `/mission/list`, {
      headers,
      params,
    });
  }

  export function fetchMissionInfo(missionId) {
    return axios.get(baseUrl + baseApiVersion + `/mission/${missionId}`, {
      headers,
    });
  }

  
export function fetchMissionUpdate(data) {
  return  axios.put(baseUrl + baseApiVersion + `/mission/update`, data, { headers });
}

export function fetchMissionDelete(missionId) {
  return axios.delete(baseUrl + baseApiVersion + `/mission/${missionId}`, {
    headers,
  });
}
export function fetchMissionTemplateDownloadUrl() {
  return baseUrl + baseApiVersion + `/mission/template/download`;
}

export function fetchMissionUpload(data) {
  headers["Content-Type"] = "multipart/form-data";
  return axios.post(baseUrl + baseApiVersion + `/mission/upload`, data, { headers });
}

export function getMissionGimbalPitchList(){
  return [
      {id : "None",show:"None"},
      {id : "Gimbal0",show:"0",},
      {id : "Gimbal15",show:"15",},
      {id : "Gimbal30",show:"30",},
      {id : "Gimbal45",show:"45",},
      {id : "Gimbal60",show:"60",},
      {id : "Gimbal75",show:"75",},
      {id : "Gimbal90",show:"90",},
  ]
}

export function getMissionCameraList(){
  return axios.get(baseUrl + baseApiVersion + `/mission/cameraList`, {
    headers,
  }); 
}

export  function  fetchMissionActionInfoList(){
  return axios.get(baseUrl+baseApiVersion+'/mission/actionList' , {
    headers
  });
}

export const createTakePhotoAction = {
      //dev =
      //demo = 테이슨수집 = 'E230630001'
      "eventId": "E230630001",  //"mission-test-ref-id",
      "title": "mission-take-photo-test",
      "autoFlightSpped": "15",
      "altitude": "100",
      "finishedActionType": "RTL",
      "missionType": "WayPoint",
      "missionRefType" : "Drone",
      "missionAutoFlightLiabilityAllow" : 'true',
      "locations" : [
        {
          "altitude": "100",
          "autoFlightSpeed": 15,
          "gimbalType": "None",
          "index": 0,
          "lat": 37.57989352850075,
          "lng": 126.87226478813037,
          "actions": [
            {
              "index": 0,
              "missionLocationIndex": 0,
              "actionType": "TakePhoto",
              "actionElementList": [
                {
                  "actionName": "payloadPositionIndex",
                  "valueType": "Int",
                  "actionData" : 0,
                },
                {
                  "actionName": "fileSuffix",
                  "valueType": "String",
                  "actionData" : "testFIleName",
                },
                {
                  "actionName": "payloadLensIndex",
                  "valueType": "String",
                  "actionData" : "zoom",
                },
                {
                  "actionName": "useGlobalPayloadLensIndex",
                  "valueType": "Boolean",
                  "actionData" : "true",
                }
              ]
            }
          ]
        },
        {
          "altitude": "100",
          "autoFlightSpeed": 15,
          "gimbalType": "None",
          "index": 1,
          "lat": 37.5802129066408,
          "lng": 126.87604548202332,
          "actions": [
            {
              "index": 0,
              "missionLocationIndex": 1,
              "actionType": "TakePhoto",
              "actionElementList": [
                {
                  "actionName": "payloadPositionIndex",
                  "valueType": "Int",
                  "actionData" : 0,
                },
                {
                  "actionName": "fileSuffix",
                  "valueType": "String",
                  "actionData" : "testFIleName",
                },
                {
                  "actionName": "payloadLensIndex",
                  "valueType": "String",
                  "actionData" : "zoom",
                },
                {
                  "actionName": "useGlobalPayloadLensIndex",
                  "valueType": "Boolean",
                  "actionData" : "true",
                }
              ]
            }
          ]
        },
        {
          "altitude": "100",
          "autoFlightSpeed": 15,
          "gimbalType": "None",
          "index": 2,
          "lat": 37.58026118430927,
          "lng": 126.87915870011443,
          "actions": [
            {
              "index": 0,
              "missionLocationIndex": 2,
              "actionType": "TakePhoto",
              "actionElementList": [
                {
                  "actionName": "payloadPositionIndex",
                  "valueType": "Int",
                  "actionData" : 0,
                },
                {
                  "actionName": "fileSuffix",
                  "valueType": "String",
                  "actionData" : "testFIleName",
                },
                {
                  "actionName": "payloadLensIndex",
                  "valueType": "String",
                  "actionData" : "zoom",
                },
                {
                  "actionName": "useGlobalPayloadLensIndex",
                  "valueType": "Boolean",
                  "actionData" : "true",
                }
              ]
            },
            {
              "index": 1,
              "missionLocationIndex": 2,
              "actionType": "TakePhoto",
              "actionElementList": [
                {
                  "actionName": "payloadPositionIndex",
                  "valueType": "Int",
                  "actionData" : 0,
                },
                {
                  "actionName": "fileSuffix",
                  "valueType": "String",
                  "actionData" : "testFIleName",
                },
                {
                  "actionName": "payloadLensIndex",
                  "valueType": "String",
                  "actionData" : "zoom",
                },
                {
                  "actionName": "useGlobalPayloadLensIndex",
                  "valueType": "Boolean",
                  "actionData" : "true",
                }
              ]
            }
          ]
        }
      ],
}

export const createPanoShotAction = {
  //dev =
  //demo = 테이슨수집 = 'E230630001'
  "eventId": "E230630001",  //"mission-test-ref-id",
  "title": "mission-pano-shot-test",
  "autoFlightSpped": "15",
  "altitude": "150",
  "finishedActionType": "RTL",
  "missionType": "WayPoint",
  "missionRefType" : "Drone",
  "missionAutoFlightLiabilityAllow" : 'true',
  "locations" : [
    {
      "altitude": "100",
      "autoFlightSpeed": 15,
      "gimbalType": "None",
      "index": 0,
      "lat": 37.57989352850075,
      "lng": 126.87226478813037,
      "actions": [
        {
          "index": 0,
          "missionLocationIndex": 0,
          "actionType": "PanoShot",
          "actionElementList": [
            // {
            //   "actionName": "payloadPositionIndex",
            //   "valueType": "Int",
            //   "actionData" : 0,
            // },
            {
              "actionName": "panoShotSubMode",
              "valueType": "String",
              "actionData" : "panoShot_360",
            },
            {
              "actionName": "payloadLensIndex",
              "valueType": "String",
              "actionData" : "wide",
            },
            {
              "actionName": "useGlobalPayloadLensIndex",
              "valueType": "Boolean",
              "actionData" : "false",
            }
          ]
        }
      ]
    },
    {
      "altitude": "100",
      "autoFlightSpeed": 15,
      "gimbalType": "None",
      "index": 1,
      "lat": 37.5802129066408,
      "lng": 126.87604548202332,
      "actions": []
    },
    {
      "altitude": "100",
      "autoFlightSpeed": 15,
      "gimbalType": "None",
      "index": 2,
      "lat": 37.58026118430927,
      "lng": 126.87915870011443,
      "actions": []
    }
  ],
}

