// import Cookies from "js-cookie";
import VueCookies from "vue-cookies";

const mutations = {
  SET_SELECTED_EVENT(state, data) {
    console.log("mutations run!!", data)
    state.selectedEvent = data
  },
  LOGIN(state, data) {
    state.isLogin = true;
    localStorage.setItem("token", data.token);
  },
  LOGOUT(state) {
    state.isLogin = false;
    state.accountId = "";
    state.userType = "";
    state.registerDate = "";
    state.status = "";
    state.ownerWork = "";
    state.phone = "";
    state.name = "";
    state.userId = "";
    state.device = "";
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("accountId");
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("userType");
    localStorage.removeItem("sessionTime");
  },
  SET_NAVBAR(state, data) {
    state.navbarState = data.nav;
    state.subMenuState = data.sub;
  },
  SET_NAVBAR_VISIBLE(state, data) {
    state.navVisible = data;
  },
  SET_GNB_VISIBLE(state, data) {
    state.gnbVisible = data;
  },
  SET_FOOTER(state, data) {
    state.footerVisible = data.visible;
  },
  SET_AUTO_LOGIN(state, data) {
    state.autoLogin = data;
  },
  SET_USER_INFO(state, data) {
    localStorage.setItem("userId", data.userId);
    localStorage.setItem("accountId", data.accountId);
    state.isAdmin = data.userType == "Admin" ?  true : false;
    localStorage.setItem("isAdmin", state.isAdmin);
    localStorage.setItem("userType", data.userType);
    if (data.accountId) {
      state.accountId = data.accountId;
    }

    if (data.userType) {
      state.userType = data.userType;
    }
    if (data.status) {
      state.status = data.status;
    }
    if (data.phone) {
      state.phone = data.phone;
    }

    if (data.ownerWork) {
      state.ownerWork = data.ownerWork;
    }

    if (data.registerDate) {
      state.registerDate = data.registerDate;
    }
    if (data.name) {
      state.name = data.name;
    }

    if (data.departmentName) {
      state.departmentName = data.departmentName;
    }

    if (data.departmentId) {
      state.departmentId = data.departmentId;
    }

    if (data.loginId) {
      state.loginId = data.loginId;
    }
  },
  langChange(state, data) {
    state.currentLang = data;
    VueCookies.set("language", data);
  },
};
export default mutations;
