/**
 * 인증관련 라이선스 accountId 추가하는곳.
 * 차후 인증서버 개발완료시 수정해야함.
 * @type {string[]}
 */

// TODO: expy 끝나면 ai삭제해주세여~
export const aiLicence = ["demo", 'expy']

export const stationLicence = ['demo', 'dev', 'expy', 'hshi']

export function stationLicenceCheck(accountId){
    const isUse = stationLicence.find(item => {
        return item === accountId
    })
    if(isUse == undefined){
        return false
    } else{
        return  true
    }
}

export function aiLicenceCheck(accountId){
    const isUse = aiLicence.find(item => {
        return item === accountId
    })
    if(isUse == undefined){
        return false
    } else{
        return  true
    }
}