import axios from "axios";
import { baseUrl, baseApiVersion, headers } from "../config/env";

//okok
export function createUser(data) {
  return axios.post(baseUrl + "/api/v1/user", data, { headers });
}

//okok
export function fetchUserDetails(userId) {
  return axios.get(baseUrl + baseApiVersion + `/user/${userId}`, { headers });
}

//okok
export function deleteUser(userId) {
  return axios.delete(baseUrl + baseApiVersion + `/user/${userId}`, {
    headers,
  });
}

//ok accountId 없음
export function updateUser(userId, data) {
  return axios.patch(baseUrl + baseApiVersion + `/user/${userId}`, data, {
    headers,
  });
}

export function updateUserList(data) {
  return axios.put(baseUrl + baseApiVersion + `/user/update/auth/list`, data, {
    headers,
  })
}

export function fetchUserList(params) {
  return axios.get(baseUrl + baseApiVersion + `/user/list`, { params, headers,});
}


export function fetchParseToken() {
  return axios.get(baseUrl + baseApiVersion + `/parseToken`, { headers,});
}