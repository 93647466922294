<template>
  <div class="contents liveInfo" style="position: relative; height: calc(100vh - 50px);">
    <div class="mission-title">
      <h1 style="height: 50px;line-height: 50px;">맵핑 목록</h1>
      <a @click="handleCreate" :to="{ name: 'MappingCreate' }">등록하기</a>
    </div>
    <div class="liveList" style="height: calc(100% - 50px);">
      <div class="search-from">
        <h2>상세검색</h2>
        <div class="search-from-input">
          <h4>{{ $t("search-keyword-title") }}</h4>
          <div class="search-input">
            <select v-model="keywordType">
              <option value="all">{{ $t("search-keyword-all") }}</option>
              <option value="title">{{ $t("mission-data-way-title") }}</option>
              <option value="departmentName">{{ $t("mission-data-way-dept") }}</option>
              <option value="userName">{{ $t("mission-data-way-registrant") }}</option>
            </select>
            <input
              type="text"
              v-model="keyword"
              @keydown.enter="search"
            />
          </div>
        </div>
        <div class="search-from-input">
          <h4>{{ $t("search-regdate-title") }}</h4>
          <Datepicker
              v-model="startYmd"
              :language="ko"
              :format="customFormatter"
              placeholder="YYYY-MM-DD"
          ></Datepicker>
          <Datepicker
              v-model="endYmd"
              :language="ko"
              :format="customFormatter"
              placeholder="YYYY-MM-DD"
          ></Datepicker>
        </div>
        <div class="search-from-btn">
          <button class="point medium" @click="search"  style="width: 49%;background:#0080FF;border-color:#0080FF;border-radius:5px;font-weight:400;">
            {{ $t("btn-searching") }}
          </button>
          <button class="medium margin6" @click="reset" style="width: 49%;border-color:#ddd;color:#969696; font-weight:400;border-radius:5px;">
            {{ $t("btn-reset") }}
          </button>
        </div>
      </div>
      <div class="tabBox one" style="width: 22%;height: calc(100% - 291px);margin-right: 20px; margin-bottom: 0px;">
        <div class="mapping-content" style="height: 100%;">
          <div class="mapping-info">
            <h2>맵핑 목록</h2>
            <h4>
              {{ $t("data-total") }} <span>{{ total }}</span
            >{{ $t("data-case-ea") }}
              <!-- [{{ currentPage + 1 + "/" + Math.ceil(total / size) }}] -->
            </h4>
          </div>
          <div class="mapping-list">
            <div class="mapping-list-none"  v-if="total == 0">
              {{ $t("search-not-found") }}
            </div>
            <div class="mapping-list-box"
              v-for="(data, i) in mappingList"
              :key="i"
              @click="handleSelectEl(data)"
              :class="{ active: selectEl != null && selectEl.missionId == data.missionId }">
              <div class="mapping-list-box-header">
                <div class="mapping-list-title">
                  <h2>{{ data.title }}</h2>
                  <h4>{{ dateFormat(data.registerDate) }}</h4>
                </div>
                <div class="mapping-list-number">
                  <span>{{ total - (size * currentPage + i) }}</span>
                </div>
              </div>
              <ul>
                <li>
                  <span>{{ $t("mission-data-way-registrant") }}</span>
                  <h4>{{ data.userName }}</h4>
                </li>
                <li>
                  <span>{{ $t("mission-data-way-dept") }}</span>
                  <h4>{{ data.userDepartmentName }}</h4>
                </li>
              </ul>
            </div>
          </div>
          <div class="pagination" style="padding-top: 15px;">
            <el-pagination
                small
                layout="prev, pager, next"
                :total="total"
                :page-size="size"
                @current-change="handleCurrentChange"
                :current-page="currentPage + 1"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <div class="map" style="width: calc(78% - 45px);height: calc(100% + 0px);position: absolute;right: 0;bottom: 0">
        <Map ref="map" style="width: 100%; height: 100%;" :loadMapName="loadMap" />
        <MapButtonComponent ref="buttonMap"/>
        <button v-show="this.selectEl != null" class="point medium" style="width:150px; position: absolute; left: 45%; bottom: 20px;background: #0080FF;border: 1px solid #0080FF;border-radius: 5px;"  @click="handleDetail">
          <a href="javascript:void(0);">{{ $t("btn-detail") }}</a>
        </button>
      </div>
    </div>
  </div>

</template>

<style>
.title{display: none;}
.search-from{width: 22%;background: #fff;border-radius: 5px;padding: 15px;margin-bottom: 15px;}
.search-from > h2{font-size: 1.4rem;font-weight: bold;border-bottom: 1px solid #ddd;padding-bottom: 5px;margin-bottom: 5px;}

.search-from-input{display: flex;flex-direction: column;width: 100%;margin-bottom: 5px;}
.search-from-input > h4{font-size: 1.2rem;font-weight: 400;color: #969696;margin-bottom: 5px;}
.search-from-input > .search-input{display: flex;margin-bottom: 5px;}
.search-from-input > .search-input > select{width: 30%;border-radius: 5px;margin-right: 5px;}
.search-from-input > .search-input > input{width: 69%;border-radius: 5px;margin-bottom: 0px;}
.search-from-input > .vdp-datepicker{width: 100%;}
.search-from-input > .vdp-datepicker input{width: 100%;border-radius: 5px;}
.search-from-btn{display: flex;flex-direction: row;width: 100%;border-top: 1px solid #ddd;padding-top: 10px;}

.mapping-info{display: flex;align-items: center;justify-content: space-between;margin-bottom: 10px;}
.mapping-info > h2{font-size: 1.4rem;font-weight: bold;margin: 0;}
.mapping-info > h4{font-size: 1.2rem;font-weight: 500;}
.mapping-info > h4 > span{color: #0080FF;font-weight: bold;}

.mapping-list{height: calc(95% - 50px);overflow-y: scroll;}
.mapping-list-none{font-size: 1.2rem;text-align: center;padding-top: 20rem;}
.mapping-list-box{border: 1px solid #ddd;margin-bottom: 10px;border-radius: 5px;padding: 10px;}
.mapping-list-box-header{display: flex;justify-content: space-between;align-items: center;border-bottom: 1px solid #ddd;padding-bottom: 10px;}
.mapping-list-title > h2{font-size: 1.4rem; font-weight: bold;margin-bottom: 5px;}
.mapping-list-title > h4{font-size: 1.2rem;font-weight: 500;color: #969696;}
.mapping-list-number{width: 25px;height: 25px;background: #0080FF;border-radius: 50px;text-align: center;}
.mapping-list-number > span{color: #fff;display: block;padding-top: 5px;font-size: 1.2rem;}

.mapping-list-box:hover{border: 1px solid #0080FF;cursor: pointer;}
.mapping-list-box.active{border: 1px solid #0080FF;background: #f7fbff;}
.mapping-list-box.active > .mapping-list-box-header > .mapping-list-title > h2{color: #0080FF;}

.mapping-list-box > ul{padding-top: 10px;}
.mapping-list-box > ul > li{display: flex;align-items: center;justify-content: space-between;margin-bottom: 10px;}
.mapping-list-box > ul > li:nth-child(2){margin-bottom: 0;}
.mapping-list-box > ul > li > span{width: 75px;background: #eee;text-align: center;border-radius: 10px;color: #969696;font-size: 1.2rem;}
.mapping-list-box > ul > li > h4{font-size: 1.2rem;font-weight: 500;}

.missionActive{background: #0080FF;}
.mission-sub > li:nth-child(3) > a{background: #0080FF;border-radius: 10px;}

.mission-title{width: 22%;display: flex;align-items: center;justify-content: space-between;margin-top: 50px;}
.mission-title > a{background: #fff;border: 1px solid #0080FF;color: #0080FF;padding: 5px 20px;border-radius: 5px;font-weight: 400;font-size: 1.2rem;}
</style>

<script>
import moment from "moment";
import { ko } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import {fetchMappingList} from "@/api/missionMapping";
import {fetchMissionList,getMissionCameraList} from "@/api/mission";
import {mapState} from "vuex";
import {getDefaultMapZoomLevel} from "@/util/common";

import { fetchEvent } from "@/api/event";
import { fetchMappingLocationList} from "@/api/missionMappingLocation";
import { fetchMissionLocationList} from "@/api/missionLocation";

import Map from "@/components/map/Map";
import MapDataType from "@/components/map/enum/MapDataType";
import MapMarkerData from "@/components/map/data/MapMarkerData";
import MapLineData from "@/components/map/data/MapLineData";
import MapPolygonData from "@/components/map/data/MapPolygonData";
import MapMappingData from "@/components/map/data/MapMappingData";
import MapUtils from "@/components/common/MapUtils"
import MapButtonComponent from "@/components/map/MapButtonComponent.vue";
import {fetchStationDeviceInfo} from "@/api/station/stationDevice";

fetchMappingList
fetchMappingLocationList
MapDataType,MapMarkerData,MapLineData,MapPolygonData
export default {
  components: { Datepicker, Map, MapButtonComponent },
  name: "MappingList",
  props: ["eventId"],
  computed: {
    ...mapState({
      currentLang: state => state.store.currentLang,
    }),
  },
  data() {
    return {
      mappingData : new MapMappingData(),
      MapUtils : new MapUtils(),
      ko: ko,
      moment: moment,
      startYmd: null,
      endYmd: null,
      mappingList: [],
      mappingLocationList : [],
      mappingLocationTotal: 0,
      visible: 1,
      total: 0,
      size: 10,
      currentPage: 0,
      keywordType: "all",
      keyword: null,
      loadMap : 'kakao',
      selectEl : null,
      mappingType : "Orthophoto",
      cameraTypeList : null,
    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 9, sub: 9 });
  },
  watch: {
    currentLang(value){
      this.initMapData(value);
    },
  },
  mounted() {
    this.$refs.map.isShowLine = true;
    this.cameraInit();
    this.getMappingList();
    this.getEvent();
    this.initButtonMap()
  },
  methods: {
    initButtonMap() {
      this.$refs.buttonMap.showMapTypeChange()
    },
    initMapData(value){
      if(value == 'en') {
        this.loadMap = 'google'
      } else {
        this.loadMap = 'kakao'
      }
      this.$nextTick(function(){
        this.onMapCenter();
        
        if(this.selectEl != null) {
          this.getMappingLocationList();
        }
      })
    },
    onMapCenter(){
      if(this.eventInfo == null) {
        return;
      }
      this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
      if(this.eventInfo.lng != null && this.eventInfo.lng != "" && this.eventInfo.lat != null && this.eventInfo.lat != "") {
        this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
      } else {
        this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
      }
    },
    
    handleSelectEl(data){
      var f = false
      if(f) return
      if(data.lng == null || data. lat == null || data.lat == 0 || data.lng == 0) {
        alert("도형의 센터값이 없습니다.");
        return
      }
      this.$refs.map.onClearMapData(); //날려버리기.
      this.$refs.map.onLngLatToMoveCenter(data.lng,  data.lat);

      if(this.selectEl != null && this.selectEl.missionId == data.missionId) { //두번 클릭할 경우 현장 위치로 이동
        this.selectEl = null;
        this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat);
        return;
      }
      this.selectEl = data;
      this.getStationInfo()
      this.getMappingLocationList();

    },
    getEvent() {
      if(this.eventId == undefined) {
        return;
      }
      fetchEvent(this.eventId).then((res) => {
        if (res.data.result == 0) {
          this.eventInfo = res.data.data;
          this.initMapData(this.currentLang);
        }
      });
    },
    cameraInit(){
      getMissionCameraList().then(res => {
        var data = JSON.parse(res.data.data)
        this.cameraTypeList = data.DJI
      })
    },

    getMappingLocationList(){
      let params = {
        eventId : this.eventId,
        missionId : this.selectEl.missionId
      };
      var point = {lat : this.selectEl.lat , lng : this.selectEl.lng }
      point
       fetchMissionLocationList(params).then((res) => {
        if (res.data.result == 0) {
          this.mappingLocationTotal = res.data.data.totalElements
          this.mappingLocationList = res.data.data.content
          this.settingMappingData() //angle , altitude 등 설정
          this.mappingLocationList.forEach(marker => {
            this.createPolygonMarker(marker)
          })

        }
      })
    },
    createPolygonMarker(markerData){
      let mapDataType = MapDataType.ID_MAPPING_MARKER
      let mapMarkerData = new MapMarkerData();
      let index = markerData.index+1
      mapMarkerData.id = index;
      mapMarkerData.lng = markerData.lng;
      mapMarkerData.lat = markerData.lat;
      mapMarkerData.rotate = 0;
      mapMarkerData.name = index;

      if (this.$refs.map.onIsMarker(mapDataType, index)) {
        this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
      } else {
        this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
      }
      if (index > 1) {
        let slice = this.mappingLocationList.slice(index - 2, index);
        this.createMapLine(index - 1, slice);
      }
      this.createLastLine(index)
    },

     /**매핑 영역안에 라인 만들때 사용 */
    createMapLine(id, locationGroup) {
      let mapDataType = MapDataType.ID_MAPPING_POLYGON_LINE
      var mapLineData = new MapLineData();
      mapLineData.id = id;
      mapLineData.locationGroup = locationGroup;
      mapLineData.isMain = true;
      mapLineData.isShow = true;
      if (this.$refs.map.onIsLine(mapDataType, id)) {
        this.$refs.map.onModifyLineReplaceAt(mapDataType, mapLineData);
      } else {
        this.$refs.map.onCreateLine(mapDataType, mapLineData);
      }
    },

    /**마커가 3개이상부터 찍히면 첫번째 포인트와 마지막 포인트 연결함 */
    createLastLine(index){
      if(index > 2){
        let slice = [
          this.mappingLocationList[0], // 첫 번째 요소
          this.mappingLocationList[index - 1] // 마지막 요소
        ];
        this.createMapLine(index, slice);
        this.createMappingLine()
      }
    },
    createMappingLine(dataType){
      /**
       *  1. 각도 , 간격, 비행높이 정보를 보낸다.
       *  2. 매핑 뒤에 둘 히든 폴리곤을 생성한다.
       *  3. 히든폴리곤과 다각형에 라인을 생성한다.
       */
      this.sendMappingData(dataType);

      /**mappingType은 정사영상이냐, 방제용이냐*/
      this.createPolygon(MapDataType.ID_MAPPING_HIDDEN_POLYGON);
      this.$refs.map.onCreateMappingLine(this.mappingType , this.polygonType)
    },

    /**폴리곤 생성.*/
    createPolygon(mapDataType){
      var mapPolygonDate = new MapPolygonData();
      if(mapDataType == MapDataType.ID_MAPPING_HIDDEN_POLYGON){
        var centerInfo = this.createCenterInfo()
        mapPolygonDate.setPolygon(MapDataType.ID_MAPPING_HIDDEN_POLYGON)
        mapPolygonDate.setLatLng(centerInfo)
        mapPolygonDate.posList = this.createSqurePolygon(centerInfo ,centerInfo.distance,Number(this.mappingData.angle))
        mapPolygonDate.line = mapPolygonDate.posList
      }
      this.$refs.map.onCreateMappingPolygon(mapPolygonDate,mapDataType );
    },
     /**다각형 도형의 가운데 정보를 생성한다.*/
    createCenterInfo(){
      var maxDistance = 0
      var center = this.MapUtils.calculateCentroid(this.mappingLocationList) //다각형 중점 구하기

      this.mappingLocationList.forEach(item => { //다각형 중점에서부터의 각포인트 최대 거리 구하기.
       var distance = this.MapUtils.calculateDistance(item , center)
        if(maxDistance < distance) maxDistance = distance
      })
      var data = {lat : center.lat , lng : center.lng , distance : maxDistance}
      return data
    },
    /**매핑 영역 밑에 깔리는 폴릭곤 */
    createSqurePolygon(point,distance,angle){
      var polygonPosList = []

      var rightPoint = this.MapUtils.calculateNewPosition(point,90+angle,distance);
       polygonPosList.push(this.MapUtils.calculateNewPosition(rightPoint,0+angle,distance,polygonPosList.length));
      polygonPosList.push(this.MapUtils.calculateNewPosition(rightPoint,180+angle,distance,polygonPosList.length));


      var leftPoint = this.MapUtils.calculateNewPosition(point,270+angle,distance);
       polygonPosList.push(this.MapUtils.calculateNewPosition(leftPoint,180+angle,distance,polygonPosList.length));
      polygonPosList.push(this.MapUtils.calculateNewPosition(leftPoint,0+angle,distance,polygonPosList.length));

      return polygonPosList
    },

     settingMappingData(){
      if(this.selectEl != null){
        var findIndex = this.getCameraListId(this.selectEl.cameraType);
        if(findIndex == -1) {console.log ("settingMappingData index : -1"); return}
        var camera = JSON.parse(JSON.stringify(this.cameraTypeList[findIndex]));

        this.mappingData.setApiData(this.selectEl)
        this.mappingData.cameraType = camera
        this.sendMappingData();
      }
    },
    sendMappingData(dataType){
  
      this.$refs.map.sendMappingData(this.mappingData,dataType)
    },
    getCameraListId(id){
      return this.cameraTypeList.findIndex(item => {return item.id == id})
    },
    dateFormat(date) {
      return moment(date).format("YYYY.MM.DD HH:mm:ss");
    },
    customFormatter(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    handleDetail() { //상세보기 컨트롤
      //var id = this.selectEl.waypointId;
      this.$router.push({ name: "MappingUpdate" , query: {eventId : this.eventInfo.eventId, missionId: this.selectEl.missionId }})
      //this.$emit("select-mission",id)
    },
    handleCreate() {
      //var id = this.selectEl.waypointId;
      this.$router.push({ name: "MappingCreate"})
      //this.$emit("select-mission",id)
    },
    handleCurrentChange(val) {
      this.currentPage = val - 1;
      this.getMappingList();
      this.scrollToTop();
    },
    scrollToTop() {
      const wrapList = this.$el.querySelector('.wrap-list');
      if (wrapList) {
        wrapList.scrollTop = 0;
      }
    },
    search(){
      if (this.startYmd != null) {
        if (this.startYmd > this.endYmd || !this.endYmd || !this.startYmd) {
          return alert(this.$t("alert-message-check-regdate"));
        }
      }
      this.currentPage = 0;
      this.getMappingList();
    },
    reset(){
      this.keywordType = "all";
      this.keyword = null;
      this.startYmd = null;
      this.endYmd = null;
    },
    getMappingList() {
      var params = {
        eventId : this.eventId,
        refType : "None",
        type : "Mapping",
        page_page: this.currentPage,
        page_size: this.size,
        keywordType: this.keywordType,
        keyword: this.keyword,
      };

      if(this.startYmd != null && this.endYmd != null) {
        params["dateRange.from"] = moment(this.startYmd).format("YYYY-MM-DD");
        params["dateRange.to"] = moment(this.endYmd).format("YYYY-MM-DD");
      }
      fetchMissionList(params).then((res) => {
        this.total = res.data.data.totalElements;
        this.mappingList = res.data.data.content;
      });
    },
    getStationInfo() {
      console.log("getStationInfo", this.selectEl.refId)
      if(this.selectEl.refId === null || this.selectEl.refId === undefined) return

      fetchStationDeviceInfo(this.selectEl.refId).then((res) => {
        if (res.status === 200 && res.data.result === 0) {
          const stationData = res.data.data.managerData

          const mapDataType = MapDataType.ID_SCHEDULE_STATION
          let mapMarkerData = new MapMarkerData();
          mapMarkerData.id = 1;
          mapMarkerData.lng = stationData.lng;
          mapMarkerData.lat = stationData.lat;
          mapMarkerData.name = 1;
          mapMarkerData.isMain = true

          if(this.$refs.map.onIsMarker(mapDataType, 1 ) ) {
            this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
          } else {
            this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
          }
        }
      });
    },
  },
};
</script>
