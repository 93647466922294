<template>
  <div style="position: relative;">
    <div class="box one flexB vodBox">
      <div>
        <videoPlayer ref="video" :src="viewSrc" :isShowLiveText="false" :playerId="'isplay'" :isUser="userType == 'User'" :isDeleteButtonShow="false" :contentsId="contentsId" @isCanPlay="playerLoadData"/>
      </div>
    </div>
    <div class="box one detail">
      <div class="flex">
        <p class="bold">{{ $t("vod-data-title") }}</p>
        <p>{{ content.title }}</p>
      </div>
      <div class="flex">
        <p class="bold">{{ $t("vod-data-registered-date") }}</p>
        <p>{{ moment(content.registerDate).format("YYYY.MM.DD HH:mm:ss") }}</p>
      </div>

      <div class="flex">
        <p class="bold">{{ $t("event-data-site-num") }}</p>
        <p>No.{{ content.eventViewId != null ? content.eventViewId : content.eventId }}</p>
      </div>
      <div class="flex">
        <p class="bold">{{ $t("event-data-site-name") }}</p>
        <p>{{ content.eventName }}</p>
      </div>
      <div class="flex">
        <p class="bold">{{ $t("live-data-channel-operator") }}</p>
        <p>
          {{ content.registerUserName
          }}{{
            content.registerUserPhone
              ? "(" + getMask(content.registerUserPhone) + ")"
              : ""
          }}
        </p>
      </div>
      <div class="flex">
        <p class="bold">{{ $t("live-data-channel-operator-dept") }}</p>
        <p>{{ content.registerUserDeptName }}</p>
      </div>
      <div class="flex">
        <p class="bold">{{ $t("data-operation-device-type") }}</p>
        <p>
          {{$t(getDeviceTypeName(content.deviceType)) }}
        </p>
      </div>
      <div class="flex">
        <p class="bold">{{ $t("vod-data-recording-time") }}</p>
        <p>{{ moment(content.registerDate).format("YYYY.MM.DD HH:mm:ss") }}</p>
      </div>
      <div class="buttonWrap">
        <button v-if="status==='Remove'" class="large left" @click="handleRestore">
          {{ $t("btn-restore") }}
        </button>
        <button v-else class="large left" @click="handleDelete">
          {{ $t("btn-delete") }}
        </button>
        <div>
          <button class="large point" style="position: unset" @click="$router.go(-1)">
            {{ $t("btn-confirm") }}
          </button>
        </div>

      </div>
    </div>
  </div>
</template>
<style>
/*사진캡처 버튼*/
.imgCapt{
  width: 96px;
  height: 36px;
  text-align: center;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
  background: white;
  border-radius: 4px;
  position: absolute;
  z-index: 2000;
  margin: 10px;
  padding: 6px 10px;
  color:black;
  border: 1px solid white;
}
.imgCapt>span{
  line-height: 23px;
  font-weight: bold;
  font-size: 1.2rem;
}
.imgCapt:hover{
  background: #94d9ef;
}
</style>
<script>
import moment from "moment";
import videoPlayer from "@/components/video/videoPlayer";
import {fetchVodDetail, fetchDeleteVod, fetchRestoreVod} from "@/api/contents";
import { fetchVodUrlList } from "@/api/server";
import { fetchVodSrc, fetchFileList } from "@/api/file";
import { format } from "@/mixins/format";
import { getDeviceTypeName } from "@/api/channel";
import {mapState} from "vuex";

export default {
  props: ["contentsId"],
  mixins: [format],
  components: { videoPlayer },
  data() {
    return {
      moment: moment,
      content: {},
      viewSrc: "",
      vodUrl: "",
      isPlayStatus: false,
      status: "",
    };
  },
  computed: {
    ...mapState({
      userType: state => state.store.userType
    }),
  },
  async mounted() {
    this.getContentDetail();
    await this.getVodEndpoint();
    this.getVodUrl();
    this.getSDList();
  },
  methods: {
    playerLoadData(id, isLoad) {
      this.isPlayStatus = isLoad;
    },
    getDeviceTypeName(data){
      return getDeviceTypeName(data);
    },
    handleDelete() {
      if (!confirm(this.$t("alert-message-delete-contents-message")) ) {
        return;
      }

      fetchDeleteVod(this.contentsId).then((res) => {
        if (res.data.result == 0) {
          this.$router.go(-1);
        }
      });
    },
    getContentDetail() {
      fetchVodDetail(this.contentsId).then((res) => {
        this.content = res.data.data;
        this.status = res.data.data.status;
      });
    },
    async getVodEndpoint() {
      await fetchVodUrlList().then((res) => {
        this.vodUrl =
          res.data.data.properties.vodStreamUrl +
          "/" +
          res.data.data.properties.application;
      });
    },
    async getVodUrl() {
      await fetchVodSrc(this.contentsId).then((res) => {
        this.viewSrc = this.vodUrl + res.data.data.streamUri;
      });
    },
    getSDList() {
      let params = {
        pageNumber: 0,
        pageSize: -1,
        referenceContentsId: this.contentsId,
      };
      fetchFileList(params).then((res) => {
        res;
      });
    },
    handleRestore(){
      if(confirm(this.$t("alert-message-restore-contents-message")) ) {
        fetchRestoreVod(this.contentsId).then((res)=>{
          if (res.data.result === 0){
            alert(this.$t("alert-message-content-restored"));
            this.$router.go(-1);
          }
        });
      }
    }
  },
  // 삭제는 SDFile delete fileId 사용
};
</script>
