import axios from "axios";
import {baseUrl, baseApiVersion, headers, aiApiUrl,} from "../config/env";

export function fetchVodList(params) {
  return axios.get(
    baseUrl + baseApiVersion + `/contents/list`, { params, headers,}
  );
}

export function fetchCreateContentsSnap(contentsId) {
  return axios.post(baseUrl + baseApiVersion + `/contents/${contentsId}/snap`, null,{headers});
}


export function fetchVodDetail(contentsId) {
  return axios.get(baseUrl + baseApiVersion + `/contents/${contentsId}`, {
    headers,
  });
}

export function fetchVodThumbnail(contentId) {
  return axios.get(baseUrl + baseApiVersion + `/thumbnail/contents/${contentId}`, {
    headers: { "Content-Type": "image/png" },
  });
}

export function getUrlLiveMapThumbnail(fileId) {
  return baseUrl + baseApiVersion + `/thumbnail/liveMap/file/${fileId}`;
}

export function fetchDeleteVod(contentsId) {
  return axios.delete(baseUrl + baseApiVersion + `/contents/${contentsId}`, {
    headers,
  });
}

export function deleteVodList(params) {
  return axios.delete(baseUrl + baseApiVersion + `/contents/list/delete`, {
    params,
    headers,
  });
}

export function fetchRestoreVod(contentsId) {
  return axios.patch(baseUrl + baseApiVersion + `/contents/restore/${contentsId}`, null,{
    headers
  });
}


export function fetchRestoreListVod(params) {
  return axios.patch(baseUrl + baseApiVersion + `/contents/list/restore`, params,{
    headers
  });
}

// /** VOD 다운로드*/
// export function fetchVodDownloadUrl(contentId) {
//   return baseUrl + baseApiVersion + `/contents/${contentId}/download`;
// }

/** Ai VOD 다운로드*/
export function fetchAiVodDownloadUrl(mediaKey, accountID, contentId ) {
  const params = {
    media_key: mediaKey,
    account_id: accountID,
    contents_id: contentId,
  }
  return axios.get(`${baseUrl}${aiApiUrl}/download`, {
    params,
    headers,
    responseType: "blob", // Ensure the response is treated as a blob
  })
}

/** VOD 다운로드*/
export function fetchVodDownloadUrl(contentId) {
  return axios.get(baseUrl+baseApiVersion + `/contents/${contentId}/download`, {
    headers,
    responseType: "blob", // Ensure the response is treated as a blob
  })
}

export function fetchVodDownload(contentId) {
  return axios.get(baseUrl + baseApiVersion + `/contents/${contentId}/download`, {
    headers: { "Content-Type": "image/png" },
  });
}
